import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import dot from 'dot-object';
import cloneDeep from 'lodash/cloneDeep';

export default createStore({
    strict: false,
    modules: {
    },
    plugins: [
        createPersistedState({
            reducer: (persistedState: any) =>
            {
                const stateFilter = {};
                const states = ['common.theme']; // states which we want to persist.

                states.forEach(path => dot.copy(path, path, persistedState, stateFilter));

                return cloneDeep(stateFilter);
            }
        })
    ]
});

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $store: any;
    }
}
