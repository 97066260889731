<template>
    <component :is="tagName" v-bind="attrs()" :class="classNames" @click="onClick" v-if="visible">
        <slot name="default" v-if="loaded"></slot>
        <placeholder :image="true" :width="36" :height="36" class="mb-n1" v-else>></placeholder>
    </component>
</template>

<script lang="ts" setup>
import { computed, inject, useAttrs, type Ref, type ComputedRef } from 'vue';
import { only } from '@/helpers/Utils';
import { Header } from '../helpers';

export interface Props
{
    index: number;
    header: Header;
}

defineOptions({
    name: 'list-view-image',
    inheritAttrs: false
});

const props = defineProps({
  "index": null,
  "header": null
});
const loaded = inject<Ref<boolean>>('loaded');
const layout = inject<Ref<string>>('layout');
const item = inject<ComputedRef<any>>('item');
const rowClick = inject<(item: any, index: number) => void>('row-click');

const table = computed(() => layout.value == 'table');
const tagName = computed(() => table.value ? 'td' : 'div');
const classNames = computed(() => table.value ? 'pointer p-1' : '');
const visible = computed(() => props.header.visible);

const $attrs = useAttrs();
const attrs = (): Record<string, any> => only($attrs, 'class', 'style');

const onClick = (): void =>
{
    if (table.value && loaded && rowClick)
    {
        rowClick(item.value, props.index);
    }
};
</script>
