<template>
    <option :value="value">
        <slot name="default"></slot>
    </option>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';

@Options({
    name: 'ideo-form-select-option'
})
export default class IdeoFormSelectOption extends Vue
{
    @Prop()
    public value: any;
}
</script>
