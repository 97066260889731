<template>
    <layout>
        <template #code>404</template>
        <template #header>Oops! You're lost.</template>
        <template #description>The page you are looking for was not found.</template>
    </layout>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import ErrorLayout from '@/views/layouts/ErrorLayout.vue';

@Options({
    name: 'error-page-404',
    components: {
        'layout': ErrorLayout
    }
})
export default class ErrorPage404 extends Vue
{
}
</script>
