export default {
    "Project-Id-Version": "",
    "POT-Creation-Date": "2024-08-07T19:17:21.846Z",
    "MIME-Version": "1.0",
    "Content-Type": "text/plain; charset=utf-8",
    "Content-Transfer-Encoding": "8bit",
    "X-Generator": "Edito",
    "PO-Revision-Date": "2024-08-07T19:17:22.017Z",
    "Language": "pl-PL",
    "Messages": {
        "1": "",
        "2": "",
        "3": "",
        "4": "",
        "...": "",
        "' + file.filename + '": "",
        "' + func.name + '": "",
        "%0 z %1": "",
        "%0 znaków": "",
        "<Brak nazwy>": "",
        "${copied ? 'Skopiowano' : 'Kopiuj'}": "",
        "${el.text}": "",
        "${form.variants > 1 ? 'Generuj obrazy' : 'Generuj obraz'}": "",
        "${getCurrentScope().label}": "",
        "${item.entityName}": "",
        "${item.text}": "",
        "${loading ? 'Ładowanie elementów...' : 'Nie znaleziono żadnych elementów.'}": "",
        "${props.inputPlaceholder}": "",
        "${str}": "",
        "${tokenVisibility ? 'Ukryj' : 'Pokaż'} token": "",
        "1 godzina": "",
        "1:1": "",
        "10 minut": "",
        "12 godzin": "",
        "15 minut": "",
        "2 dni": "",
        "2 miesiące": "",
        "2 tygodnie": "",
        "24 godziny": "",
        "3 dni": "",
        "3 godziny": "",
        "3 miesiące": "",
        "30 minut": "",
        "4 dni": "",
        "4:7": "",
        "5 dni": "",
        "5 minut": "",
        "6 dni": "",
        "6 godzin": "",
        "7:4": "",
        "Aby dodawać członków zespołu musisz najpierw zapisać formularz.": "",
        "Aby móc przetestować poprawność kluczy, najpierw zapisz formularz.": "",
        "Adaptive sampling": "",
        "Administracja": "",
        "Administracja - Kraje": "",
        "Administracja - Miasta": "",
        "Administracja - Role": "",
        "Administracja - Stanowiska pracy": "",
        "Administracja - Użytkownicy": "",
        "Administracja - Województwa": "",
        "Administracja - Zespoły": "",
        "Administratorzy": "",
        "Adres": "",
        "Adres domenowy": "",
        "Adres e-mail potwierdzony": "",
        "Adres email": "",
        "Adres email nadawcy": "",
        "Adres IP": "",
        "Adres IP: %0": "",
        "Adres serwera": "",
        "Adres URL": "",
        "Akcent": "",
        "Akceptacja": "",
        "Akceptacja dokumentów": "",
        "Akceptacja dokumentu": "",
        "Akceptacja własnych dokumentów": "",
        "Akceptacja wszystkich dokumentów": "",
        "Akceptowalny typ plików": "",
        "Akceptowany format danych": "",
        "Akceptuj": "",
        "Akcja": "",
        "Akcje": "",
        "Aktualizacja: {0}": "",
        "Aktualizuj": "",
        "Aktualna sesja": "",
        "Aktualne hasło": "",
        "Aktualne urządzenie": "",
        "Aktywna": "",
        "Aktywne": "",
        "Aktywne kanały komunikacji": "",
        "Aktywne sesje": "",
        "Aktywne źródła": "",
        "Aktywni": "",
        "Aktywny": "",
        "Aktywuj": "",
        "Aktywuj tłumaczenia": "",
        "Aktywuj zestaw inteligentnych narzędzi": "",
        "Alert": "",
        "Alias": "",
        "Alias został przepięty.": "",
        "Angielski": "",
        "Ankieta": "",
        "Anonimizuj": "",
        "Anonimowy": "",
        "Anuluj": "",
        "Aplikacja": "",
        "Aplikacja zostanie zrestartowana w przeciągu maksymalnie 60 sekund.": "",
        "application/json": "",
        "application/x-www-form-urlencoded": "",
        "Asset został aktywowany.": "",
        "Asset został wyłączony.": "",
        "Asystenci": "",
        "Asystent AI": "",
        "Asystent został dodany.": "",
        "Asystent został zaktualizowany.": "",
        "Asystenta AI": "",
        "Audio": "",
        "Automatyzacja": "",
        "Autor": "",
        "Azure AD": "",
        "Azure AI": "",
        "Azure Application Insights": "",
        "Azure OpenAI": "",
        "Basic Auth": "",
        "Baza danych": "",
        "Baza danych tego klienta jest nieaktualna.": "",
        "Bazy danych": "",
        "Bezpieczeństwo": "",
        "Bezpieczne połączenie SSL": "",
        "Bieżący dokument": "",
        "Blokada konta": "",
        "Blokada konta do": "",
        "Blokowanie kont": "",
        "Błąd 404. Nie znaleziono żądanego zasobu.": "",
        "Błędne": "",
        "Błędny": "",
        "Błędy": "",
        "Błędy walidacji należy przypisać do \"entry.errors.value\" w postaci tablicy komunikatów.": "",
        "Brak": "",
        "Brak cennika dla wybranego dostawcy.": "",
        "Brak danych": "",
        "Brak dodanych kolumn": "",
        "Brak działu nadrzędnego": "",
        "Brak elementów": "",
        "Brak kluczy": "",
        "Brak kolekcji": "",
        "Brak nowych powiadomień.": "",
        "Brak odpowiedzi": "",
        "Brak opcji": "",
        "Brak wersji": "",
        "Brak wyboru": "",
        "Brak wyników": "",
        "Brak zdefiniowanych ról.": "",
        "Brak zdefiniowanych zespołów.": "",
        "Bramka SMS": "",
        "Cache będzie przechowywany z wykorzystaniem serwera Redis.": "",
        "Cache po stronie serwera": "",
        "Cache-Control": "",
        "Cena": "",
        "Cena w dolarach sumy tokenów wejściowych i wyjściowych.": "",
        "Cennik Azure OpenAI": "",
        "Cennik AzureAI": "",
        "Cennik OpenAI": "",
        "Centrum powiadomień": "",
        "Checkbox": "",
        "Ciemny": "",
        "Client ID": "",
        "Client Secret": "",
        "Co 10 dni": "",
        "Co 10 minut": "",
        "Co 12 godzin": "",
        "Co 15 minut": "",
        "Co 2 godziny": "",
        "Co 2 minuty": "",
        "Co 2 tygodnie": "",
        "Co 20 minut": "",
        "Co 3 godziny": "",
        "co 3 miesiące": "",
        "Co 3 miesiące": "",
        "co 30 dni": "",
        "Co 30 minut": "",
        "Co 4 miesiące": "",
        "Co 5 dni": "",
        "Co 5 minut": "",
        "Co 6 godzin": "",
        "co 6 miesięcy": "",
        "Co chcesz wygenerować?": "",
        "Co drugi dzień": "",
        "Co drugi miesiąc": "",
        "Co godzinę": "",
        "Co miesiąc": "",
        "Co minutę": "",
        "Co pół roku": "",
        "co rok": "",
        "Co tydzień": "",
        "Codziennie": "",
        "Connection String": "",
        "Coś poszło nie tak": "",
        "Cron": "",
        "Cyfry": "",
        "Czas": "",
        "Czas pomiędzy": "",
        "Czas trwania blokady": "",
        "Czas uruchomienia": "",
        "Czat z asystentem": "",
        "Czerwiec": "",
        "Członkowie zespołu": "",
        "Cztery kolumny": "",
        "Czy aby na pewno chesz zrestartować aplikacje?": "",
        "Czy na pewno chcesz usunąć widget?": "",
        "Dalej": "",
        "DALL-E 3": "",
        "Dane": "",
        "Dane formularza są przechowywane w obiekcie Entry.": "",
        "Dane osobowe": "",
        "Dane w tabeli": "",
        "Dane wejściowe": "",
        "Dane wyjściowe": "",
        "Data": "",
        "Data do": "",
        "Data dodania": "",
        "Data i czas": "",
        "Data modyfikacji": "",
        "Data od": "",
        "Data opuszczenia zespołu": "",
        "Data otrzymania": "",
        "Data rejestracji": "",
        "Data rozpoczęcia": "",
        "Data uruchomienia": "",
        "Data uruchomienia (UTC)": "",
        "Data usunięcia": "",
        "Data utworzenia": "",
        "Data utworzenia wersji": "",
        "Data utworzenia: ": "",
        "Data użycia": "",
        "Data wygaśnięcia": "",
        "Data wylogowania": "",
        "Data wywołania": "",
        "Data zakończenia": "",
        "Data zalogowania": "",
        "Data zapytania": "",
        "Data zdarzenia": "",
        "Data: %0 | Status: %1": "",
        "Dedykowane": "",
        "DeepL": "",
        "Definicja": "",
        "Definicja (JSON)": "",
        "Definicja formularza znajduje się w obiekcie Form.": "",
        "DELETE: Usunięcie wpisu": "",
        "Design Studio": "",
        "Dezaktywuj": "",
        "Długość czasu przechowywania": "",
        "Długość trwania sesji [min": "",
        "Dni": "",
        "do": "",
        "Do": "",
        "Do akceptacji": "",
        "do czasu ręcznego odblokowania": "",
        "Docs": "",
        "Dodaj": "",
        "Dodaj asystenta": "",
        "Dodaj automatyzacje": "",
        "Dodaj dni": "",
        "Dodaj do zespołu": "",
        "Dodaj dokument": "",
        "Dodaj dział": "",
        "Dodaj endpoint": "",
        "Dodaj formularz": "",
        "Dodaj funkcję": "",
        "Dodaj grupę": "",
        "Dodaj jeden lub więcej widgetów, aby uzyskać wgląd w postępy swojego zespołu.": "",
        "Dodaj kategorię": "",
        "Dodaj klienta": "",
        "Dodaj komponent": "",
        "Dodaj moduł": "",
        "Dodaj nowy folder": "",
        "Dodaj nowy wątek": "",
        "Dodaj plik": "",
        "Dodaj pliki": "",
        "Dodaj podsumowanie": "",
        "Dodaj pozycję": "",
        "Dodaj rolę": "",
        "Dodaj scenariusz": "",
        "Dodaj sekcję": "",
        "Dodaj serwer": "",
        "Dodaj shortcode": "",
        "Dodaj słowo": "",
        "Dodaj stronę": "",
        "Dodaj synonimy": "",
        "Dodaj szablon": "",
        "Dodaj token": "",
        "Dodaj użytkownika": "",
        "Dodaj webhooka": "",
        "Dodaj widget": "",
        "Dodaj wiersz": "",
        "Dodaj wpis": "",
        "Dodaj zastępstwo": "",
        "Dodaj zespół": "",
        "Dodaj źródło": "",
        "dodał": "",
        "dodane i edytowane przeze mnie": "",
        "Dodanie": "",
        "Dodanie asystenta": "",
        "Dodanie dokumentu": "",
        "Dodanie działu": "",
        "Dodanie endpointu": "",
        "Dodanie formularza": "",
        "Dodanie funkcji": "",
        "Dodanie klienta": "",
        "Dodanie komponentu": "",
        "Dodanie modułu": "",
        "Dodanie pliku": "",
        "Dodanie roli": "",
        "Dodanie scenariusza": "",
        "Dodanie serwera": "",
        "Dodanie użytkownika": "",
        "Dodanie webhooka": "",
        "Dodanie zastępstwa": "",
        "Dodanie zespołu": "",
        "Dodanie źródła": "",
        "Dodanie źródła danych": "",
        "Dodano za dużo plików (maksymalnie %0).": "",
        "dodany": "",
        "Dodanych plików %0 (limit wynosi %1).": "",
        "Dodatkowe informacje": "",
        "Dodawanie": "",
        "Dodawanie dokumentów": "",
        "Dodawanie i edycja własnych dokumentów": "",
        "Dodawanie wartości dwóch kolumn": "",
        "Dokument %0": "",
        "Dokument PDF": "",
        "Dokument tekstowy": "",
        "Dokument został usunięty.": "",
        "Dokumenty": "",
        "Dołącz stack trace": "",
        "Dołączanie plików": "",
        "Domena": "",
        "Domyślna": "",
        "Domyślna wartość": "",
        "Domyślne": "",
        "Domyślne filtry": "",
        "Domyślnie ukryte": "",
        "Domyślnie widoczne": "",
        "Domyślny": "",
        "Dopuść brak odpowiedzi": "",
        "Dostawca": "",
        "Dostęp do modułu": "",
        "Dostęp niezdefiniowany": "",
        "Dostęp przyznany": "",
        "Dostęp zabroniony": "",
        "Dostępna jest nowa aktualizacja. Proszę zapisać postęp pracy i klinąć przycisk \"Aktualizuj\". Możesz również zaakceptować aktualizację poprzez odświeżenie okna przeglądarki. Brak aktualizacji może spowodować błędy.": "",
        "Dostępne": "",
        "Dostępny limit": "",
        "dowolnej wybranej wersji": "",
        "Dowolny": "",
        "Dozwolone rozszerzenia": "",
        "Dozwolone rozszerzenia plików": "",
        "Dozwolone rozszerzenia: %0.": "",
        "Dozwolone są tylko małe i duże litery": "",
        "Dozwolone są tylko małe litery i znak -": "",
        "Dozwolone typy plików: %0.": "",
        "Drugie imię": "",
        "Dsn": "",
        "Duplikuj": "",
        "Duże litery": "",
        "Duży": "",
        "Duży układ siatki": "",
        "Dwie kolumny": "",
        "Dyrektywa max-age=N wskazuje, że odpowiedź pozostaje świeża do N sekund po wygenerowaniu odpowiedzi.": "",
        "Dyrektywa no-store wskazuje, że jakikolwiek cache dowolnego rodzaju nie powinien przechowywać tej odpowiedzi.": "",
        "Dyrektywa PRIVATE wskazuje, że odpowiedź może być przechowywana wyłącznie w prywatnej pamięci podręcznej (np. lokalnej pamięci przeglądarki).": "",
        "Dyrektywa PUBLIC wskazuje, że odpowiedź może być przechowywana we współdzielonej pamięci podręcznej (np. proxy).": "",
        "Dyrektywa s-maxage jest ignorowana przez prywatne pamięci podręczne i zastępuje wartość określoną przez dyrektywę max-age dla współdzielonych pamięci podręcznych.": "",
        "Dyrektywa s-maxage wskazuje, jak długo odpowiedź pozostaje aktualna we współdzielonej pamięci podręcznej (np. proxy).": "",
        "Dyrektywy zakresu": "",
        "Dział aktywny": "",
        "Dział nadrzędny": "",
        "Dział został dodany.": "",
        "Dział został zaktualizowany.": "",
        "Działania masowe": "",
        "Dzielenie": "",
        "dzień": "",
        "E-mail": "",
        "Edycja asystenta": "",
        "Edycja dokumentów": "",
        "Edycja dokumentu": "",
        "Edycja działu": "",
        "Edycja endpointu": "",
        "Edycja formularza": "",
        "Edycja funkcji": "",
        "Edycja klienta": "",
        "Edycja komponentu": "",
        "Edycja logów": "",
        "Edycja modułu": "",
        "Edycja pliku": "",
        "Edycja roli": "",
        "Edycja scenariusza": "",
        "Edycja serwera": "",
        "Edycja użytkownika": "",
        "Edycja w popupie": "",
        "Edycja webhooka": "",
        "Edycja wszystkich dokumentów": "",
        "Edycja zastępstwa": "",
        "Edycja zespołu": "",
        "Edycja źródła": "",
        "Edycja źródła danych": "",
        "Edytor": "",
        "Edytor lokalizowany": "",
        "Edytowana wersja": "",
        "Edytowane mogą być tylko szablony włączonych i obsługiwanych kanałów.": "",
        "Edytuj": "",
        "Edytuj członka grupy": "",
        "Edytuj dashboard": "",
        "Edytuj dokument": "",
        "Edytuj filtr": "",
        "Edytuj kategorię": "",
        "Edytuj szablon": "",
        "Edytuj uprawnienia": "",
        "Edytuj wpis": "",
        "Eksport": "",
        "Eksport do Excela": "",
        "Eksport dokumentów": "",
        "Eksport użytkowników": "",
        "Element został przywrócony.": "",
        "Element został usunięty.": "",
        "Email": "",
        "Email potwierdzony": "",
        "Endpoint został dodany.": "",
        "Endpoint został zaktualizowany.": "",
        "Eskportuj": "",
        "Etykieta": "",
        "Etykiety": "",
        "Exportuj do Excela": "",
        "Filmy": "",
        "Filtr dla synchronizacji działów": "",
        "Filtr dla synchronizacji użytkowników": "",
        "Filtr domyślny": "",
        "Filtr prywatny": "",
        "Filtr został usunięty": "",
        "Filtr został zapisany": "",
        "Filtrowanie": "",
        "Filtrowanie listy dokumentów": "",
        "Filtry": "",
        "Firebase": "",
        "Firebase config": "",
        "Folder plików": "",
        "Foldery": "",
        "Formularz": "",
        "Formularz bazuje na nieaktualnej wersji definicji.": "",
        "Formularz zarchiwizowany": "",
        "Formularz został dodany.": "",
        "Formularz został skopiowany.": "",
        "Formularz został zaakceptowany.": "",
        "Formularz został zaktualizowany.": "",
        "Formularze": "",
        "Formuła jest nieprawidłowa": "",
        "Funkcja została dodana.": "",
        "Funkcja została usunięta.": "",
        "Funkcja została zaktualizowana.": "",
        "Funkcje": "",
        "Funkcje asystenta": "",
        "Funkcjonalności": "",
        "Generator obrazów AI": "",
        "Generowanie formularza zakończone.": "",
        "Generowanie obrazów": "",
        "Generowanie obrazów zakończone.": "",
        "Generuj": "",
        "Generuj formularz": "",
        "Generuj metadane": "",
        "Generuj metadane (AI)": "",
        "Generuj nowy obraz": "",
        "Generuj obraz": "",
        "GET: Lista wpisów (sortowanie i stronicowanie)": "",
        "GET: Pobranie danych": "",
        "GET: Pobranie wpisu": "",
        "GET: Schemat (definicja formularza)": "",
        "Godziny": "",
        "Google Translate V2": "",
        "GPT-4 Vision": "",
        "GPT-4o Omni": "",
        "Grafika": "",
        "Grafika wektorowa": "",
        "Grudzień": "",
        "Grupa": "",
        "Harmonogram zadań": "",
        "Harmonogram został zmieniony": "",
        "Hasło": "",
        "Hasło (Klucz ukryty)": "",
        "Hasło musi zawierać co najmniej jeden znak specjalny np.: ~ @ # $ % ^ & *": "",
        "Hasło użytkownika serwisowego": "",
        "Hasło zostało zmienione. Zaloguj się ponownie": "",
        "Health": "",
        "Hierarchia": "",
        "Historia": "",
        "Historia logowania": "",
        "Historia zadania": "",
        "Historia zapytań do AI": "",
        "Host": "",
        "Host dodatkowy": "",
        "Host główny": "",
        "i": "",
        "Id": "",
        "ID": "",
        "ID lub typ zadania": "",
        "Id modelu: %0": "",
        "Id obiektu": "",
        "Id sesji": "",
        "Id wpisu": "",
        "ID zadania": "",
        "Id zapytania": "",
        "Id zdarzenia": "",
        "Identyfikator": "",
        "Identyfikator wdrożenia": "",
        "Identyfikator zadania": "",
        "Ikona": "",
        "Ilość wierszy": "",
        "Ilość wyników do odrzucenia": "",
        "Ilość wyników do pobrania": "",
        "Ilość znaków": "",
        "Ilość znaków odpowiedzi": "",
        "Imię": "",
        "Imię i Nazwisko": "",
        "Import": "",
        "Import modułu": "",
        "Import użytkowników": "",
        "Importuj definicję formularza": "",
        "Indeks": "",
        "Indeks został usunięty.": "",
        "Informacja": "",
        "Inspekcja": "",
        "Instalacja": "",
        "Instrukcja": "",
        "Instrukcja modelu (prompt).": "",
        "Interpreter kodu": "",
        "Interpreter kodu umożliwia asystentowi pisanie i uruchamianie kodu. To narzędzie może przetwarzać pliki z różnymi danymi i formatowaniem oraz generować pliki.": "",
        "Jakość": "",
        "Jakość HD": "",
        "Jakość standardowa": "",
        "Jakość wygnerowanego obrazu.": "",
        "Jasny": "",
        "Jedna kolumna": "",
        "Jeśli danego użytkownika nie ma na liście, oznacza to, że brakuje podstawowych danych takich jak Imię i Nazwisko. Uzupełnij te dane w dziale Użytkownicy.": "",
        "Jeśli pole jest puste, wówczas używana jest wartość domyślna. W przeciwnym wypadku wartość domyślna jest nadpisywana.": "",
        "Kalendarz - Kategorie zasobów": "",
        "Kanał jest domyślnie wymagany. Nie da się wyłączyć wysyłania powiadomienia tym kanałem. Możliwa jest tylko zmiana tytułu i treści komunikatu.": "",
        "kanał wymagany": "",
        "Kanały": "",
        "Kanały komunikacji": "",
        "Kanały obsługiwane przez wybrany typ powiadmienia": "",
        "Kanały włączone w ustawieniach aplikacji": "",
        "Katalog": "",
        "Katalog główny": "",
        "Katalog został utworzony": "",
        "Katalog został utworzony.": "",
        "Kategoria": "",
        "Kategoria jest wymagana": "",
        "Kategoria została dodana.": "",
        "Kategoria została usunięta.": "",
        "Kategoria została zaktualizowana.": "",
        "Kategorie": "",
        "Key": "",
        "Key pair": "",
        "Klienci": "",
        "Klient aktywny": "",
        "Klient został dodany.": "",
        "Klient został usunięty.": "",
        "Klient został zaktualizowany.": "",
        "Klucz": "",
        "Klucz API": "",
        "Klucz prywatny": "",
        "Klucz publiczny": "",
        "Klucze": "",
        "Klucze są nie prawidłowe.": "",
        "Klucze są prawidłowe.": "",
        "Kod pocztowy": "",
        "Kod walidacji": "",
        "Kod wertfikacyjny": "",
        "Kod weryfikacyjny jest nieprawidłowy.": "",
        "Kod został skopiowany do schowka.": "",
        "Kod źródłowy": "",
        "Kolejka": "",
        "Kolor drugorzędny: %0": "",
        "Kolor icon na panelu z aplikacjami: %0": "",
        "Kolor podstawowy: %0": "",
        "Kolor przewodni obrazu": "",
        "Kolor tekstu aktywnej pozycji menu: %0": "",
        "Kolor tła panelu z aplikacjami: %0": "",
        "Kolory zostały przywrócone.": "",
        "Kolory zostały zapisane.": "",
        "Kolumna": "",
        "Kolumny": "",
        "Komponent którego wartość zostanie użyta jako nazwa elementu nadrzędnego. Dostępne tylko komponenty typu ``Tekst``.": "",
        "Komponent relacyjny": "",
        "Komponent został dodany.": "",
        "Komponent został usunięty.": "",
        "Komponent został zaktualizowany.": "",
        "Komponent źródłowy": "",
        "Komponenty": "",
        "Komunikat": "",
        "Komunikat błędu": "",
        "Komunikat walidacji": "",
        "Konfiguracja": "",
        "Konfiguracja asystenta ai": "",
        "Konfiguracja domyślna": "",
        "Konfiguracja harmonogramu (czas UTC)": "",
        "Konfiguracja kolumn tabeli": "",
        "Konfiguracja stopki oraz logo umieszczanych w wysyłanych powiadomieniach kanałem mailowym. Stopka może być skonfigurowana indywidualnie dla każdej wersji jezykowej, logo jest wspólne dla wszystkich.": "",
        "Konfiguracja zaawansowana": "",
        "Konfiguracja zapytania": "",
        "Konta użytkowników": "",
        "Konta użytkowników zostały odblokowane": "",
        "Konta użytkowników zostały przypisane do zespołu": "",
        "Konta użytkowników zostały usunięte z systemu": "",
        "Konta użytkowników zostały zablokowane": "",
        "Kontakt": "",
        "Kontakty - Typ działania": "",
        "Kontener plików": "",
        "Konto aktywne": "",
        "Konto pozostanie nieaktywne dopóki adres e-mail nie zostanie potwierdzony": "",
        "Konto z dostępem do panelu administracyjnego": "",
        "Konto zanonimizowane": "",
        "Kontrolka": "",
        "Kontrolka wyboru (pojedynczy wybór)": "",
        "Kontrolka wyboru (wybór wielokrotny)": "",
        "Konwertuj obrazy do formatu WebP": "",
        "Kopiuj": "",
        "Kopiuj adres": "",
        "Kopiuj definicję": "",
        "Kopiuj pole": "",
        "Kosz": "",
        "Kraj": "",
        "Krok minut": "",
        "Krok minut musi być liczbą z zakresu od 1 do 60.": "",
        "Kroki": "",
        "Krótki opis": "",
        "Kwiecień": "",
        "LDAPS (LDAP over SSL)": "",
        "Lewy panel": "",
        "Licencja": "",
        "Liczba": "",
        "Liczba całkowita": "",
        "Liczba danych wejściowych podana w tokenach.": "",
        "Liczba danych wyjściowych podana w tokenach.": "",
        "Liczba dziesiętna": "",
        "Liczba wariantów": "",
        "Liczba wyników na stronie": "",
        "Lider": "",
        "Limit": "",
        "Limit API": "",
        "Limit konta OpenAI": "",
        "Linia": "",
        "linie": "",
        "Lipiec": "",
        "Lista dozwolonych / wykluczonych żądań HTTP": "",
        "Lista efektywnych uprawnień użytkownika wynikających z uprawnień indywidualnych oraz ról.": "",
        "Lista elementów zostanie zawężona tylko do tych, które są pozwiązane z wybranymi wartościami z zaznaczonych komponentów.": "",
        "Lista ID": "",
        "Lista indeksów": "",
        "Lista jest pusta": "",
        "Lista plików": "",
        "Lista powiadomień": "",
        "Lista rozszerzeń oddzielona przecinkami.": "",
        "Lista rozwijana": "",
        "Lista zaufanych adresów IP": "",
        "Listopad": "",
        "Listy": "",
        "Litery": "",
        "Litery i cyfry": "",
        "Logi": "",
        "Logi wydajnościowe": "",
        "Login": "",
        "Logo": "",
        "Logowanie": "",
        "Logowanie zakończyło się niepowodzeniem.": "",
        "Logowanie zależności / żądań typu HTTP": "",
        "Loguj wybrane": "",
        "Lokalizowane": "",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit.": "",
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit.": "",
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quam commodi autem id, veniam, illum temporibus.": "",
        "Luty": "",
        "Łączenie treści": "",
        "Maj": "",
        "Maksimum": "",
        "Maksymalna ilość plików": "",
        "Maksymalna rozdzielość %0x%1px": "",
        "Maksymalny rozmiar pliku w MB": "",
        "Maksymalny rozmiar pliku: %0MB.": "",
        "Maksymalny rozmiar pojedynczego pliku": "",
        "Małe litery": "",
        "Małe znaki": "",
        "Mały": "",
        "Mały układ siatki": "",
        "Mappingi": "",
        "Marzec": "",
        "Metadane": "",
        "Metoda": "",
        "Metoda autentykacji": "",
        "Metoda musi zwracać obiekt \"entry.errors\".": "",
        "Miejsca po przecinku": "",
        "Miejscowość": "",
        "miesiąc": "",
        "Miesiąc": "",
        "Miesiące": "",
        "Migracje": "",
        "Miniaturki": "",
        "Minimalna długość hasła": "",
        "Minimalna ilość plików": "",
        "Minimum": "",
        "Minuty": "",
        "Mnożenie": "",
        "Model": "",
        "Model danych": "",
        "Moderacja włączona": "",
        "Moderacja wyłączona": "",
        "Moduł": "",
        "Moduł został dodany.": "",
        "Moduł został usunięty.": "",
        "Moduł został wyeksportowany.": "",
        "Moduł został zaktualizowany.": "",
        "Moduły": "",
        "Modyfikacja": "",
        "Modyfikacja dokumentów": "",
        "Modyfikuj istniejący wątek": "",
        "Moje dokumenty": "",
        "Moje filtry": "",
        "Moje konto": "",
        "Moje pliki": "",
        "Monitor kolejek": "",
        "Monitoring": "",
        "Monitoruj statystyki .NET": "",
        "Monitoruj żądania HTTP": "",
        "Możesz podać nową nazwę pliku.": "",
        "Możliwość modyfikacji tego działu jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "",
        "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "",
        "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są synchronizowane z zewnętrzną bazą danych %0.": "",
        "Możliwość wielokrotnego wyboru": "",
        "Mój tytuł": "",
        "Multimedia": "",
        "multipart/form-data": "",
        "Na pewno usunąć": "",
        "Na pewno usunąć wybrane elementy?": "",
        "Nadawanie uprawnień do dokumentów": "",
        "Nadawanie uprawnień do własnych dokumentów": "",
        "Nadawanie uprawnień do wszystkich dokumentów": "",
        "Nadawca": "",
        "Nagłówek": "",
        "Nagłówek dokumentu": "",
        "Najstarsza wiadomość": "",
        "Narzędzia": "",
        "Narzędzie integruje się z platformą Firebase (powiadomienia push)": "",
        "Narzędzie integruje się z platformą SMSAPI.pl": "",
        "Następna strona": "",
        "Nazwa": "",
        "Nazwa aliasu": "",
        "Nazwa asystenta": "",
        "Nazwa bazy danych": "",
        "Nazwa działu": "",
        "Nazwa filtra": "",
        "Nazwa formularza": "",
        "Nazwa funkcji": "",
        "Nazwa jest wymagana": "",
        "Nazwa katalogu": "",
        "Nazwa katalogu została zmieniona.": "",
        "Nazwa klienta": "",
        "Nazwa kolumny": "",
        "Nazwa kolumny z opisem": "",
        "Nazwa kolumny z wartością": "",
        "Nazwa komponentu": "",
        "Nazwa konta (Klucz dostępu)": "",
        "Nazwa modelu": "",
        "Nazwa modułu": "",
        "Nazwa musi mieć co najmniej 3 znaki": "",
        "Nazwa musi mieć co najmniej 5 znaków": "",
        "Nazwa nadawcy": "",
        "Nazwa narzędzia": "",
        "Nazwa nie może być dłuższa niż 30 znaków": "",
        "Nazwa nie może być dłuższy niż 50 znaków": "",
        "Nazwa pliku": "",
        "Nazwa pliku została zmieniona.": "",
        "Nazwa pola": "",
        "Nazwa powiadomienia": "",
        "Nazwa przycisku ``Dalej``": "",
        "Nazwa przycisku ``Wstecz``": "",
        "Nazwa przycisku ``Wyślij``": "",
        "Nazwa roli": "",
        "Nazwa sekcji w menu": "",
        "Nazwa sekcji...": "",
        "Nazwa serwera": "",
        "Nazwa szablonu": "",
        "Nazwa typu": "",
        "Nazwa urządzenia": "",
        "Nazwa użytkownika": "",
        "Nazwa użytkownika serwisowego": "",
        "Nazwa wątku": "",
        "Nazwa webhooka": "",
        "Nazwa zadania": "",
        "Nazwa zasobu": "",
        "Nazwa zespołu": "",
        "Nazwa źródła": "",
        "Nazwa źródła danych": "",
        "Nazwisko": "",
        "Nazwy wartości": "",
        "Nie": "",
        "Nie loguj wybranych": "",
        "Nie masz uprawnień do panelu administracyjnego.": "",
        "Nie masz uprawnień do tego dokumentu": "",
        "Nie masz uprawnień do tego widoku. Stan widoku mógł ulec zmianie, odśwież i sprawdź lub spróbuj ponownie później.": "",
        "Nie możesz dodać tego samego widgetu dwa razy": "",
        "Nie możesz modyfikować profilu zastępowanego użytkownika.": "",
        "Nie możesz modyfikować uprawnień": "",
        "Nie można usunąć swojego konta.": "",
        "Nie podano wymaganej ilości znaków: %0.": "",
        "Nie podano wymaganej ilości znaków: %0. (%1)": "",
        "Nie udało się pobrać listy działów": "",
        "Nie udało się pobrać listy filtrów": "",
        "Nie udało się pobrać listy kanałów": "",
        "Nie udało się pobrać listy kolejek": "",
        "Nie udało się pobrać listy powiadomień": "",
        "Nie udało się pobrać listy ról": "",
        "Nie udało się pobrać listy serwerów baz danych": "",
        "Nie udało się pobrać listy serwerów plików": "",
        "Nie udało się pobrać listy typów modeli": "",
        "Nie udało się pobrać listy wydziałów": "",
        "Nie udało się pobrać listy zespołów": "",
        "Nie udało się pobrać obrazu.": "",
        "Nie udało się pobrać powiadomień.": "",
        "Nie udało się pobrać ustawień kont użytkowników": "",
        "Nie udało się pobrać widgetu/ów": "",
        "Nie udało się usunąć szablonu": "",
        "Nie udało się wygenerować formularza": "",
        "Nie udało się wykonać akcji": "",
        "Nie udało się wysłać pliku": "",
        "Nie udało się załadować widgetu AI.": "",
        "Nie udało się zapisać filtra": "",
        "Nie wszystkie wybrane elementy zostały przeniesione. Nie posiadasz wymaganych uprawnień lub, w przypadku przenoszenia katalogów, katalog docelowy jest podkatalogiem katalogu źródłowego. Katalogi i pliki których nie udało się przenieść pozostały nadal w zakładce Wybrane.": "",
        "Nie wybrano żadnego użytkownika.": "",
        "Nie wymaga uwierzytelniania": "",
        "nie wymuszaj zmiany hasła": "",
        "Nie zdefiniowano żadnych ról.": "",
        "Nie znaleziono dostępnych widgetów": "",
        "Nie znaleziono filtrów.": "",
        "Nie znaleziono formularza.": "",
        "Nie znaleziono kolumn.": "",
        "Nie znaleziono pasującego widgetu": "",
        "Nie znaleziono pasujących elementów.": "",
        "Nie znaleziono żadnego pola.": "",
        "Nie znaleziono żadnych plików": "",
        "Nie znaleziono żadnych użytkowników.": "",
        "Nie znaleziono żadnych wyników.": "",
        "Nieaktywne": "",
        "Nieaktywni": "",
        "Nieaktywny": "",
        "niedozwolone rozszerzenie pliku (%0)": "",
        "niedozwolony typ pliku (%0)": "",
        "Niepoprawne wyrażenie regularne": "",
        "Niepotwierdzeni": "",
        "Nieprawidłowa definicja": "",
        "Nieprawidłowy adres email": "",
        "Nieprawidłowy adres URL": "",
        "Nieprzeczytane": "",
        "Niestandardowy błąd": "",
        "Nieudane": "",
        "Nieudane logowania": "",
        "Niewidoczne": "",
        "Nieznane narzędzie": "",
        "Nieznany model": "",
        "Nieznany typ pliku": "",
        "Nigdy": "",
        "Nikomu nie przydzielono uprawnień": "",
        "no-store (wyłącza cache)": "",
        "Nowa data": "",
        "Nowe hasło": "",
        "np. (&(objectClass=organizationalUnit))": "",
        "np. (&(objectClass=user)": "",
        "Numer domu": "",
        "Numer mieszkania": "",
        "Numer sesji": "",
        "Numer telefonu": "",
        "OAuth2 dla Exchange Online": "",
        "Obiekt %0 został %1": "",
        "Obok siebie": "",
        "Obraz został pomyślnie opisany": "",
        "Obraz został wycięty.": "",
        "Obraz został wygenerowany.": "",
        "Obsługiwane formaty plików: txt, md, pdf, docx.": "",
        "Oczekuje na wysłanie": "",
        "od": "",
        "Od": "",
        "od %0": "",
        "Odblokuj": "",
        "Odejmowanie": "",
        "Odpowiedzi": "",
        "Odpowiedzi: %0 na %1": "",
        "Odpowiedź": "",
        "Odpowiedź dowolna": "",
        "Odrzucone": "",
        "Odstęp": "",
        "Odśwież": "",
        "Odznacz": "",
        "Odznacz wszystkie": "",
        "Ok": "",
        "Omnitool": "",
        "Opcje": "",
        "OpenAI": "",
        "Operacje": "",
        "Opis": "",
        "Opis błędu": "",
        "Opis funkcji": "",
        "Opis obrazu": "",
        "Opis zespołu": "",
        "Opis źródła": "",
        "Opisy": "",
        "Opisz jaki formularz chcesz wygenerować": "",
        "Optymalizuj rozdzielczość obrazów": "",
        "Oraz hasło": "",
        "Oraz nowe hasło": "",
        "Organizacja": "",
        "Organizacja została usunięta.": "",
        "Osoba zastępowana": "",
        "Osoba zastępująca": "",
        "Ostatnia aktywność": "",
        "Ostatnia strona": "",
        "Ostrzeżenia": "",
        "Ostrzeżenie": "",
        "Otwórz": "",
        "Oznacz": "",
        "Oznacz jako nieprzeczytane": "",
        "Oznacz jako przeczytane": "",
        "Oznacz powiadomienia": "",
        "Oznacz wszystkie jako przeczytane": "",
        "Oznacz wybrane jako nieprzeczytane": "",
        "Oznacz wybrane jako przeczytane": "",
        "Paleta kolorów": "",
        "Parametry funkcji": "",
        "Parametry, wymagane do wykonania zapytania:": "",
        "Pasek": "",
        "Pasek postępu": "",
        "Październik": "",
        "Pełny": "",
        "Pierwiastkowanie wartości kolumny": "",
        "Pierwsza strona": "",
        "Pigułki": "",
        "Piktogram": "",
        "Plik audio": "",
        "Plik binarny": "",
        "Plik CSS": "",
        "Plik CSV": "",
        "Plik DOC": "",
        "Plik DOCX": "",
        "Plik GIF": "",
        "Plik HTML": "",
        "Plik jest wysyłany": "",
        "Plik JPEG": "",
        "Plik JPG": "",
        "Plik MP4": "",
        "Plik PNG": "",
        "Plik skompresowany": "",
        "Plik ZIP": "",
        "Plik został dodany.": "",
        "Plik został usunięty.": "",
        "Plik został wysłany": "",
        "Plik:": "",
        "Pliki": "",
        "Pliki asystenta": "",
        "Pliki do wysłania: %0": "",
        "Pliki prywatne": "",
        "Pliki publiczne": "",
        "Pliki współdzielone": "",
        "Pliki: %0": "",
        "po %0 nieudanych próbach logowania": "",
        "Po jednym synonimie w wierszu": "",
        "Po wybraniu tej opcji konta użytkowników zostaną usunięte z systemu. Czy chcesz kontynuować?": "",
        "Po zaznaczeniu tej opcji uprawnienia indywidualne i pochodzące z ról nie są brane pod uwagę.": "",
        "Pobierz": "",
        "Początkowa ilość wierszy": "",
        "Poczta": "",
        "Podaj kod weryfikacyjny": "",
        "Podaj nową nazwę": "",
        "Podaj swój adres email": "",
        "Podana nazwa jest zajęta.": "",
        "Podana wartość jest nieprawidłowa.": "",
        "Podanie nazwy jest wymagane.": "",
        "Podano adres": "",
        "Podano nieprawidłowe dane": "",
        "Podano nieprawidłowe dane.": "",
        "Podano nieprawidłowe dane. (%0)": "",
        "Podczas pierszego uruchomienia wymagane jest utworzenie konta głównego administratora.": "",
        "Podgląd": "",
        "Podgląd formularza": "",
        "Podgląd tabeli": "",
        "Podkreślenie": "",
        "Podpis": "",
        "Podstawowe": "",
        "Podstawowe operacje na datach": "",
        "Podstawowe operacje na liczbach": "",
        "Podstawowe operacje tekstowe": "",
        "Podstawowe operacje warunkowe": "",
        "Podziedziałek - Piątek": "",
        "Pojedynczy wybór": "",
        "Pokaż etykietę": "",
        "Pokaż historię": "",
        "Pokaż numery stron": "",
        "Pokaż przycisk ``Wyślij``": "",
        "Pokaż szczegóły": "",
        "Pokaż tylko moje dokumenty": "",
        "Pokaż tytuł formularza": "",
        "Pokaż tytuł sekcji": "",
        "Pokaż tytuł strony": "",
        "Pokaż tytuły stron": "",
        "Pokaż w koncie użytkownika": "",
        "Pokaż więcej": "",
        "pokaż więcej...": "",
        "Pole \"%0\" jest wymagane.": "",
        "Pole \"%0\" jest wymagane. (%1)": "",
        "Pole \"%0\" nie zawiera prawidłowego adresu email.": "",
        "Pole \"%0\" nie zawiera prawidłowego adresu URL.": "",
        "Pole \"%0\" zawiera niedozwoloną wartość.": "",
        "Pole \"Drugie imię\" jest wymagane.": "",
        "Pole \"Email\" jest wymagane.": "",
        "Pole \"Imię\" jest wymagane.": "",
        "Pole \"Nazwa użytkownika\" jest wymagane.": "",
        "Pole \"Nazwisko\" jest wymagane.": "",
        "Pole \"Tytuł\" jest wymagane.": "",
        "Pole jest wymagane": "",
        "Pole może zawierać jedynie cyfry.": "",
        "Pole może zawierać jedynie cyfry. (%0)": "",
        "Pole może zawierać jedynie litery i cyfry.": "",
        "Pole może zawierać jedynie litery i cyfry. (%0)": "",
        "Pole może zawierać jedynie litery.": "",
        "Pole może zawierać jedynie litery. (%0)": "",
        "Pole może zawierać tylko ciało metody walidującej dane w postaci natywnego kodu JavaScript.": "",
        "Pole nie może zawierać spacji": "",
        "Pole skopiowane": "",
        "Pole tekstowe": "",
        "Pole typu \"%0\" jest niedozwolone w tym formularzu.": "",
        "Pole wymaga podania przynajmniej jednego znaku specjalnego.": "",
        "Pole wymaga podania przynajmniej jednej cyfry.": "",
        "Pole wymaga podania przynajmniej jednej dużej litery.": "",
        "Pole wymaga podania przynajmniej jednej małej litery.": "",
        "Pole wymagane": "",
        "Pole zawiera": "",
        "Polityka prywatności": "",
        "Polski": "",
        "Pomoc": "",
        "Poprawne": "",
        "Poprawny": "",
        "Poprzednia strona": "",
        "Porównaj %0": "",
        "Porównaj z edytowaną wersją": "",
        "Porównania": "",
        "Porównanie": "",
        "Porównywana wersja": "",
        "Port": "",
        "Posiada załączniki": "",
        "POST: Lista wpisów (dowolne wyszukiwanie)": "",
        "POST: Upload załączników": "",
        "POST: Utworzenie wpisu": "",
        "POST: Zapisanie zmian": "",
        "Potwierdzenia": "",
        "Potwierdzenie przywrócenia": "",
        "Potwierdzenie usunięcia": "",
        "Potwierdzenie włączenia": "",
        "Potwierdzenie wylogowania użytkownika": "",
        "Potwierdzenie wyłączenia": "",
        "Potwierdzenie zmiany statusu": "",
        "Potwierdź": "",
        "Potwierdź akceptację": "",
        "Powiadomienia": "",
        "Powiadomienia mailowe": "",
        "Powiadomienia zostały skonfigurowane.": "",
        "Powiadomienie jest domyślnie wymagane. Nie da się wyłączyć wysyłania tego powiadomienia.": "",
        "Powiadomienie testowe zostało wysłane.": "",
        "Powiększ": "",
        "Powinno się dodać dyrektywę PRIVATE dla treści personalizowanych dla użytkownika, zwłaszcza dla odpowiedzi otrzymywanych po zalogowaniu i z uwzględnieniem uprawnień.": "",
        "powrót": "",
        "Powrót": "",
        "Powtórz hasło": "",
        "Powtórzone": "",
        "Powyższe obiekty zawierają tylko właściwości i są pozbawione wszystkich metod.": "",
        "Poziom dostępu do webhooka": "",
        "Poziom dostępu użytkownika": "",
        "Pozostałe": "",
        "Pozostało %0 znaków.": "",
        "Pozwól na dodawanie wierszy": "",
        "Pozycja": "",
        "pół roku": "",
        "Prawy panel": "",
        "Predefiniowane tła panelu z aplikacjami": "",
        "Predefiniowane zestawy kolorów": "",
        "private (tylko cache przeglądarki)": "",
        "Procent": "",
        "Profil użytkownika": "",
        "Profil został zaktualizowany.": "",
        "Projekt": "",
        "Prometheus": "",
        "Proporcje obrazu": "",
        "Proste uprawnienia": "",
        "Próbkowanie": "",
        "Prywatne": "",
        "Przebudowanie indeksów": "",
        "Przebudowanie indeksównnOperacja może trwać od kilku sekund do kilku godzin w zależności od rozmiaru indeksów.nnNależy używać tylko w przypadku konieczności zaaplikowania zmian dokonanych w słownikach synonimów lub słów wykluczonych.": "",
        "Przeciągnij i upuść aby posortować pliki": "",
        "Przeczytane": "",
        "Przedrostek": "",
        "Przeglądarka z której korzystasz nie wspiera powiadomień PUSH": "",
        "Przeindeksowanie danych": "",
        "Przeindeksowanie danychnnOperacja może trwać od kilku sekund do kilku godzin w zależności od ilości danych w bazie.nnNależy używać tylko w przypadku zauważenia braku danych w indeksach.": "",
        "Przejdź do konfiguracji, aby włączyć narzędzia": "",
        "Przekroczono dozwoloną ilość znaków: %0.": "",
        "Przekroczono dozwoloną ilość znaków: %0. (%1)": "",
        "przekroczony dozwolony rozmiar pliku (%0 MB)": "",
        "Przełącznik": "",
        "Przeniesienie wybranych elementów": "",
        "przeniesiony do kosza": "",
        "Przenieś": "",
        "Przenieś${selected.length > 1 ? ' zaznaczone' : ''}": "",
        "przeniósł do kosza": "",
        "Przepisz limit konta oraz aktualne stawki za 1000 tokenów ze strony OpenAI.": "",
        "Przepnij alias": "",
        "Przesuń do góry": "",
        "Przesuń na dół": "",
        "Przesyłanie plików powiodło się.": "",
        "Przetestuj": "",
        "Przetestuj poprawność zapisanych kluczy": "",
        "Przetestuj ustawienia poczty": "",
        "Przetestuj ustawienia Sms": "",
        "Przetwarzane": "",
        "Przybornik": "",
        "Przykład:": "",
        "Przykład: domena\\użytkownik": "",
        "Przykład: użytkownik@domena": "",
        "Przykładowa struktura danych": "",
        "Przykłady": "",
        "Przykłady konfiguracji": "",
        "Przypisanie do zespołu": "",
        "Przypisanie ról": "",
        "Przyrostek": "",
        "Przywrócenie": "",
        "przywrócił z kosza": "",
        "przywrócony z kosza": "",
        "Przywróć": "",
        "Przywróć domyślne": "",
        "Przywróć filtr": "",
        "Przyznaj dostęp": "",
        "public (cache przeglądarki i proxy)": "",
        "Publiczne": "",
        "PUT: Aktualizacja wpisu": "",
        "Pytania": "",
        "Query string": "",
        "Radio": "",
        "Ranga": "",
        "ReCaptcha": "",
        "Region": "",
        "Rejestr zmian": "",
        "Relacyjne": "",
        "Repozytorium plików": "",
        "Request body": "",
        "Resetuj": "",
        "RESTful API": "",
        "Rodzaj odpowiedzi": "",
        "Rodzaj źródła": "",
        "rok": "",
        "Rok": "",
        "Rola": "",
        "Rola aktywna": "",
        "Rola domyślna": "",
        "Rola została dodana.": "",
        "Rola została usunięta.": "",
        "Rola została zaktualizowana.": "",
        "Role i uprawnienia": "",
        "Role użytkownika": "",
        "Role zespołu": "",
        "Role zostały przypisane": "",
        "Root": "",
        "Rozdzielczość": "",
        "Rozdzielczość wygnerowanego obrazu.": "",
        "Rozmiar": "",
        "Rozmiar okna popup": "",
        "Rozmiar pliku": "",
        "Rozmiar podawany w bajtach.": "",
        "Rozmiar zdjęcia został zmieniony.": "",
        "Rozmieść równomiernie": "",
        "Równość": "",
        "Satysfakcja": "",
        "Scrollowalne okna popup": "",
        "Sekcja": "",
        "SenderId": "",
        "Sentry": "",
        "Server Side Cache": "",
        "Serwer bazy danych": "",
        "Serwer dostępny": "",
        "Serwer plików": "",
        "Serwer został dodany.": "",
        "Serwer został usunięty.": "",
        "Serwer został zaktualizowany.": "",
        "Serwery plików": "",
        "Sesja": "",
        "Sesja nr %0": "",
        "Sesja użytkownika": "",
        "Sheduler": "",
        "Sierpień": "",
        "Skala 1-5": "",
        "Skopiuj kod": "",
        "słowa": "",
        "Słowa wykluczone": "",
        "Słownik": "",
        "Słownik dowolny": "",
        "Słownik systemowy": "",
        "Słowo": "",
        "Słowo bazowe": "",
        "Słowo wykluczone": "",
        "Słowo zostało dodane.": "",
        "Słowo zostało usunięte.": "",
        "Słowo zostało zaktualizowane.": "",
        "Sortowanie malejąco": "",
        "Sortowanie rosnąco": "",
        "Specjalizowane": "",
        "Specyfikacja": "",
        "Sposób prezentacji zakładek": "",
        "Sprawdzenie czy pusty": "",
        "Stała wartość": "",
        "Stan powiadomienia": "",
        "Standardowa szczegółowość": "",
        "Standardowe uprawnienia": "",
        "Start": "",
        "Status": "",
        "Status dokumentu został zmieniony.": "",
        "Status edytowanej wersji dokumentu": "",
        "Status modułu": "",
        "Status webhooka": "",
        "Status żądania": "",
        "Stopka": "",
        "Strona": "",
        "Strona %0": "",
        "Strona główna": "",
        "Struktura organizacyjna": "",
        "Studio": "",
        "Stwierdzenie": "",
        "Styczeń": "",
        "Styl obrazu": "",
        "Sukces": "",
        "Suma danych wejściowych i wyjściowych podana w tokenach.": "",
        "Suma tokenów": "",
        "Symbol": "",
        "Symbol jest wymagany": "",
        "Symbol jest zajęty": "",
        "Symbol klienta": "",
        "Symbol musi mieć co najmniej 3 znaki": "",
        "Symbol musi mieć co najmniej 5 znaków": "",
        "Symbol nie może być dłuższy niż 10 znaków": "",
        "Symbol nie może być dłuższy niż 20 znaków": "",
        "Symbol w adresie URL": "",
        "Synonimy": "",
        "Synonimy zostały dodane.": "",
        "Synonimy zostały usunięte.": "",
        "Synonimy zostały zaktualizowane.": "",
        "System": "",
        "System powiadomień": "",
        "Szablon modułu": "",
        "Szablon został dodany.": "",
        "Szablon został usunięty.": "",
        "Szablon został zaktualizowany.": "",
        "Szacowana kwota:": "",
        "Szczegółowość obrazu": "",
        "Szczegóły": "",
        "Szczegóły asystenta": "",
        "Szczegóły elementu": "",
        "Szczegóły zadania": "",
        "Szczegóły zapytania": "",
        "Szczegóły zdarzenia": "",
        "Szczegóły zdarzenia nr %0": "",
        "Szerokość": "",
        "Szkic": "",
        "Szukaj": "",
        "Szukaj we wszystkich wersjach": "",
        "Szukana fraza": "",
        "Szukane słowo": "",
        "Średni": "",
        "Ta strona jest chroniona przez reCAPTCHA i Google.": "",
        "Tabela": "",
        "Tagi": "",
        "Tak": "",
        "Tak/Nie": "",
        "Tekst": "",
        "Tekst alternatywny": "",
        "Tekst lokalizowany": "",
        "Tekst zastępczy": "",
        "Telefon": "",
        "Ten dashboard nie zawiera jeszcze żadnych widgetów": "",
        "Ten element nie zawiera menu kontekstowego.": "",
        "Ten kanał ma możliwość wysyłania tekstu sformatowanego.": "",
        "Ten kanał nie ma możliwości wysyłania tekstu sformatowanego.": "",
        "Tenant ID": "",
        "Teraz": "",
        "Terminy do słownika będzie można dodawać dopiero po zapisaniu źródła danych.": "",
        "Test konfiguracji": "",
        "Test ustawień pomyślnie zakończony.": "",
        "Testowy adres email": "",
        "Testowy numer telefonu": "",
        "Testuj": "",
        "Testuj zapytanie": "",
        "Tłumaczenia": "",
        "To pole jest wymagane": "",
        "Token": "",
        "Token został usunięty.": "",
        "Tokeny": "",
        "Tokeny dostępu": "",
        "Tokeny wejściowe": "",
        "Tokeny wyjściowe": "",
        "Tracing": "",
        "Treści odpowiedzi są wymagane": "",
        "Treści pytań są wymagane": "",
        "Treść": "",
        "Trigger": "",
        "Trwa generowanie formularza": "",
        "Trwa generowanie obrazów": "",
        "Trwa generowanie obrazu": "",
        "Trwa generowanie opisu dla wybranego obrazu": "",
        "Tryb konserwacji systemu": "",
        "Trzy kolumny": "",
        "Twoje hasło wygasło. Zmień je na nowe.": "",
        "Tworzenie dokumentów": "",
        "Twój token": "",
        "tydzień": "",
        "Tydzień": "",
        "Tylko do odczytu": "",
        "tylko dokumenty wersjonowane": "",
        "Tylko liderzy": "",
        "Tylko serwer deweloperski. Nie używać w trybie produkcyjnym. ": "",
        "Typ": "",
        "Typ konta": "",
        "Typ logowania": "",
        "Typ mime": "",
        "Typ obiektu": "",
        "Typ pliku": "",
        "Typ pola": "",
        "Typ powiadomienia": "",
        "Typ serwera": "",
        "Typ słownika": "",
        "Typ właściwy": "",
        "Typ źródła": "",
        "Typ źródła danych": "",
        "typu \"%0\" o ID \"%1\"": "",
        "Tytuł": "",
        "Tytuł formularza jest wymagany": "",
        "Tytuł formularza nie może być dłuższy niż %0 znaków": "",
        "Tytuł sekcji": "",
        "Udało się zapisać ustawienia widgetów": "",
        "Układ": "",
        "Układ listy": "",
        "Ukryta": "",
        "Ukryty": "",
        "Ulica": "",
        "Upload": "",
        "Uprawnienia": "",
        "Uprawnienia do dokumentów": "",
        "Url": "",
        "Uruchom zadanie": "",
        "Urządzenie zostało usunięte.": "",
        "Usługi": "",
        "Ustaw szerokości proporcjonalnie": "",
        "Ustawienia": "",
        "Ustawienia Firebase": "",
        "Ustawienia konta": "",
        "Ustawienia powiadomień zostały zaktualizowane.": "",
        "Ustawienia sesji": "",
        "ustawienia widgetu": "",
        "Ustawienia widgetu": "",
        "Ustawienia zaawansowane": "",
        "Ustawienia zostały zapisane.": "",
        "usunął": "",
        "Usunął": "",
        "Usunięcie": "",
        "Usunięcie dokumentu": "",
        "Usunięcie formularzannOperacja jest nieodwracalna. Zostaną utracone wszystkie powiązane dokumenty!nnCzy na pewno chcesz usunąć wybrany formularz?": "",
        "Usunięcie indeksunnOperacja jest nieodwracalna. Czy na pewno chcesz usunąć wybrany indeks?": "",
        "Usunięcie wybranych elementów": "",
        "usunięty": "",
        "Usuń": "",
        "Usuń datę": "",
        "Usuń dział": "",
        "Usuń filtr": "",
        "Usuń grupę": "",
        "Usuń indeks": "",
        "Usuń plik": "",
        "Usuń plik z listy": "",
        "Usuń pozycję": "",
        "Usuń sekcję": "",
        "Usuń spacje": "",
        "Usuń urządzenie": "",
        "Usuń urządzenie aby odwołać zapamiętane automatyczne logowanie na swoje konto": "",
        "Usuń z zespołu": "",
        "Usuń${selected.length > 1 ? ' zaznaczone' : ''}": "",
        "Usuwanie dokumentów": "",
        "Usuwanie użytkowników": "",
        "Usuwanie własnych dokumentów": "",
        "Usuwanie wszystkich dokumentów": "",
        "Utworzył": "",
        "Utwórz grupy": "",
        "Utwórz katalog": "",
        "Utwórz kopię formularza": "",
        "Utwórz kopię z": "",
        "Utwórz nowy": "",
        "Utwórz nowy wątek": "",
        "UWAGA: Brak obsługi TypeScript oraz w kodzie metody nie można robić importów.": "",
        "Uwzględnij wydziały podrzędne": "",
        "Użyte narzędzie": "",
        "Użytkownicy": "",
        "Użytkownicy w tej roli": "",
        "Użytkownik": "",
        "Użytkownik \"%0\" %1 obiekt %2": "",
        "Użytkownik jest liderem zespołu": "",
        "Użytkownik nie może się zalogować dopóki konto nie jest aktywne.": "",
        "Użytkownik nie należy do żadnego zespołu.": "",
        "Użytkownik posiada pełne uprawnienia": "",
        "Użytkownik został dodany do zespołu.": "",
        "Użytkownik został dodany.": "",
        "Użytkownik został usunięty.": "",
        "Użytkownik został wylogowany.": "",
        "Użytkownik został zaktualizowany.": "",
        "Użytkownik został zanonimizowany.": "",
        "Użyty Model": "",
        "W ciele metody walidującej dostępne są poniższe zmienne:": "",
        "W każdy dzień tygodnia": "",
        "W organizacji nie ma jeszcze żadnych działów.": "",
        "W przyszłości": "",
        "W zespole od %0": "",
        "W zespole od %0 do %1": "",
        "Walidacja": "",
        "Walidacja certyfikatu SSL": "",
        "Walidacja danych": "",
        "Walidacja po stronie API": "",
        "Walidacja typów MIME": "",
        "Wartości od 0 - 1.0 (0 oznacza 0%, a 1 oznacza 100%)": "",
        "Wartość": "",
        "Wartość domyślna": "",
        "Wartość domyślna: (&(objectClass=organizationalUnit))": "",
        "Wartość domyślna: (&(objectClass=user)": "",
        "Wartość musi być liczbą całkowitą": "",
        "Wartość musi być liczbą dodatnią": "",
        "Wartość musi być liczbą ujemną": "",
        "Warunki korzystania z usług Google": "",
        "Warunki logiczne": "",
        "Warunkowo": "",
        "Ważne informacje": "",
        "Ważność": "",
        "Wątek": "",
        "Wątek główny": "",
        "Wątek główny jest kontynuowany automatycznie przez asystenta.": "",
        "Wczytywanie danych...": "",
        "Webhook": "",
        "Webhook został dodany.": "",
        "Webhook został usunięty.": "",
        "Webhook został zaktualizowany.": "",
        "Webhooki": "",
        "Weekend": "",
        "Wersja API": "",
        "Wersja utowrzona przez": "",
        "Wersje": "",
        "Wersje dokumentu": "",
        "wersji zaakceptowanej": "",
        "Wersjonowanie": "",
        "Wersjonowanie dokumentów włączone": "",
        "Wersjonowanie dokumentów wyłączone": "",
        "Wersjonowanie można włączyć tylko podczas dodawania nowego formularza.": "",
        "Wewnętrznie": "",
        "Wiadomość testowa została wysłana.": "",
        "Widgety": "",
        "Widoczność": "",
        "Widoczność modułu w menu": "",
        "Widoczność sekcji w menu modułu": "",
        "Widok ustawień": "",
        "Wielkość kroku": "",
        "Wielokrotny wybór": "",
        "Wiersz": "",
        "Więcej": "",
        "więcej...": "",
        "Więcej...": "",
        "Wklej komponent": "",
        "Właściwości": "",
        "Właściwości szablonu": "",
        "Włącz": "",
        "Włącz walidację po stronie API": "",
        "Włącza bardzo restrykcyjne sprawdzanie typów MIME dla wysyłanych plików. W przypadku zgłaszania błędów dla plików, których zawartości jesteśmy pewni, należy tą opcję pozostawić nieaktywną.": "",
        "Włączenie moderacji danych wejściowych jest zalecane.": "",
        "Włączone": "",
        "Włączony": "",
        "Województwo": "",
        "Wpis domyślny": "",
        "Wpis został dodany.": "",
        "Wpis został usunięty.": "",
        "Wpis został zaakceptowany.": "",
        "Wpis został zaktualizowany.": "",
        "Wpisz adresy po przecinku.": "",
        "Wpisz wiadomość": "",
        "Wprowadzamy URL do seriwsów które chemy wykluczyć. Dane mogą być odzielone przecinkiem lub być w nowej linii": "",
        "Wprowadzona wartość jest nieprawidłowa": "",
        "Wprowadzona wartość jest za długa": "",
        "Wprowadzona wartość jest za krótka": "",
        "Wprowadzona wartość jest za niska": "",
        "Wprowadzona wartość jest za wysoka": "",
        "Wprowadzona wartość nie jest poprawna": "",
        "Wróć do logowania": "",
        "Wróć do strony głównej": "",
        "Wrzesień": "",
        "Wskazuje, jak długo odpowiedź pozostaje aktualna w pamięci serwera Redis.": "",
        "Wstaw cztery kolumny 1/4": "",
        "Wstaw dwie kolumny 1/3 oraz 2/3": "",
        "Wstaw dwie kolumny 2/3 oraz 1/3": "",
        "Wstaw dwie równe kolumny": "",
        "Wstaw kontrolkę": "",
        "Wstaw stronę": "",
        "Wstaw szablon informacyjny ze zdjęciem": "",
        "Wstaw trzy kolumny 1/3": "",
        "Wstaw z dołu": "",
        "Wstaw z góry": "",
        "Wstaw z lewej strony": "",
        "Wstaw z prawej strony": "",
        "Wstecz": "",
        "Wstępne filtrowanie danych": "",
        "Wszystkie": "",
        "Wszystkie dane osobowe użytkownika znajdujące się w systemie zostaną usunięte!nnCzy na pewno chcesz wykonać akcję??": "",
        "Wszystkie niezapisane zmiany zostaną utracone.": "",
        "Wszystkie próby": "",
        "Wszystko": "",
        "wybierz": "",
        "Wybierz": "",
        "wybierz długość": "",
        "Wybierz formularz zawierający dodatkowe pola dla kont użytkownków": "",
        "Wybierz katalog do którego chcesz przenieść wybrane elementy.": "",
        "wybierz model": "",
        "Wybierz plik": "",
        "Wybierz plik lub katalog": "",
        "Wybierz plik.": "",
        "Wybierz pliki": "",
        "Wybierz pliki (do {0})": "",
        "Wybierz serwer": "",
        "Wybierz shortcode": "",
        "Wybierz typ powiadomienia aby zobaczyć dostępne klucze.": "",
        "Wybierz użytkownika": "",
        "Wybierz użytkowników dla których chcesz wykonać akcje": "",
        "Wybierz wątek w którym chcesz prowadzić konwersację.": "",
        "Wybierz zaznaczone": "",
        "wybierz...": "",
        "Wybór": "",
        "Wybór języka": "",
        "Wybór+": "",
        "Wybrana data jest mniejsza niż dozwolona %0.": "",
        "Wybrana data jest większa niż dozwolona %0.": "",
        "Wybrana liczba jest mniejsza niż dozwolona %0.": "",
        "Wybrana liczba jest większa niż dozwolona %0.": "",
        "Wybrane": "",
        "Wybrane dokumenty": "",
        "Wybrane elementy zostały przeniesione.": "",
        "Wybrane elementy zostały usunięte.": "",
        "wybrane elementy?": "",
        "Wybrane pliki": "",
        "Wybrane pliki: {0}": "",
        "Wybrane: %0": "",
        "wybranego elementu": "",
        "Wybrano %0 użytkowników.": "",
        "Wybrano jednego użytkownika.": "",
        "Wybrany element został usunięty.": "",
        "wybrany element?": "",
        "Wybrany język": "",
        "wybranych elementów": "",
        "Wybróbuj możliwości AI": "",
        "Wyczyść": "",
        "Wydział": "",
        "Wygenerowana odpowiedź przez model AI.": "",
        "Wygenerowane obrazy": "",
        "Wygeneruj metadane dla przesyłanych obrazów": "",
        "Wyklucz logowanie zależności": "",
        "Wykonaj": "",
        "Wykorzystane": "",
        "Wykresy": "",
        "Wyloguj się": "",
        "Wyloguj zalogowanego obecnie użytkownika": "",
        "Wyłaczone": "",
        "Wyłączony": "",
        "Wymagaj znaków specjalnych w hasłach": "",
        "Wymagane": "",
        "Wymagane jest ustawienie minimalnej długości hasła": "",
        "Wymagane jest wybranie formularza": "",
        "Wymagane jest wybranie kategorii": "",
        "Wymagane jest wybranie komponentu": "",
        "Wymagane jest wybranie modułu": "",
        "Wymagane jest wybranie sekcji": "",
        "Wymagane jest wybranie źródła danych": "",
        "Wymagane znaki": "",
        "Wymiary": "",
        "Wymuś zmianę hasła": "",
        "Wynik testu": "",
        "Wyniki": "",
        "Wyniki testu zawszę będą ograniczone do maksymalnie 10 pozycji.": "",
        "Wyrażenia warunkowe": "",
        "Wyrażenie regularne": "",
        "Wyrażenie w postaci \"/pattern/flag\" nie jest obsługiwane, użyj same wartości pattern": "",
        "Wysłane": "",
        "Wysoka szczegółowość": "",
        "Wysokość": "",
        "Wystąpił błąd podczas ładowania obrazu. Spróbuj ponownie.": "",
        "Wystąpił błąd podczas próby exportu użytkowników.": "",
        "Wystąpił błąd podczas próby importu definicji modułu.": "",
        "Wystąpił błąd podczas próby importu użytkowników.": "",
        "Wystąpił błąd połączenia. Upewnij się że masz włączony internet i spróbuj ponownie.": "",
        "Wystąpił błąd serwera podczas obsługiwania wybranej operacji. Spróbuj ponownie.": "",
        "Wystąpił problem podczas przesyłania obrazu.": "",
        "Wysyłaj dane o użytkowniku": "",
        "Wysyłaj tym kanałem": "",
        "Wyszukaj (Ctrl+Q)": "",
        "wyszukaj użytkownika...": "",
        "Wyszukaj widget": "",
        "Wyszukaj wszędzie": "",
        "Wyszukaj...": "",
        "Wyszukiwanie": "",
        "Wyszukiwanie plików": "",
        "Wyszukiwanie plików umożliwia asystentowi korzystanie z wiedzy z plików. Po przesłaniu pliku asystent automatycznie decyduje, kiedy pobrać treść, na podstawie żądań użytkownika.": "",
        "Wyszukiwany ciąg znaków": "",
        "Wyślij": "",
        "Wyślij kod": "",
        "Wyświetlam": "",
        "Wyświetlanie dokumentów": "",
        "Wytnij": "",
        "Wywołanie funkcji": "",
        "Wyzeruj licznik": "",
        "Wzorzec": "",
        "Z uprawnieniami": "",
        "Z uprawnieniami do modułu": "",
        "Zaakceptowany": "",
        "Zaawansowane uprawnienia": "",
        "Zabierz dostęp": "",
        "Zablokowany": "",
        "Zablokuj": "",
        "Zachowaj oryginalny format obrazów": "",
        "zacznij pisać aby wyszukać użytkownika...": "",
        "Zacznij pisać Form lub Entry aby uruchomić podpowiadanie.": "",
        "Zaczyna sie od": "",
        "Zadanie przebudowania indeksów zostało rozpoczęte.": "",
        "Zadanie przeindeksowania danych zostało rozpoczęte.": "",
        "Zadanie zostało uruchomione.": "",
        "Zadanie zostało włączone": "",
        "Zadanie zostało wyłączone": "",
        "Zagrożenie": "",
        "Zaimportowano definicję modułu.": "",
        "Zaimportowano listę użytkowników.": "",
        "Zakładki": "",
        "Zakolejkowane": "",
        "Zakończ edycje": "",
        "Zakończ sesję": "",
        "Zakończ sesję aby wylogować użytkownika": "",
        "Zakończone": "",
        "Zakres": "",
        "Zakres czasu": "",
        "Zaktualizuj formularz": "",
        "Zależna od kontrolki": "",
        "Zalogowany": "",
        "Zaloguj się": "",
        "Zaloguj się na swoje konto": "",
        "Załączniki": "",
        "Zamiany zostały zapisane.": "",
        "Zamknij": "",
        "Zaokrąglenie": "",
        "Zapamiętaj logowanie": "",
        "Zapamiętane urządzenia": "",
        "Zapisywanie obrazów zakończone.": "",
        "Zapisz": "",
        "Zapisz filtr": "",
        "Zapisz i wróć": "",
        "Zapisz i zamknij": "",
        "Zapisz ustawienia": "",
        "Zapisz ustawienia widgetów": "",
        "Zapisz widget": "",
        "Zapisz wybrane": "",
        "Zapisz zmiany": "",
        "Zaplanowane": "",
        "Zapomniałeś hasła?": "",
        "Zapytanie SQL do pobrania po ID": "",
        "Zapytanie SQL do wyszukiwania": "",
        "Zasięg": "",
        "Zastępstwa": "",
        "Zastępstwo": "",
        "Zastępstwo wygasło.": "",
        "Zastępstwo wygaśnie za 10 minut.": "",
        "Zastępstwo wygaśnie za 5 minut.": "",
        "Zastępstwo wygaśnie za minutę.": "",
        "Zastępstwo zostało dodane.": "",
        "Zastępstwo zostało usunięte.": "",
        "Zastępstwo zostało zaktualizowane.": "",
        "Zastępuje osobę": "",
        "Zastosuj zmiany w aplikacji": "",
        "Zaufane adresy IP": "",
        "Zawiera": "",
        "Zawsze": "",
        "Zaznacz": "",
        "Zaznacz wszystkie": "",
        "Zdarzenie": "",
        "Zdjęcia": "",
        "Zdjęcie": "",
        "Zdjęcie użytkownika": "",
        "Zespoły": "",
        "Zespół": "",
        "Zespół aktywny": "",
        "Zespół został dodany.": "",
        "Zespół został usunięty.": "",
        "Zespół został zaktualizowany.": "",
        "Zgoda": "",
        "Zmiana hasła": "",
        "Zmiana rozmiaru obrazu": "",
        "Zmiana ustawień może doprowadzić do utraty dostępu do panelu!nnCzy na pewno chcesz zapisać zmiany??": "",
        "Zmiany ustawień monitoringu wymagają restartu aplikacji.": "",
        "Zmiany zaczną obowiązywać dopiero po przebudowaniu indeksów.": "",
        "Zmiany zostały zapisane.": "",
        "Zmiany zostaną zastosowane w aplikacji w przeciągu kikunastu sekund": "",
        "Zmieniono ustawienia widgetu": "",
        "Zmień hasło": "",
        "Zmień nazwę": "",
        "Zmień nazwę katalogu": "",
        "Zmień nazwę pliku": "",
        "Zmień rozmiar": "",
        "Zmień status edytowanej wersji": "",
        "Zmień widok": "",
        "Zmniejsz": "",
        "zmodyfikował": "",
        "Zmodyfikował": "",
        "zmodyfikowany": "",
        "znaki": "",
        "Znaki specialne": "",
        "Zobacz sesję użytkownika": "",
        "Zobacz szczegóły sesji użytkownika": "",
        "Zobacz szczegóły zdarzenia": "",
        "Zobacz wszystkie": "",
        "Zobacz wszystkie zastępstwa": "",
        "Został przywrócony oryginalny harmonogram": "",
        "został wysłany": "",
        "Zresetuj hasło": "",
        "Zrestartuj aplikacje": "",
        "Zwrócony": "",
        "Źródła danych": "",
        "Źródła LDAP": "",
        "Źródło": "",
        "Źródło aktywne": "",
        "Źródło danych": "",
        "źródło danych zostało dodane.": "",
        "Źródło danych zostało zaktualizowane.": "",
        "Źródło LDAP zostało dodane.": "",
        "Źródło LDAP zostało zaktualizowane.": "",
        "źródło zostało usunięte.": "",
        "Żaden kanał komunikacji nie został włączony w ustawieniach powiadomień.": "",
        "Żródło danych": ""
    }
};
