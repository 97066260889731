<template>
    <component :is="tag" class="input-group-text">
        <slot name="default"></slot>
    </component>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';

@Options({
    name: 'ideo-form-textarea'
})
export default class IdeoFormTextarea extends Vue
{
    @Prop({ default: 'div' })
    public tag: string;
}
</script>
