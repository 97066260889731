import { createApp } from 'vue';

// Polyfills
import './polyfills';

// Store
import { createPinia } from 'pinia';
import store from './store';

// Router
import router from './router';

// Bootstrap
import logging from '@/plugins/logging';
import axios from '@/plugins/axios';
import config from '@/plugins/config';
import localization from '@/plugins/localization';
import datetime from '@/plugins/datetime';
import events from '@/plugins/events';
import viewstate from '@/plugins/viewstate';
import media from '@/plugins/media';
import directives from '@/plugins/directives';
import mixins from '@/plugins/mixins';
import permissions from '@/plugins/permissions';
import alerts from '@/plugins/alerts';
import firebase from '@/plugins/firebase';
import progress from '@/plugins/progress';
import sitemap from '@/plugins/sitemap';
import auth, { adminAccessPolicy } from '@/plugins/auth';
import signalr from '@/plugins/signalr';
import components from '@/plugins/components';

// PrimeVue
import PrimeVue from 'primevue/config';

// Menu
import menu from '@/settings/sitemap';

// Application
import App from './views/App.vue';

const pinia = createPinia();
const app = createApp(App);

app.use(PrimeVue);
app.use(store);
app.use(pinia);
app.use(router);
app.use(logging);
app.use(axios);
app.use(config);
app.use(localization);
app.use(datetime);
app.use(events);
app.use(viewstate, { router });
app.use(media);
app.use(directives);
app.use(mixins);
app.use(permissions);
app.use(alerts);
app.use(firebase);
app.use(progress, {
    color: '#E77C00',
    failedColor: 'red',
    thickness: '3px',
    autoFinish: false,
    autoRevert: false,
    inverse: false
});
app.use(sitemap, {
    sitemap: menu
});
app.use(auth, {
    routes: {
        homePage: { path: '/orders' },
        loginPage: { path: '/auth/login' },
        forbidden: { path: '/error/403' },
        notFound: { path: '/error/404' },
        changePassword: { name: 'core-account-password' }
    },
    defaultPolicy: adminAccessPolicy
});
app.use(signalr, {
    endpoint: `${import.meta.env.VITE_APP_ADMIN_URL}/hubs`
});
app.use(components);

router.isReady().then(() =>
{
    app.mount('#app');
});
