import { axios } from '@/plugins/axios';
import { Statement, Option } from '@/helpers/Interfaces';
import { $t } from '@/plugins/localization';

/**
 * SettingsService
 */
export default class SettingsService
{
    /**
     * @param key string
     *
     * @returns Promise<Record<string, any>>
     */
    public static async fetch(key: string): Promise<Record<string, any>>
    {
        return (await axios.get<Record<string, any>>(`admin/settings/${key}`)).data;
    }

    /**
     * @param key string
     *
     * @returns Promise<MonitoringFormModel>
     */
    public static async fetchMonitoring(): Promise<MonitoringFormModel>
    {
        return (await axios.get<MonitoringFormModel>(`admin/settings/monitoring`)).data;
    }

    /**
     * @param model Record<string, any>
     *
     * @returns Promise<Statement>
     */
    public static async save(key: string, model: Record<string, any>): Promise<Statement>
    {
        return (await axios.post<Statement>(`admin/settings/${key}`, model)).data;
    }

    /**
     * @param model Record<MonitoringFormModel>
     *
     * @returns Promise<Statement>
     */
    public static async saveMonitoring(model: MonitoringFormModel): Promise<Statement>
    {
        return (await axios.post<Statement>(`admin/settings/monitoring`, model)).data;
    }

    /**
     * @returns Promise<Statement>
     */
    public static async restartApplication(): Promise<Statement>
    {
        return (await axios.post<Statement>(`admin/settings/restart-application`)).data;
    }
}

export interface MonitoringFormModel
{
    appInsights: AppInsightsOptions;
    prometheus: PrometheusMetricsOptions;
    sentry: SentryOptions;
}

export interface AppInsightsOptions
{
    enabled: boolean;
    connectionString: string;
    tracingEnabled: boolean;
    adaptiveSamplingEnabled: boolean;
    excludedDependencies: AppInsightsDependencyType[];
    httpDependencyTracking: AppInsightsHttpDependencyType;
    httpDependencyUrls: string;
}

export interface PrometheusMetricsOptions
{
    enabled: boolean;
    httpMetricsEnabled: boolean;
    netRuntimeMetricsEnabled: boolean;
}

export interface SentryOptions
{
    enabled: boolean;
    dsn: string;
    profilingEnabled: boolean;
    sendDefaultPii: boolean;
    attachStackTrace: boolean;
    tracingEnabled: boolean;
    tracesSampleRate: number;
    profilesSampleRate: number;
}

enum AppInsightsDependencyType {
    Sql = 1,
    Redis = 2,
    ElasticSearch = 3,
    AzureBlob = 4
}

const AppInsightsDependencyTypeOptions = (): Option<AppInsightsDependencyType>[] => ([
    { value: AppInsightsDependencyType.Sql, text: 'SQL' },
    { value: AppInsightsDependencyType.Redis, text: 'Redis' },
    { value: AppInsightsDependencyType.ElasticSearch, text: 'ElasticSearch' },
    { value: AppInsightsDependencyType.AzureBlob, text: 'Azure blob' }

]);

enum AppInsightsHttpDependencyType {
    All = 0,
    Off = 1,
    IncludeSelected = 2,
    ExcludeSelected = 3
}

const AppInsightsHttpDependencyTypeOptions = (): Option<AppInsightsHttpDependencyType>[] => ([
    { value: AppInsightsHttpDependencyType.All, text: $t('[[[Wszystkie]]]') },
    { value: AppInsightsHttpDependencyType.Off, text: $t('[[[Wyłaczone]]]') },
    { value: AppInsightsHttpDependencyType.IncludeSelected, text: $t('[[[Loguj wybrane]]]') },
    { value: AppInsightsHttpDependencyType.ExcludeSelected, text: $t('[[[Nie loguj wybranych]]]') },
]);

export {
    AppInsightsDependencyType,
    AppInsightsDependencyTypeOptions,
    AppInsightsHttpDependencyType,
    AppInsightsHttpDependencyTypeOptions
};
