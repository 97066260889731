// Entries
import { Blueprint } from '../base/blueprints/Blueprint';
import { AggregateBlueprint } from '../base/blueprints/AggregateBlueprint';
import { BlueprintDefinition } from './blueprints/BlueprintDefinition';

// Entries
import { Entry } from './entries/Entry';
import { instanceOfValidEntry } from './entries/ValidEntry';

// Managers
import { DocumentManager } from './managers/DocumentManager';
import { ExpressionManager } from './managers/ExpressionManager';
import { DomManager } from './managers/DomManager';
import { FormLayoutManager } from './managers/FormLayoutManager';
import { FormClipboardManager } from './managers/FormClipboardManager';
import { LocalizationManager } from './managers/LocalizationManager';

// Services
import { FormBlueprintFactory } from './services/FormBlueprintFactory';

// Types
import { ProcessCallback } from './types/ProcessCallback';

// Base
import { ContentBuilder, ContentBuilderContract } from '../base';

// --------------------------------------------------

export interface FormBuilderContract extends ContentBuilderContract
{
    readonly document: DocumentManager;
    readonly expressions: ExpressionManager;
    readonly dom: DomManager;
    readonly layout: FormLayoutManager;
    readonly clipboard: FormClipboardManager;
    readonly localization: LocalizationManager;

    readonly entryId: number;
    readonly entry: Entry;
    getEntry(): Entry;

    validateEntry(): boolean;
    setEntryErrors(errors: Record<string, string[]>): void;
    collectEntry(preprocess?: ProcessCallback): Promise<Entry>;
}

export class FormBuilder extends ContentBuilder implements FormBuilderContract
{
    private entryFactory: () => Entry;

    private documentManager: DocumentManager;
    private expressionManager: ExpressionManager;
    private domManager: DomManager;
    private localizationManager: LocalizationManager;

    public constructor(blueprintFactory: FormBlueprintFactory)
    {
        super(blueprintFactory);

        this.layoutManager = new FormLayoutManager(
            blueprintFactory,
            this.eventManager,
            this.schemaManager,
            this.dimensionsManager
        );
        this.clipboardManager = new FormClipboardManager(
            this.eventManager,
            this.schemaManager,
            this.layoutManager as FormLayoutManager,
            this.dimensionsManager
        );
        this.localizationManager = new LocalizationManager();

        const documentManager = new DocumentManager(
            this.schema
        );
        const expressionManager = new ExpressionManager(
            this.state,
            this.schema,
            documentManager
        );
        const domManager = new DomManager(
            this.schema
        );

        this.documentManager = documentManager;
        this.expressionManager = expressionManager;
        this.domManager = domManager;
    }

    // --------------------------------------------------

    public get document(): DocumentManager
    {
        return this.documentManager;
    }

    public get expressions(): ExpressionManager
    {
        return this.expressionManager;
    }

    public get dom(): DomManager
    {
        return this.domManager;
    }

    public get layout(): FormLayoutManager
    {
        return this.layoutManager as FormLayoutManager;
    }

    public get clipboard(): FormClipboardManager
    {
        return this.clipboardManager as FormClipboardManager;
    }

    public get localization(): LocalizationManager
    {
        return this.localizationManager;
    }

    // --------------------------------------------------

    public get entry(): Entry
    {
        return this.document.getRootEntry();
    }

    public set entry(entry: Entry)
    {
        this.document.setRootEntry(entry);
    }

    public getEntry(): Entry
    {
        return this.entry;
    }

    public setEntry(entry: () => Entry): void
    {
        this.entryFactory = entry;
    }

    public get entryId(): number
    {
        return this.document.id;
    }

    public setEntryIdentity(callback: () => number): void
    {
        this.document.setIdentity(callback);
    }

    public validateEntry(): boolean
    {
        if (instanceOfValidEntry(this.entry))
        {
            return this.entry.validate(this.blueprint, this);
        }

        return true;
    }

    public setEntryErrors(errors: Record<string, string[]>): void
    {
        this.document.setErrors(errors);
    }

    public async collectEntry(preprocess: ProcessCallback = async () => {}): Promise<Entry>
    {
        if (instanceOfValidEntry(this.entry))
        {
            return await this.entry.collect(this.blueprint, this, preprocess);
        }

        return this.entry;
    }

    // --------------------------------------------------

    public update(): void
    {
        super.update();

        this.entry = this.entryFactory();
    }
}
