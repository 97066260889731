// Blueprints
import { RootBlueprint } from "../../base/blueprints/RootBlueprint";
import { FormBlueprint } from "../blueprints/FormBlueprint";
import { PageBlueprint } from "../blueprints/PageBlueprint";
import { RowBlueprint } from "../blueprints/RowBlueprint";

// Services
import { BlueprintFactory } from "../../base/services/BlueprintFactory";

export class FormBlueprintFactory extends BlueprintFactory
{
    public createRoot(id: string, name: string): RootBlueprint
    {
        return this.createForm(id, name);
    }

    public createForm(id: string, name: string): FormBlueprint
    {
        return this.create('form', id, name) as FormBlueprint;
    }

    public createPage(id: string, name: string): PageBlueprint
    {
        return this.create('page', id, name) as PageBlueprint;
    }

    public createRow(id: string, name: string): RowBlueprint
    {
        return this.create('row', id, name) as RowBlueprint;
    }
}
