import { axios } from '@/plugins/axios';
import Pager from '@/helpers/Pager';
import { Pagination } from '@/helpers/Interfaces';
import { DateTime } from 'luxon';

/**
 * SearchService
 */
export default class SearchService
{
    /**
     * @param model Record<string, any>
     *
     * @returns Promise<Pagination<ListItemModel>>
     */
    public static async getSearchResults(query: string, types: string[], values: [string, string][], onlyMine: boolean, allVersions: boolean, pager: Pager): Promise<Pagination<SearchResult>>
    {
        return (await axios.post<Pagination<SearchResult>>('admin/search', {
            searchTerm: query,
            types,
            values,
            onlyMine,
            allVersions,
            pager: pager.data()
        })).data;
    }
}

export interface SearchResult
{
    id: number;
    type: string;
    typeName: string;
    dateCreatedUtc: DateTime
    dateModifiedUtc?: DateTime
    createdBy: number;
    modifiedBy?: number;
    title: string;
    description: string;
    images: ImageResult[]
    attachments: AttachmentResult[]
    attributes: Record<string, string[]>
}

export interface ImageResult
{
    id: number;
    url: string;
    name: string;
}

export interface AttachmentResult
{
    id: number;
    name: string;
}

export interface SearchResultType
{
    count: number;
    key: string;
    name: string;
}

export interface SearchResultGroupValue
{
    count: number;
    key: string;
    name: string;
}

export interface SearchResultGroup extends SearchResultGroupValue
{
    values: SearchResultGroupValue[]
}
