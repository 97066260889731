// Base
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { AggregateBlueprint, instanceOfAggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { ValidatableBlueprint, validateBlueprints } from '@/components/builder/base/blueprints/ValidatableBlueprint';

// Blueprints
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { RowBlueprint } from '@/components/builder/form/blueprints/RowBlueprint';

// --------------------------------------------------

export const Definition: BlueprintDefinition = {
    type: 'row',
    name: '[[[Wiersz]]]',
    group: 'none'
};

export interface RowContract extends RowBlueprint, AggregateBlueprint
{
}

export class RowType implements RowContract, ValidatableBlueprint
{
    public kind: 'row' = 'row' as const;
    public id: string;
    public type: string;
    public name: string;
    public components: Blueprint[] = [];
    public errors: ValidationErrors;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.components = [];
        this.errors = {};
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return validateBlueprints(this.components);
    }
}

export const instanceOfRowType = (object: any): object is RowType =>
{
    return instanceOfAggregateBlueprint(object) && 'type' in object && object.type === Definition.type;
};
