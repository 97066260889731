<script lang="ts" setup>
import { ref, computed, watch, useSlots } from 'vue';
import Pager from '@/helpers/Pager';
import { isObject } from 'lodash';
import ListViewBase from '@/components/list/ListViewBase.vue';
import { Header, getProxy } from './helpers';

export interface Props
{
    layout?: 'auto'|'table'|'flex'|'card';
    showHeader?: boolean;
    items: any[];
    columns?: {
        visible: Record<string, boolean>,
        positions: Record<string, number>
    };
    pager?: Pager;
    emptyLabel?: string;
    preload?: boolean;
    draggable?: boolean;
    rowClick?: (item: any, i: number, e: PointerEvent) => void;
    rowClass?: (item: any) => Record<string, boolean> | string[] | string;
    customisable?: boolean;
}

defineOptions({
    name: 'list-view',
    components: {
        'list-view-base': ListViewBase
    }
});

const props = defineProps({
  "layout": { default: 'auto' },
  "showHeader": { type: Boolean, default: true },
  "items": null,
  "columns": { default: () => ({visible: {}, positions: {}}) },
  "pager": { default: () => new Pager(1, 20) },
  "emptyLabel": { default: '[[[Brak wyników]]]' },
  "preload": { type: Boolean, default: true },
  "draggable": { type: Boolean, default: false },
  "rowClick": { type: Function, default: (item: any, i: number, e: PointerEvent) => {} },
  "rowClass": { type: Function, default: (item: any) => ({}) },
  "customisable": { type: Boolean, default: true }
});

const emit = defineEmits(["check", "drag", "change"]);

const onChange = (): void =>
{
    emit('change');
};

const onDrag = (value: any): void =>
{
    emit('drag', value);
};

const checkItems = (value: boolean): void =>
{
    emit('check', value);
};

const $slots = useSlots();
const paging = ref(false);
const loaded = ref(!props.preload);

const headers = computed<Header[]>(() =>
{
    if ('row' in $slots)
    {
        const data = getProxy();
        const cols = $slots.row({ item: data }).filter((p: any) => isObject(p.type) && p.type.name.startsWith('list-view-'));

        return cols.map((p: any) => ({...(p.props || {}), type: p.type.name.replace('list-view-', '')}));
    }

    return [];
});

const rows = computed(() =>
{
    const preloading = props.layout !== 'card' && props.preload && loaded.value === false;

    if (preloading || props.pager.loading)
    {
        const data = getProxy();

        return Array.from(Array(5), () => data);
    }

    return props.items || [];
});

watch(() => [props.pager.pageIndex, props.pager.pageSize, props.pager.sorting, props.pager.order], () =>
{
    paging.value = true;
});

// watch(() => props.items, () =>
// {
//     if (paging.value == true)
//     {
//         paging.value = false;
//         loaded.value = true;
//     }

//     if (loaded.value == true && props.items.length == 0)
//     {
//         loaded.value = false;
//     }
//     else if (loaded.value == false)
//     {
//         loaded.value = true;
//     }
// });

// watch(() => loading.value, (value) =>
// {
//     if (value == false && !props.loading)
//     {
//         loaded.value = true;
//     }
// });

watch(() => props.pager.loading, (value) =>
{
    if (typeof value !== 'boolean')
        return;

    loaded.value = !value;
});
</script>

<template>
    <list-view-base
        :loaded="loaded" :layout="props.layout"
        :headers="headers" :show-header="props.showHeader" :columns="props.columns"
        :items="rows" :pager="props.pager" :empty-label="props.emptyLabel"
        :row-click="props.rowClick" :row-class="props.rowClass" :draggable="draggable"
        :customisable="props.customisable"
        @check="checkItems"
        @change="onChange"
        @drag="onDrag"
    >
        <template #row="{item, index}">
            <slot name="row" :item="item" :index="index"></slot>
        </template>
    </list-view-base>
</template>
