import { AggregateBlueprint, instanceOfAggregateBlueprint } from "./AggregateBlueprint";

export interface RootBlueprint extends AggregateBlueprint
{
    root: true;
}

export const instanceOfRootBlueprint = (object: any): object is RootBlueprint =>
{
    return instanceOfAggregateBlueprint(object) && 'root' in object && object.root === true;
};

export abstract class RootAbstract
{
    public root: true = true as const;
}
