<script lang="ts" setup>
import { computed, ref } from 'vue';
import { Option } from '@/helpers/Interfaces';
import IdeoComboBoxSelect from './IdeoComboBoxSelect.vue';
import IdeoComboBoxMultiple from './IdeoComboBoxMultiple.vue';
import IdeoComboBoxDropdown from './IdeoComboBoxDropdown.vue';
import IdeoComboBoxInline from './IdeoComboBoxInline.vue';

type BaseType = string|number;
type ArrayType = BaseType[];
type ModelType = BaseType|ArrayType;
type FetchSingle = (id: BaseType) => Promise<Option<BaseType>>;
type FetchMultiple = (ids: ArrayType) => Promise<Option<BaseType>[]>;
type Fetch = FetchSingle|FetchMultiple;
type Search = (query: string, limit: number) => Promise<Option<BaseType>[]>;

const model = defineModel<ModelType>();
const props = defineProps({
  "name": null,
  "placeholder": { default: '[[[Wyszukaj...]]]' },
  "options": { default: (): [] => ([]) },
  "valueField": { default: 'value' },
  "textField": { default: 'text' },
  "size": { default: 10 },
  "default": { type: Boolean, default: true },
  "defaultLabel": { default: '[[[Brak wyboru]]]' },
  "multiple": { type: Boolean, default: false },
  "fetch": { type: Function, default: undefined },
  "search": { type: Function, default: undefined },
  "inline": { type: Boolean, default: false }
});
const emit = defineEmits(["change"]);

const proxy = computed<any>({
    get() { return model.value; },
    set(value) { model.value = value; }
});
const fetch: any = props.fetch;
const search: any = props.search;
const display = ref<string>(null);
const component = () => props.multiple ? IdeoComboBoxMultiple : IdeoComboBoxSelect;
const container = () => props.inline ? IdeoComboBoxInline  : IdeoComboBoxDropdown;

function onChange(value: Option<BaseType>|Option<BaseType>[])
{
    emit('change', value);
}

function onDisplay(value: string)
{
    display.value = value;
}
</script>

<template>
    <component :is="container()" :display="display">
        <component
            :is="component()"
            v-model="proxy"
            :name="props.name"
            :placeholder="$t('[[[Wyszukaj...]]]')"
            :options="props.options"
            :value-field="props.valueField"
            :text-field="props.textField"
            :size="props.size"
            :default="props.default"
            :default-label="props.defaultLabel"
            :fetch="fetch"
            :search="search"
            @change="onChange"
            @display="onDisplay"
        />
    </component>
</template>
