import { Blueprint } from "../../base/blueprints/Blueprint";

export interface Relational extends Blueprint
{
    moduleId?: number;
    sectionId?: string;
    categoryId?: number;
    formId?: number;
    componentId?: string;
    dependsOn: string[];
}

export const instanceOfRelational = (object: any): object is Relational =>
{
    return object && ('moduleId' in object && 'sectionId' in object) || ('categoryId' in object && 'formId' in object && 'componentId' in object) && 'dependsOn' in object;
};
