<script lang="ts" setup>
import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineEmits(["update:tab"]);
import { computed } from 'vue';
import { normalizeClasses } from '@/helpers/Utils';
import { Option } from '@/helpers/Interfaces';

const props = defineProps({
  "tab": null,
  "disabled": { type: Boolean, default: false },
  "card": { type: Boolean, default: false },
  "vertical": { type: Boolean, default: false },
  "pills": { type: Boolean, default: false },
  "underline": { type: Boolean, default: false },
  "fill": { type: Boolean, default: false },
  "justified": { type: Boolean, default: false },
  "align": { default: 'start' },
  "navClass": { default: () => [] as string[] },
  "activeNavItemClass": { default: () => [] as string[] },
  "tabs": { default: () => [] as Option[] }
});

const { tab } = __MACROS_useVModel("tab");

const flag = (value: any): boolean =>
{
    return value !== false;
};

const navClasses = computed(() =>
{
    const vertical = flag(props.vertical);
    const card = flag(props.card) && !vertical;
    const underline = flag(props.underline);
    const pills = flag(props.pills) && !underline;
    const tabs = !pills && !underline;
    const fill = flag(props.fill);
    const justified = flag(props.justified);

    return {
        'nav': true,
        'nav-tabs': tabs,
        'nav-pills': pills,
        'nav-underline': underline,
        'nav-fill': fill,
        'nav-justified': justified,
        'card-header-tabs': card && tabs,
        'card-header-pills': card && pills,
        'card-header-underline': card && underline,
        'flex-column': vertical,
        'justify-content-center': props.align == 'center',
        'justify-content-end': props.align == 'end',
        ...normalizeClasses(props.navClass)
    };
});

const navLinkClasses = (tabName: string): Record<string, boolean> =>
{
    const active = tabName == tab.value;

    return {
        'nav-link': true,
        'active': active,
        'disabled': flag(props.disabled),
        ...(active ? normalizeClasses(props.activeNavItemClass) : {})
    };
};

const onChange = (item: Option): void =>
{
    tab.value = item.value;
};
</script>

<template>
    <ul :class="navClasses">
        <li class="nav-item" v-for="item in props.tabs" :key="item.value">
            <a :class="navLinkClasses(item.value)" href="#" @click.stop.prevent="onChange(item)">
                <i :class="item.icon" v-if="item.icon" class="me-2"></i>
                <span>{{ $t(item.text) }}</span>
            </a>
        </li>
        <li class="nav-item" v-if="$slots.end">
            <slot name="end"></slot>
        </li>
    </ul>
</template>
