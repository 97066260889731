import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { Entry, entry, instanceOfEntry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasAffix } from '@/components/builder/form/traits/HasAffix';
import { HasFilter } from '@/components/builder/form/traits/HasFilter';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { HasPlaceholder } from '@/components/builder/form/traits/HasPlaceholder';
import { AffixValue } from '@/components/builder/form/types/AffixValue';
import { MinMaxValue } from '@/components/builder/form/types/MinMaxValue';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';

export const Definition: BlueprintDefinition = {
    type: 'localized-text',
    name: '[[[Tekst lokalizowany]]]',
    icon: 'fa-font',
    group: 'localized',
    position: 1
};

export enum LocalizedTextFieldTypes {
    Text = 'Text',
    Multiline = 'Multiline',
}

export enum ValidationTypes {
    None = 'None',
    Alphabetic = 'Alphabetic',
    Numeric = 'Numeric',
    Alphanumeric = 'Alphanumeric',
    RegularExpression = 'RegularExpression'
}

// --------------------------------------------------

export interface HasValidation extends Blueprint
{
    validation: ValidationTypes;
    validationRule: string;
    validationMessage:  Record<string, string>;
}

export const instanceOfHasValidation = (object: any): object is HasValidation =>
{
    return object && 'validation' in object && 'validationRule' in object && 'validationMessage' in object;
};

export const validator = (blueprint: HasValidation, form: FormBuilderContract, displayLang: string): (value: string) => string =>
{
    const validate = (regexp: RegExp, value: string, message: string): string =>
    {
        return regexp.test(value) ? null : message;
    };
    let rule = (value: string): string => validate(new RegExp("^.*$", "g"), value, null);

    if (instanceOfHasValidation(blueprint))
    {
        switch (blueprint.validation)
        {
            case ValidationTypes.Alphabetic:
                rule = (value: string) => validate(new RegExp("^[a-z]*$", "gi"), value, `[[[Pole może zawierać jedynie litery. (%0)|||${displayLang}]]]`);
                break;
            case ValidationTypes.Numeric:
                rule = (value: string) => validate(new RegExp("^[0-9]*$", "g"), value, `[[[Pole może zawierać jedynie cyfry. (%0)|||${displayLang}]]]`);
                break;
            case ValidationTypes.Alphanumeric:
                rule = (value: string) => validate(new RegExp("^[a-z0-9]*$", "gi"), value, `[[[Pole może zawierać jedynie litery i cyfry. (%0)|||${displayLang}]]]`);
                break;
            case ValidationTypes.RegularExpression:
                rule = (value: string) => validate(new RegExp(blueprint.validationRule, "gi"), value, form.localization.translate(blueprint.validationMessage) || `[[[Podano nieprawidłowe dane. (%0)|||${displayLang}]]]`);
                break;
        }
    }

    return rule;
};

// --------------------------------------------------

export class LocalizedTextEntry extends ValidEntry<Record<string, string>> implements Entry
{
    public type: string = Definition.type;
    public data: Record<string, string> = null;
    public isHeader: boolean = false;
    public isSummary: boolean = false;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }
    }

    public async collect(blueprint: LocalizedTextContract, form: FormBuilderContract, preprocess: ProcessCallback): Promise<Entry>
    {
        const result = await preprocess(blueprint, this, form.blueprintId, form.entryId);
        const defaultValue = form.expressions.executeExpression(blueprint.defaultValue) || {};
        const value: Record<string, string> = this.data ?? {};

        for (const lang of form.localization.codes())
        {
            value[lang] = value[lang] ?? defaultValue[lang] ?? '';
        }

        return entry({
            type: this.type,
            value: value,
            isHeader: this.isHeader,
            isSummary: this.isSummary,
            ...(result ?? {})
        });
    }

    public validate(blueprint: LocalizedTextContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        let message = null;

        const defaultValue = form.expressions.executeExpression(blueprint.defaultValue) || {};
        const value: Record<string, string> = this.data ?? {};

        if (!form.expressions.readonly(blueprint, true) && form.expressions.visible(blueprint, true))
        {
            for (const lang of form.localization.codes())
            {
                const displayLang = lang.substring(0, 2).toLocaleUpperCase();
                const required = form.expressions.required(blueprint) && blueprint.requiredLocales.includes(lang);
                const langValue = value[lang] ?? defaultValue[lang] ?? '';
                const empty = langValue.length == 0;

                if (required && empty)
                {
                    this.errors.value = [`[[[Pole "%0" jest wymagane. (%1)|||${form.localization.translate(blueprint.label)}|||${displayLang}]]]`];
                }
                else if (!required && empty)
                {
                    continue;
                }
                else if (langValue.length < blueprint.characters.min)
                {
                    this.errors.value = [`[[[Nie podano wymaganej ilości znaków: %0. (%1)|||${blueprint.characters.min}|||${displayLang}]]]`];
                }
                else if (blueprint.characters.max != null && langValue.length > blueprint.characters.max)
                {
                    this.errors.value = [`[[[Przekroczono dozwoloną ilość znaków: %0. (%1)|||${blueprint.characters.max}|||${displayLang}]]]`];
                }
                else if (!(!required && empty) && (message = validator(blueprint, form, displayLang)(langValue)) != null)
                {
                    this.errors.value = [message];
                }
                else if (form.expressions.customError(blueprint))
                {
                    this.errors.custom = [form.expressions.customErrorMessage(blueprint, form)];
                }
            }
        }

        return this.valid();
    }
}

export const instanceOfLocalizedTextEntry = (object: any): object is LocalizedTextEntry =>
{
    return instanceOfEntry(object) && 'type' in object && object.type === Definition.type;
};

export interface LocalizedTextContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasPlaceholder, HasAffix, HasHelp, HasWidth, HasFilter, HasValidation
{
    fieldType: LocalizedTextFieldTypes;
    defaultValue: string;
    characters: MinMaxValue;
    rows: number;
    isHeader: boolean;
    isSummary: boolean;
    requiredLocales: string[];
}

export class LocalizedTextType implements LocalizedTextContract, ValidatableBlueprint, EntryFactory<LocalizedTextEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public placeholder: Record<string, string>;
    public fieldType: LocalizedTextFieldTypes;
    public defaultValue: string;
    public characters: MinMaxValue;
    public rows: number;
    public affix: AffixValue;
    public help: Record<string, string>;
    public minWidth: number;
    public width: number;
    public validation: ValidationTypes;
    public validationRule: string;
    public validationMessage: Record<string, string>;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: Record<string, string>;
    public errors: ValidationErrors;
    public showFilter: boolean;
    public isHeader: boolean;
    public isSummary: boolean;
    public requiredLocales: string[];

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = { 'pl-PL': 'Tekst' };
        this.showLabel = true;
        this.placeholder = {};
        this.defaultValue = null;
        this.characters = { min: 0, max: 50 };
        this.rows = 2;
        this.help = {};
        this.affix = { prepend: '', append: '' };
        this.fieldType = LocalizedTextFieldTypes.Text;
        this.validation = ValidationTypes.None;
        this.validationRule = '';
        this.validationMessage = {};
        this.minWidth = 1;
        this.width = 0;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = {};
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
        this.showFilter = false;
        this.isHeader = false;
        this.isSummary = false;
        this.requiredLocales = [];
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public createEntry(data: any): LocalizedTextEntry
    {
        return new LocalizedTextEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        const isNotPCRE = /^\/.+\/[gimuy]*$|^[^/]+\/[gimuy]*$|^\/[^/]*$/;

        if (isNotPCRE.test(this.validationRule))
        {
            this.errors.validationRule = ['[[[Wyrażenie w postaci "/pattern/flag" nie jest obsługiwane, użyj same wartości pattern]]]'];
        }
        else
        {
            try { new RegExp(this.validationRule); }
            catch (e) { this.errors.validationRule = ['[[[Niepoprawne wyrażenie regularne]]]']; }
        }

        return {
            [this.name]: this.errors
        };
    }
}
