<template>
    <component :is="link ? 'router-link' : 'button'" :to="link" type="button" :class="customClasses" :disabled="disabled || loading">
        <i :class="[icon, {'me-2': $slots.default}]" v-if="icon"></i>
        <slot name="default"></slot>

        <div v-show="loading" class="overflow">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </div>
    </component>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';

@Options({
    name: 'ideo-button'
})
export default class IdeoButton extends Vue
{
    @Prop({
        default: 'secondary',
        validator: (value: string) => [
            'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'link', 'none',
            'outline-primary', 'outline-secondary', 'outline-success', 'outline-danger', 'outline-warning', 'outline-info', 'outline-light', 'outline-dark'
        ].includes(value)
    })
    public variant: string;

    @Prop({
        default: 'md',
        validator: (value: string) => ['sm', 'md', 'lg'].includes(value)
    })
    public size: string;

    @Prop({ default: false })
    public block: boolean;

    @Prop({ default: false })
    public pill: boolean;

    @Prop({ default: false })
    public squared: boolean;

    @Prop({ default: false })
    public disabled: boolean;

    @Prop()
    public icon: string;

    @Prop()
    public link: string;

    @Prop()
    public loading: boolean;

    @Prop()
    public noBorder: boolean;

    public get customClasses(): Record<string, boolean>
    {
        return {
            'btn': true,
            [`btn-${this.variant}`]: this.variant != 'none',
            'w-100': this.block !== false,
            'rounded-pill': this.pill !== false,
            'rounded-0': this.squared !== false,
            'btn-sm': this.size == 'sm',
            'btn-lg': this.size == 'lg',
            'btn-no-border': this.noBorder
        };
    }
}
</script>

<style lang="scss">
.btn {
    position: relative;
    cursor: pointer;

    &-no-border {
        border: none !important;
    }

    &-subtle {
        &.btn-success, &.btn-outline-success {
            background: var(--bs-success-bg-subtle);
            color: var(--bs-success-text-emphasis);
        }

        &.btn-secondary, &.btn-outline-secondary {
            background: var(--bs-secondary-bg-subtle);
            color: var(--bs-secondary-text-emphasis);
        }

        &.btn-primary, &.btn-outline-primary {
            background: var(--bs-primary-bg-subtle);
            color: var(--bs-primary-text-emphasis);
        }

        &.btn-info, &.btn-outline-info {
            background: var(--bs-info-bg-subtle);
            color: var(--bs-info-text-emphasis);
        }

        &.btn-warning, &.btn-outline-warning {
            background: var(--bs-warning-bg-subtle);
            color: var(--bs-warning-text-emphasis);
        }

        &.btn-danger, &.btn-outline-danger {
            background: var(--bs-danger-bg-subtle);
            color: var(--bs-danger-text-emphasis);
        }
    }

    .overflow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: inherit;
    }
}
</style>
