import { initializeApp, FirebaseApp } from 'firebase/app';
import { Messaging, getMessaging, onMessage, getToken } from 'firebase/messaging';
import { getCurrentInstance, Plugin } from 'vue';
import NotificationService from '@/modules/core/common/services/NotificationsService';

export interface Firebase
{
    isActive: boolean,
    app: FirebaseApp,
    messaging: Messaging,
    init(): Promise<string>
}

class FirebaseHelper implements Firebase
{
    public isActive: boolean;
    public app: FirebaseApp;
    public messaging: Messaging;
    private instance: any;
    private keyPair: string;
    private config: any;

    public constructor(instance: any)
    {
        this.instance = instance;
    }

    public async init(): Promise<string>
    {
        const { isActive, firebaseConfig, keyPair } = await this.getConfig();

        this.isActive = isActive;

        if (isActive)
        {
            const lines = firebaseConfig.split('\n').map((line: string, index: number, rows: string[]) => {
                // Usunięcie podwójnych spacji i cudzysłowów
                line = line.replace(/  /g, '').replace(/"/g, '');

                const colonIndex = line.indexOf(':');

                if (colonIndex !== -1)
                {
                    const key = line.substring(0, colonIndex);
                    const value = line.substring(colonIndex + 2, line.length - 1);

                    line = `"${key}":"${value}"${index + 1 !== rows.length - 1 ? ',' : ''}`;
                }

                return line;
            });

            this.config = JSON.parse(lines.join('\n'));
            this.keyPair = keyPair;

            this.app = initializeApp(this.config);
            this.messaging = getMessaging(this.app);

            onMessage(this.messaging, (payload: any) =>
            {
                this.instance.config.globalProperties.$alert.info(payload.notification.body);
            });

            return await this.setToken();
        }
    }

    private async getConfig(): Promise<any>
    {
        try
        {
            return await NotificationService.getFirebaseConfig();
        }
        catch (ex)
        {
            this.instance.config.globalProperties.$log.error(ex);
        }
    }

    private async setToken(): Promise<string>
    {
        try
        {
            const permission = await Notification.requestPermission();

            if (permission === 'granted')
            {
                const params = new URLSearchParams(this.config);

                const reg = await navigator.serviceWorker.register(`/firebase-messaging-sw.js?${params.toString()}`);

                const token = await getToken(this.messaging, { vapidKey: this.keyPair, serviceWorkerRegistration: reg });

                return token;
            }
            else
            {
                this.instance.config.globalProperties.$log.warn('You have disabled notifications in your browser');
            }
        }
        catch (ex)
        {
            this.instance.config.globalProperties.$log.error(ex);
        }
    }
}

export const useFirebase = (): {$firebase: Firebase} =>
{
    const app = getCurrentInstance();

    return {
        $firebase: app.appContext.config.globalProperties.$firebase
    };
};

const FirebasePlugin: Plugin =
{
    install(app, options)
    {
        app.config.globalProperties.$firebase = new FirebaseHelper(app);
    }
};

export default FirebasePlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $firebase: Firebase
    }
}
