import { Blueprint, instanceOfBlueprint } from '../../base/blueprints/Blueprint';
import { AlwaysChoice } from '../enums/AlwaysChoice';
import { NeverChoice } from '../enums/NeverChoice';
import { WhenChoice } from '../enums/WhenChoice';

export interface RequiredBlueprint extends Blueprint
{
    required: AlwaysChoice | NeverChoice | WhenChoice;
    requiredWhen: string;
}

export const instanceOfRequiredBlueprint = (object: any): object is RequiredBlueprint =>
{
    return instanceOfBlueprint(object) && 'required' in object;
};
