<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  "tag": { default: 'div' },
  "show": { type: Boolean, default: true },
  "fill": { type: Boolean, default: true },
  "column": { type: Boolean, default: true },
  "stretch": { type: Boolean, default: true },
  "start": { type: Boolean, default: true },
  "end": { type: Boolean, default: true }
});

const css = computed(() => ({
    'd-flex': props.show,
    'd-none': !props.show,
    'flex-fill': props.fill,
    'flex-column': props.show && props.column,
    'align-items-start': !props.stretch
}));
</script>

<template>
    <component :is="tag" :class="css">
        <template v-if="$slots.start || $slots.end">
            <ideo-panel :column="column" :stretch="stretch" :fill="false" :class="{'me-3': start && !column, 'mb-2': start && column}" v-if="$slots.start">
                <slot name="start"></slot>
            </ideo-panel>
            <ideo-panel :column="column" :stretch="stretch">
                <slot name="default"></slot>
            </ideo-panel>
            <ideo-panel :column="column" :stretch="stretch" :fill="false" :class="{'ms-3': end && !column, 'mt-2': end && column}" v-if="$slots.end">
                <slot name="end"></slot>
            </ideo-panel>
        </template>
        <template v-else>
            <slot name="default"></slot>
        </template>
    </component>
</template>
