import { defineStore } from 'pinia';
import { ref } from 'vue';
import { AuthModel } from '@/modules/core/auth/services/AuthService';
import { useLocalStorage } from '@vueuse/core';

export interface AuthStore
{
    loaded: boolean;
    authenticated: boolean;
    identity: AuthModel;
    rememberMe: boolean;
    token: string;
    impersonated: number;
    setLoaded(value: boolean): void;
    setAuthenticated(value: boolean): void;
    setIdentity(model: AuthModel): void;
    clearIdentity(): void;
    setRememberMe(enabled: boolean): void;
    setToken(value: string): void;
    impersonate(id: number): void;
    unimpersonate(): void
}

export const useAuthStore = defineStore('auth', () =>
{
    const loaded = ref<boolean>(false);
    const authenticated = ref<boolean>(false);
    const identity = ref<AuthModel>(null);
    const rememberMe = ref<boolean>(false);
    const token = useLocalStorage("token", ref<string>(null));
    const impersonated = ref<number>(null);

    function setLoaded(value: boolean): void
    {
        loaded.value = value;
    }

    function setAuthenticated(value: boolean): void
    {
        authenticated.value = value;
    }

    function setIdentity(model: AuthModel): void
    {
        identity.value = model;
    }

    function clearIdentity(): void
    {
        identity.value = null;
    }

    function setRememberMe(enabled: boolean): void
    {
        rememberMe.value = enabled;
    }

    function setToken(value: string): void
    {
        token.value = value;
    }

    function impersonate(id: number): void
    {
        impersonated.value = id;
    }

    function unimpersonate(): void
    {
        impersonated.value = null;
    }

    return {
        loaded, authenticated, identity, rememberMe, token, impersonated,
        setLoaded, setAuthenticated, setIdentity, clearIdentity,
        setRememberMe, setToken, impersonate, unimpersonate
    };
});
