<script lang="ts" setup>
import InputNumber from 'primevue/inputnumber';
import InputGroup from 'primevue/inputgroup';
import Button from 'primevue/button';

const model = defineModel<number>();

defineOptions({
    name: 'ideo-form-number',
    components: {
        'input-number': InputNumber,
        'input-group': InputGroup,
        'p-button': Button
    }
});

const resetValue = (): void =>
{
    model.value = 0;
};
</script>

<template>
    <input-group class="form-number">
        <input-number v-model="model" />
        <p-button @click="resetValue" value="test" severity="secondary">
            <i class="fas fa-fw fa-times"></i>
        </p-button>
    </input-group>
</template>

<style lang="scss">
.form-number {
    .p-inputtext {
        font-size: 0.8125rem;
    }

    .p-button {
        font-size: 0.8125rem;
    }
}
</style>
