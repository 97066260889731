import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { Entry, entry } from '@/components/builder/form/entries/Entry';
import { ValidEntry, instanceOfValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';

export const Definition: BlueprintDefinition = {
    type: 'table',
    name: '[[[Tabela]]]',
    icon: 'far fa-table',
    group: 'primary',
    position: 11,
};

export interface TableColumn {
    columnHeader: string;
    columnType: BlueprintDefinition;
    formula?: string;
    summary?: boolean;
}

export interface TableContract extends Blueprint, AggregateBlueprint, VisibleBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasHelp, HasWidth {
    numberOfRows: number;
    canAddRow: boolean;
    columns: TableColumn[];
}

export class TableEntry extends ValidEntry<Record<string, any>[]>
{
    public type: string = Definition.type;
    public data: Record<string, ValidEntry<any>>[] = [];

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }
    }

    public async collect(blueprint: TableContract, form: FormBuilderContract, preprocess: ProcessCallback): Promise<Entry>
    {
        await preprocess(blueprint, this, form.blueprintId, form.entryId);

        const result = [];

        for (const item of this.data)
        {
            const row: Record<string, any> = {};

            for (const entryName in item)
            {
                const name = entryName;

                if (instanceOfValidEntry(item[name]))
                {
                    const entry = item[name] as ValidEntry<any>;
                    const blueprint = form.schema.find(name);

                    row[name] = await entry.collect(blueprint, form, preprocess);
                }
            }

            result.push(row);
        }

        return entry({
            type: this.type,
            data: result
        });
    }

    public validate(blueprint: TableContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        const data = this.data;

        if (!form.expressions.readonly(blueprint, true) && form.expressions.visible(blueprint, true))
        {
            if (form.expressions.required(blueprint) && data.length === 0)
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${form.localization.translate(blueprint.label)}]]]`];
            }
            else if (form.expressions.customError(blueprint))
            {
                this.errors.custom = [form.expressions.customErrorMessage(blueprint, form)];
            }
        }

        return this.valid();
    }
}

export const instaceOfTableEntry = (object: any): object is TableEntry =>
{
    return object && 'type' in object && object.type === Definition.type;
};

export class TableType implements TableContract, ValidatableBlueprint, EntryFactory<TableEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public help: Record<string, string>;
    public width: number;
    public minWidth: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: Record<string, string>;
    public errors: ValidationErrors;
    public numberOfRows: number;
    public components: Blueprint[];
    public columns: TableColumn[];
    public canAddRow: boolean;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = { 'pl-PL': 'Tabela' };
        this.showLabel = true;
        this.help = {};
        this.width = 0;
        this.minWidth = 2;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.required = AlwaysChoice.Always;
        this.requiredWhen = null;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = {};
        this.components = [];
        this.numberOfRows = 1;
        this.canAddRow = false;
        this.columns = [];
        this.errors = {};
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public createEntry(data: any): TableEntry
    {
        return new TableEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors,
        };
    }
}
