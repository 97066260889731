export interface Blueprint
{
    id: string;
    type: string;
    name: string;
    disabled?: boolean;
}

export const instanceOfBlueprint = (object: any): object is Blueprint =>
{
    return object && 'id' in object && 'type' in object && 'name' in object;
};

export interface BlueprintConstructor
{
    new (id: string, name: string): Blueprint;
}
