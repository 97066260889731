<template>
    <component :is="tag">
        <slot name="default"></slot>
    </component>
</template>

<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'ideo-dropdown-text'
})
export default class IdeoDropdownText extends Vue
{
    @Prop({ default: 'p' })
    public tag: string;
}
</script>
