export default {
    "Project-Id-Version": "",
    "POT-Creation-Date": "2024-08-07T19:17:21.846Z",
    "MIME-Version": "1.0",
    "Content-Type": "text/plain; charset=utf-8",
    "Content-Transfer-Encoding": "8bit",
    "X-Generator": "Edito",
    "PO-Revision-Date": "2024-08-07T19:17:22.056Z",
    "Last-Translator": "",
    "Language-Team": "",
    "Language": "en-US",
    "Messages": {
        "1": "1",
        "2": "2",
        "3": "3",
        "4": "4",
        "%0 znaków": "%0 characters",
        "1 godzina": "1 hour",
        "10 minut": "10 minutes",
        "12 godzin": "12 hours",
        "15 minut": "15 minutes",
        "24 godziny": "24 hours",
        "3 godziny": "3 hours",
        "30 minut": "30 minutes",
        "5 minut": "5 minutes",
        "6 godzin": "6 hours",
        "Aby dodawać członków zespołu musisz najpierw zapisać formularz.": "To add team members, you have to save the form.",
        "Administracja": "Administration",
        "Administracja - Kraje": "Administration - Countries",
        "Administracja - Miasta": "Administration - Cities",
        "Administracja - Role": "Administration - Roles",
        "Administracja - Stanowiska pracy": "Administration - Positions",
        "Administracja - Użytkownicy": "Administration - Users",
        "Administracja - Województwa": "Administration - Voivodships",
        "Administracja - Zespoły": "Administration - Teams",
        "Administratorzy": "Administrators",
        "Adres": "Address",
        "Adres domenowy": "Domain address",
        "Adres e-mail potwierdzony": "The email address has been confirmed",
        "Adres email": "Email address",
        "Adres email nadawcy": "Sender's email address",
        "Adres IP": "IP address",
        "Adres serwera": "Server address",
        "Adres URL": "URL",
        "Akceptacja": "Acceptance",
        "Akceptuj": "Accept",
        "Akcja": "Action",
        "Akcje": "Actions",
        "Aktualizuj": "Update",
        "Aktualne hasło": "Current password",
        "Aktualne urządzenie": "Current device",
        "Aktywne": "Active",
        "Aktywne kanały komunikacji": "Active communication channels",
        "Angielski": "English",
        "Ankieta": "Survey",
        "Anuluj": "Cancel",
        "Autor": "Author",
        "Azure AD": "Azure AD",
        "Baza danych": "Database",
        "Bazy danych": "Database",
        "Bezpieczeństwo": "Safety",
        "Bezpieczne połączenie SSL": "Secure SSL connection",
        "Blokada konta": "Account blocked",
        "Błąd 404. Nie znaleziono żądanego zasobu.": "Error 404. The requested resource was not found.",
        "Błędne": "Incorrect",
        "Brak": "Lack",
        "Brak działu nadrzędnego": "No parent section",
        "Brak kluczy": "No keys",
        "Brak nowych powiadomień.": "No new notifications.",
        "Brak odpowiedzi": "No response",
        "Brak wersji": "No version",
        "Brak wyników": "No results",
        "Checkbox": "Checkbox",
        "co 3 miesiące": "every 3 months",
        "co 30 dni": "every 30 days",
        "co 6 miesięcy": "every 6 months",
        "Co godzinę": "Every hour",
        "Co miesiąc": "Every month",
        "co rok": "every year",
        "Co tydzień": "Every week",
        "Codziennie": "Every day",
        "Coś poszło nie tak": "Something went wrong",
        "Cron": "Cron",
        "Cyfry": "Digits",
        "Czas": "Time",
        "Czas pomiędzy": "Time between",
        "Czas trwania blokady": "Account blocking time",
        "Czerwiec": "June",
        "Członkowie zespołu": "Team members",
        "Cztery kolumny": "Four columns",
        "Dalej": "Next",
        "Dane formularza są przechowywane w obiekcie Entry.": "The form data is stored in the Entry object.",
        "Dane osobowe": "Personal data",
        "Data": "Date",
        "Data do": "Date to",
        "Data dodania": "Adding date",
        "Data i czas": "Date and time",
        "Data modyfikacji": "Modification date",
        "Data od": "Date from",
        "Data rejestracji": "Registration date",
        "Data rozpoczęcia": "Start date",
        "Data usunięcia": "Deletion date",
        "Data utworzenia": "Creation date",
        "Data utworzenia wersji": "Version creation date",
        "Data wylogowania": "Logout date",
        "Data zakończenia": "End date",
        "Data zalogowania": "Login date",
        "Data zdarzenia": "Event date",
        "Definicja formularza znajduje się w obiekcie Form.": "The form definition is in the Form object.",
        "do": "to",
        "Do akceptacji": "To be accepted",
        "do czasu ręcznego odblokowania": "until manually unlocked",
        "Dodaj": "Add",
        "Dodaj dni": "Add days",
        "Dodaj dział": "Add section",
        "Dodaj formularz": "Add form",
        "Dodaj kategorię": "Add category",
        "Dodaj klienta": "Add client",
        "Dodaj rolę": "Add a role",
        "Dodaj serwer": "Add server",
        "Dodaj stronę": "Add page",
        "Dodaj szablon": "Add template",
        "Dodaj użytkownika": "Add user",
        "Dodaj wiersz": "Add row",
        "Dodaj wpis": "Add entry",
        "Dodaj zastępstwo": "Add a replacement",
        "Dodaj zespół": "Add team",
        "Dodaj źródło": "Add source",
        "Dodanie": "Adding",
        "Dodanie działu": "Add section",
        "Dodanie formularza": "Add form",
        "Dodanie klienta": "Add client",
        "Dodanie roli": "Add role",
        "Dodanie serwera": "Add server",
        "Dodanie użytkownika": "Add user",
        "Dodanie zastępstwa": "Add replacement",
        "Dodanie zespołu": "Add team",
        "Dodanie źródła danych": "Add data source",
        "Dodanych plików %0 (limit wynosi %1).": "Added files %0 (limit is %1).",
        "Dodawanie": "Adding",
        "Dokument %0": "Document %0",
        "Domyślna": "Default",
        "Domyślna wartość": "Default value",
        "Domyślny": "Default",
        "Dopuść brak odpowiedzi": "Allow lack of response",
        "Dostęp niezdefiniowany": "Undefined access",
        "Dostęp przyznany": "Access granted",
        "Dostęp zabroniony": "Access denied",
        "Dostępna jest nowa aktualizacja. Proszę zapisać postęp pracy i klinąć przycisk \"Aktualizuj\". Możesz również zaakceptować aktualizację poprzez odświeżenie okna przeglądarki. Brak aktualizacji może spowodować błędy.": "A new update is available. Please save the work progress and click the \"Update\" button. You can also accept the update by refreshing the browser. Noo update may result in errors.",
        "Dowolny": "Any",
        "Dozwolone rozszerzenia": "Allowed extensions",
        "Drugie imię": "Middle name",
        "Dwie kolumny": "Two columns",
        "Dział aktywny": "Active section",
        "Dział nadrzędny": "Parent section",
        "Dział został dodany.": "The section has been added.",
        "Dział został zaktualizowany.": "The section has been updated.",
        "Dzielenie": "Division",
        "E-mail": "E-mail",
        "Edycja działu": "Edit section",
        "Edycja formularza": "Edit form",
        "Edycja klienta": "Edit client",
        "Edycja roli": "Edit role",
        "Edycja serwera": "Edit server",
        "Edycja użytkownika": "Edit user",
        "Edycja zastępstwa": "Edit substitution",
        "Edycja zespołu": "Edit team",
        "Edycja źródła danych": "Edit data source",
        "Edytowana wersja": "Edited version",
        "Edytowane mogą być tylko szablony włączonych i obsługiwanych kanałów.": "Only templates for enabled and supported channels can be edited.",
        "Edytuj": "Edit",
        "Edytuj szablon": "Edit template",
        "Edytuj uprawnienia": "Edit permissions",
        "Edytuj wpis": "Edit post",
        "Element został przywrócony.": "The element has been restored.",
        "Element został usunięty.": "The element has been deleted.",
        "Email": "E-mail",
        "Etykieta": "Tag",
        "Etykiety": "Tags",
        "Filtr prywatny": "Private filter",
        "Filtr został usunięty": "Filter has been removed",
        "Filtr został zapisany": "Filter has been saved",
        "Filtry": "Filters",
        "Formularz": "Modules / Contact form",
        "Formularz zarchiwizowany": "Archived form",
        "Formularz został dodany.": "The form has been added.",
        "Formularz został zaktualizowany.": "The form has been updated.",
        "Formularze": "Forms",
        "Grudzień": "December",
        "Grupa": "Group",
        "Harmonogram zadań": "Task scheduler",
        "Hasło": "Password",
        "Hasło (Klucz ukryty)": "Password (Hidden Key)",
        "Hasło musi zawierać co najmniej jeden znak specjalny np.: ~ @ # $ % ^ & *": "The password must contain at least one special character, e.g.: ~ @ $ % ^ & *",
        "Hasło użytkownika serwisowego": "Maintenace user password",
        "Hasło zostało zmienione. Zaloguj się ponownie": "The password has been changed. Sign in again.",
        "Historia logowania": "Login history",
        "Historia zadania": "Task history",
        "Host": "Host",
        "Host dodatkowy": "Secondary host",
        "Host główny": "Main host",
        "Id": "Id",
        "ID": "ID",
        "Id modelu: %0": "Model Id: %0",
        "Id sesji": "Session Id",
        "Id zdarzenia": "Event Id",
        "Ikona": "Icon",
        "Ilość wierszy": "Number of rows",
        "Ilość wyników do odrzucenia": "Number of results to be rejected",
        "Ilość wyników do pobrania": "Number of results to download",
        "Ilość znaków": "Number of characters",
        "Imię": "First name",
        "Imię i Nazwisko": "First name and last name",
        "Inspekcja": "Inspection",
        "Jedna kolumna": "One column",
        "Kalendarz - Kategorie zasobów": "Calendar - Resource Categories",
        "Kanał jest domyślnie wymagany. Nie da się wyłączyć wysyłania powiadomienia tym kanałem. Możliwa jest tylko zmiana tytułu i treści komunikatu.": "The channel is required by default. It is not possible to stop sending a notification through this channel. It is only possible to change the title and content of the message.",
        "kanał wymagany": "required channel",
        "Kanały komunikacji": "Communication channels",
        "Kanały obsługiwane przez wybrany typ powiadmienia": "Channels supported by the selected notification type",
        "Kanały włączone w ustawieniach aplikacji": "Channels enabled in app settings",
        "Katalog": "Directory",
        "Katalog został utworzony.": "The directory has been created.",
        "Kategoria": "Category",
        "Kategoria jest wymagana": "Category is required",
        "Kategoria została dodana.": "The category has been added.",
        "Kategoria została usunięta.": "The category has been removed.",
        "Kategoria została zaktualizowana.": "The category has been updated.",
        "Kategorie": "Categories",
        "Klienci": "Clients",
        "Klient aktywny": "Active client",
        "Klient został dodany.": "The client has been added.",
        "Klient został usunięty.": "The client has been deleted.",
        "Klient został zaktualizowany.": "The client has been updated.",
        "Klucze": "Keys",
        "Kod pocztowy": "Postal code",
        "Komunikat": "Message",
        "Komunikat błędu": "Error message",
        "Komunikat walidacji": "Validation message",
        "Konfiguracja": "Settings",
        "Konfiguracja domyślna": "Default configuration",
        "Kontakty - Typ działania": "Contacts - Activity Type",
        "Kontener plików": "File container",
        "Kontrolka wyboru (pojedynczy wybór)": "Choice control (single choice)",
        "Kontrolka wyboru (wybór wielokrotny)": "Choice control (multiple choice)",
        "Kopiuj": "Copy",
        "Kosz": "Bin",
        "Kraj": "Country",
        "Kroki": "Steps",
        "Krótki opis": "Short description",
        "Kwiecień": "April",
        "Lewy panel": "Left panel",
        "Liczba": "Number",
        "Liczba całkowita": "Integer",
        "Liczba dziesiętna": "Decimal",
        "Linia": "Line",
        "linie": "Lines",
        "Lipiec": "July",
        "Lista efektywnych uprawnień użytkownika wynikających z uprawnień indywidualnych oraz ról.": "List of effective user permissions resulting from individual permissions and roles.",
        "Lista jest pusta": "The list is empty",
        "Lista plików": "File list",
        "Lista powiadomień": "Notification list",
        "Lista rozwijana": "Drop-down list",
        "Listopad": "November",
        "Litery": "Letters",
        "Litery i cyfry": "Letters and numbers",
        "Login": "Login",
        "Logo": "Logo",
        "Logowanie": "Log in",
        "Logowanie zakończyło się niepowodzeniem.": "Login failed.",
        "Luty": "February",
        "Łączenie treści": "Combining content",
        "Maj": "May",
        "Maksimum": "Maximum",
        "Maksymalna ilość plików": "Maximum number of files",
        "Maksymalny rozmiar pliku w MB": "Maximum file size in MB",
        "Małe znaki": "Small characters",
        "Marzec": "March",
        "Metadane": "Metadata",
        "Miejsca po przecinku": "Decimal points",
        "Miejscowość": "Town",
        "Miesiąc": "Month",
        "Migracje": "Migrations",
        "Miniaturki": "Thumbnails",
        "Minimalna długość hasła": "Minimum password length",
        "Minimum": "Minimum",
        "Mnożenie": "Multiplication",
        "Moduł": "Module",
        "Moduły": "Modules",
        "Modyfikacja": "Modification",
        "Moje filtry": "My filters",
        "Moje konto": "My account",
        "Monitor kolejek": "Queue monitor",
        "Monitoring": "Monitoring",
        "Możliwość modyfikacji tego działu jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "The possibility of modifying this section is limited. Inactive fields are synchronized with the external database %0.",
        "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "The possibility to modify this account is limited. Inactive fields are synchronized with the external database %0.",
        "Multimedia": "Multimedia",
        "Na pewno usunąć wybrane elementy?": "Are you sure to delete the selected elements?",
        "Narzędzia": "Tools",
        "Następna strona": "Next page",
        "Nazwa": "Name",
        "Nazwa bazy danych": "Database name",
        "Nazwa działu": "Department name",
        "Nazwa katalogu": "Directory name",
        "Nazwa katalogu została zmieniona.": "The directory has been renamed.",
        "Nazwa klienta": "Client name",
        "Nazwa kolumny z opisem": "Description column name",
        "Nazwa kolumny z wartością": "Column name with value",
        "Nazwa konta (Klucz dostępu)": "Account Name (Access Key)",
        "Nazwa nadawcy": "Sender name",
        "Nazwa pliku": "File name",
        "Nazwa pliku została zmieniona.": "The file has been renamed.",
        "Nazwa pola": "Field name",
        "Nazwa przycisku ``Dalej``": "Button name ''Next''",
        "Nazwa przycisku ``Wstecz``": "Button name ''Back''",
        "Nazwa przycisku ``Wyślij``": "Button name ''Send''",
        "Nazwa roli": "Role name",
        "Nazwa serwera": "Server name",
        "Nazwa szablonu": "Template name",
        "Nazwa użytkownika": "User name",
        "Nazwa użytkownika serwisowego": "Maintenance user name",
        "Nazwa zadania": "Task name",
        "Nazwa zespołu": "Team name",
        "Nazwa źródła": "Source name",
        "Nazwa źródła danych": "Data source name",
        "Nazwisko": "Last name",
        "Nie": "No",
        "Nie masz uprawnień do panelu administracyjnego.": "You do not have permission to access the administration panel.",
        "Nie możesz modyfikować uprawnień": "You cannot modify permissions",
        "Nie można usunąć swojego konta.": "You can't delete your account.",
        "Nie podano wymaganej ilości znaków: %0.": "The required number of characters was not given: %0.",
        "Nie udało się pobrać listy działów": "Failed to download the sections list",
        "Nie udało się pobrać listy filtrów": "Failed to download filter list",
        "Nie udało się pobrać listy kanałów": "Failed to download channel list",
        "Nie udało się pobrać listy powiadomień": "Failed to download notification list",
        "Nie udało się pobrać listy ról": "Failed to download role list",
        "Nie udało się pobrać listy serwerów baz danych": "Failed to retrieve the database server list",
        "Nie udało się pobrać listy serwerów plików": "Failed to download the file server list",
        "Nie udało się pobrać listy typów modeli": "Failed to download the list of model types",
        "Nie udało się pobrać listy wydziałów": "Failed to download the list of sections",
        "Nie udało się pobrać powiadomień.": "Failed to download notifications.",
        "Nie udało się usunąć szablonu": "Failed to delete template",
        "Nie udało się wysłać pliku": "Failed to send file",
        "Nie wszystkie wybrane elementy zostały przeniesione. Nie posiadasz wymaganych uprawnień lub, w przypadku przenoszenia katalogów, katalog docelowy jest podkatalogiem katalogu źródłowego. Katalogi i pliki których nie udało się przenieść pozostały nadal w zakładce Wybrane.": "Not all selected items have been transfered. You do not have the required permissions or, if you are moving directories, the destination directory is a subdirectory of the source directory. Directories and files that could not have been transfered remain in the Selected tab.",
        "nie wymuszaj zmiany hasła": "do not force password change",
        "Nie zdefiniowano żadnych ról.": "No roles are defined.",
        "Nie znaleziono żadnych plików": "No files found",
        "Nie znaleziono żadnych wyników.": "No results found.",
        "Nieaktywne": "Inactive",
        "Niepotwierdzeni": "Unconfirmed",
        "Nieprawidłowy adres email": "Invalid email address",
        "Nieprawidłowy adres URL": "Invalid URL",
        "Nieprzeczytane": "Unread",
        "Niestandardowy błąd": "Non-standard error",
        "Nieudane": "Failed",
        "Niewidoczne": "Not visible",
        "Nigdy": "Never",
        "Nikomu nie przydzielono uprawnień": "No one has been granted permissions",
        "Nowa data": "New date",
        "Nowe hasło": "New password",
        "Numer domu": "House no",
        "Numer mieszkania": "Flat no",
        "Numer sesji": "Session number",
        "Obok siebie": "Next to each other",
        "Obraz został wycięty.": "The image has been cut out.",
        "Oczekuje na wysłanie": "To be shipped",
        "od": "from",
        "Odejmowanie": "Subtraction",
        "Odpowiedzi": "Responses",
        "Odpowiedź": "Answer",
        "Odrzucone": "Rejected",
        "Odstęp": "Space",
        "Odznacz wszystkie": "Unselect all",
        "Ok": "Ok",
        "Opcje": "Options",
        "Opis": "Description",
        "Opis zespołu": "Team description",
        "Opis źródła": "Source description",
        "Opisy": "Descriptions",
        "Oraz nowe hasło": "And a new password",
        "Organizacja": "Organization",
        "Osoba zastępowana": "Person being substituted",
        "Osoba zastępująca": "Substitute",
        "Ostatnia aktywność": "Recent activity",
        "Ostatnia strona": "Last page",
        "Parametry, wymagane do wykonania zapytania:": "Parameters required to execute the request:",
        "Pasek": "Bar",
        "Pasek postępu": "Progress bar",
        "Październik": "October",
        "Pierwsza strona": "First page",
        "Plik jest wysyłany": "The file is being sent",
        "Plik został wysłany": "The file has been sent",
        "Pliki do wysłania: %0": "Files to send: %0",
        "Pliki prywatne": "Private files",
        "Pliki publiczne": "Public files",
        "po %0 nieudanych próbach logowania": "after %0 unsuccessful login attempts",
        "Po zaznaczeniu tej opcji uprawnienia indywidualne i pochodzące z ról nie są brane pod uwagę.": "When you select this option, individual and role permissions will not be considered.",
        "Poczta": "Mail",
        "Podaj swój adres email": "Enter your email address",
        "Podana nazwa jest zajęta.": "The name you entered is already taken.",
        "Podana wartość jest nieprawidłowa.": "The provided value is not valid.",
        "Podanie nazwy jest wymagane.": "Name is required.",
        "Podano nieprawidłowe dane": "Invalid data provided.",
        "Podano nieprawidłowe dane.": "Invalid data provided.",
        "Podgląd": "Preview",
        "Podpis": "Signature",
        "Podstawowe": "Basic",
        "Podstawowe operacje na datach": "Basic date operations",
        "Podstawowe operacje na liczbach": "Basic digits operations",
        "Podstawowe operacje tekstowe": "Basic text operations",
        "Podstawowe operacje warunkowe": "Basic conditional operations",
        "Pojedynczy wybór": "Single choice",
        "Pokaż etykietę": "Show tag",
        "Pokaż historię": "Show history",
        "Pokaż numery stron": "Show page numbers",
        "Pokaż przycisk ``Wyślij``": "Show ''Send'' button",
        "Pokaż szczegóły": "Show details",
        "Pokaż tytuł formularza": "Show form title",
        "Pokaż tytuł sekcji": "Show section title",
        "Pokaż tytuł strony": "Show page title",
        "Pokaż tytuły stron": "Show page titles",
        "Pokaż więcej": "Show more",
        "pokaż więcej...": "show more...",
        "Pole \"%0\" jest wymagane.": "Field ''%0'' is required.",
        "Pole \"%0\" nie zawiera prawidłowego adresu email.": "Field ''%0'' does not contain a valid email address.",
        "Pole \"%0\" nie zawiera prawidłowego adresu URL.": "Field ''%0'' does not contain a valid URL address.",
        "Pole \"Drugie imię\" jest wymagane.": "''Middle name'' is required.",
        "Pole \"Imię\" jest wymagane.": "''Name'' is required.",
        "Pole \"Nazwisko\" jest wymagane.": "''Surname'' is required.",
        "Pole \"Tytuł\" jest wymagane.": "''Title'' is required.",
        "Pole może zawierać jedynie cyfry.": "Field can only contain numbers.",
        "Pole może zawierać jedynie litery i cyfry.": "This field can contain only letters and numbers.",
        "Pole może zawierać jedynie litery.": "This field can only contain letters.",
        "Pole wymagane": "Field required",
        "Pole zawiera": "Field contains",
        "Polski": "Polish",
        "Pomoc": "Help",
        "Poprawne": "Correct",
        "Poprzednia strona": "Previous page",
        "Porównaj %0": "Compare %0",
        "Porównaj z edytowaną wersją": "Compare with edited version",
        "Porównania": "Comparison",
        "Porównanie": "Comparison",
        "Porównywana wersja": "Compared version",
        "Port": "Port",
        "Potwierdzenie przywrócenia": "Confirmation of restoration",
        "Potwierdzenie usunięcia": "Deletion confirmation",
        "Potwierdzenie zmiany statusu": "Confirmation of status change",
        "Potwierdź akceptację": "Confirm acceptance",
        "Powiadomienia": "Notifications",
        "Powiadomienia zostały skonfigurowane.": "Notifications have been configured.",
        "Powiadomienie jest domyślnie wymagane. Nie da się wyłączyć wysyłania tego powiadomienia.": "Notification is required by default. You cannot stop this notification to appear.",
        "Powiększ": "Zoom in",
        "Powrót": "Back",
        "Powtórz hasło": "Repeat password",
        "Powtórzone": "Repeated",
        "Pozostało %0 znaków.": "There are %0 characters left.",
        "Pozycja": "Position",
        "Prawy panel": "Right panel",
        "Procent": "Percentage",
        "Profil użytkownika": "Profile",
        "Profil został zaktualizowany.": "The profile has been updated.",
        "Projekt": "Project",
        "Prywatne": "Private",
        "Przeciągnij i upuść aby posortować pliki": "Drag and drop to sort files",
        "Przeczytane": "Read",
        "Przedrostek": "Prefix",
        "Przeglądarka z której korzystasz nie wspiera powiadomień PUSH": "The browser you are using does not support PUSH notifications",
        "Przekroczono dozwoloną ilość znaków: %0.": "Allowed number of characters exceeded: %0.",
        "Przełącznik": "Switcher",
        "Przeniesienie wybranych elementów": "Transfer selected items",
        "Przenieś": "Move",
        "Przesuń do góry": "Move up",
        "Przesuń na dół": "Move down",
        "Przetestuj ustawienia poczty": "Test your mail settings",
        "Przetwarzane": "Processing",
        "Przybornik": "Kit",
        "Przyrostek": "Suffix",
        "Przywróć": "Reload",
        "Przyznaj dostęp": "Grant access",
        "Publiczne": "Public",
        "Pytania": "Questions",
        "Radio": "Radio",
        "Ranga": "Rank",
        "Region": "Region",
        "Rejestr zmian": "Changelog",
        "Repozytorium plików": "File repository",
        "Resetuj": "Reset",
        "Rodzaj odpowiedzi": "Type of response",
        "Rok": "Year",
        "Rola": "Role",
        "Rola aktywna": "Active role",
        "Rola została dodana.": "The role has been added.",
        "Rola została usunięta.": "The role has been removed.",
        "Rola została zaktualizowana.": "The role has been updated.",
        "Role i uprawnienia": "Roles and permissions",
        "Role użytkownika": "User roles",
        "Rozmiar": "Size",
        "Rozmiar zdjęcia został zmieniony.": "The image has been resized.",
        "Równość": "Equality",
        "Satysfakcja": "Satisfaction",
        "Sekcja": "Section",
        "Serwer bazy danych": "Database server",
        "Serwer dostępny": "Server available",
        "Serwer plików": "File server",
        "Serwer został dodany.": "Server has been added.",
        "Serwer został usunięty.": "Server has been removed.",
        "Serwer został zaktualizowany.": "Server has been updated.",
        "Serwery plików": "File servers",
        "Sesja": "Session",
        "Sesja nr %0": "Session No . %0",
        "Sesja użytkownika": "User's session has expired.",
        "Sierpień": "August",
        "Skala 1-5": "Scale 1-5",
        "słowa": "words",
        "Słownik": "Dictionary",
        "Słownik dowolny": "Any dictionary",
        "Słownik systemowy": "System dictionary",
        "Sortowanie malejąco": "Sort descending ",
        "Sortowanie rosnąco": "Sort ascending",
        "Sprawdzenie czy pusty": "Check if empty",
        "Stała wartość": "Constant value",
        "Stan powiadomienia": "Notification status",
        "Status": "Status",
        "Status dokumentu został zmieniony.": "The status of the document has been changed.",
        "Status edytowanej wersji dokumentu": "Status of the edited version of the document",
        "Strona %0": "Page %0",
        "Strona główna": "Home",
        "Stwierdzenie": "Statement",
        "Styczeń": "January",
        "Symbol": "Symbol",
        "Symbol klienta": "Customer symbol",
        "Szablon został dodany.": "The template has been added.",
        "Szablon został usunięty.": "The template has been removed.",
        "Szablon został zaktualizowany.": "The template has been updated.",
        "Szczegóły": "Details",
        "Szczegóły zadania": "Task details",
        "Szczegóły zdarzenia": "Event details",
        "Szczegóły zdarzenia nr %0": "Details of event number %0",
        "Szerokość": "Width",
        "Szkic": "Draft",
        "Szukaj": "Search",
        "Szukana fraza": "Search term",
        "Tagi": "Tags",
        "Tak": "Yes",
        "Tak/Nie": "Yes/No",
        "Tekst": "Text",
        "Tekst alternatywny": "Alt text",
        "Tekst zastępczy": "Alternative text",
        "Telefon": "Phone",
        "Ten element nie zawiera menu kontekstowego.": "This item does not contain a context menu.",
        "Ten kanał ma możliwość wysyłania tekstu sformatowanego.": "This channel provides a possibility to send formatted text.",
        "Ten kanał nie ma możliwości wysyłania tekstu sformatowanego.": "This channel does not provide a possibility to send formatted text.",
        "Teraz": "Now",
        "Terminy do słownika będzie można dodawać dopiero po zapisaniu źródła danych.": "You won't be able to add terms to the dictionary until you save the data source.",
        "Testowy adres email": "Tested email address",
        "To pole jest wymagane": "The field is required.",
        "Treści odpowiedzi są wymagane": "Answers are required",
        "Treści pytań są wymagane": "Questions are required",
        "Treść": "Content",
        "Tryb konserwacji systemu": "System maintenancemMode",
        "Trzy kolumny": "Three columns",
        "Twoje hasło wygasło. Zmień je na nowe.": "Your password has expired. Change it to new one.",
        "Tylko do odczytu": "Read-only",
        "Tylko serwer deweloperski. Nie używać w trybie produkcyjnym. ": "Development server only. Do not use in production mode. ",
        "Typ": "Type",
        "Typ konta": "Account type",
        "Typ mime": "Mime type",
        "Typ obiektu": "Object type",
        "Typ pola": "Field type",
        "Typ powiadomienia": "Notification type",
        "Typ serwera": "Server type",
        "Typ słownika": "Dictionary type",
        "Typ źródła": "Source type",
        "Typ źródła danych": "Data source type",
        "Tytuł": "Title",
        "Tytuł formularza jest wymagany": "Form title is required",
        "Tytuł formularza nie może być dłuższy niż %0 znaków": "The title of the form cannot be longer than %0 znaków",
        "Tytuł sekcji": "Section title",
        "Układ": "Arrangement",
        "Ulica": "Street",
        "Upload": "Upload",
        "Uprawnienia": "Permissions",
        "Uruchom zadanie": "Run the task",
        "Urządzenie zostało usunięte.": "The device has been removed.",
        "Usługi": "Services",
        "Ustaw szerokości proporcjonalnie": "Set widths proportionally",
        "Ustawienia": "Settings",
        "Ustawienia konta": "Account settings",
        "Ustawienia powiadomień zostały zaktualizowane.": "Your notification settings have been updated.",
        "Ustawienia zaawansowane": "Advanced settings",
        "Ustawienia zostały zapisane.": "Settings have been saved.",
        "Usunął": "Deleted",
        "Usunięcie": "Deletion",
        "Usunięcie wybranych elementów": "Delete selected items",
        "Usuń": "Delete",
        "Usuń datę": "Delete date",
        "Usuń filtr": "Remove filter",
        "Usuń plik": "Delete file",
        "Usuń plik z listy": "Remove the file from the list",
        "Usuń spacje": "Remove spaces",
        "Usuń z zespołu": "Remove from team",
        "Utworzył": "Created ",
        "Utwórz katalog": "Create a directory",
        "Utwórz nowy": "Create new",
        "Uwzględnij wydziały podrzędne": "Include sub-departments",
        "Użytkownicy": "Users",
        "Użytkownicy w tej roli": "Users in this role",
        "Użytkownik": "User",
        "Użytkownik jest liderem zespołu": "The user is a team leader",
        "Użytkownik posiada pełne uprawnienia": "The user has full permissions",
        "Użytkownik został dodany do zespołu.": "The user has been added to the team.",
        "Użytkownik został dodany.": "The user has been added.",
        "Użytkownik został usunięty.": "User has been deleted.",
        "Użytkownik został zaktualizowany.": "The user has been updated.",
        "W organizacji nie ma jeszcze żadnych działów.": "There are no sections in the organization yet.",
        "W przyszłości": "In the future",
        "Walidacja": "Validation",
        "Walidacja certyfikatu SSL": "SSL certificate validation",
        "Wartość": "Value",
        "Wartość domyślna": "Default value",
        "Wartość musi być liczbą całkowitą": "The value must be an integer",
        "Wartość musi być liczbą dodatnią": "The value must be a positive number",
        "Wartość musi być liczbą ujemną": "The value must be a negative number",
        "Warunki logiczne": "Logical conditions",
        "Warunkowo": "Conditionally",
        "Ważne informacje": "Important information",
        "Ważność": "Validity",
        "Wersja utowrzona przez": "Version created by",
        "Wersje": "Versions",
        "Wersje dokumentu": "Document versions",
        "Wewnętrznie": "Internally",
        "Wiadomość testowa została wysłana.": "A test message has been sent.",
        "Widoczność": "Visibility",
        "Wielkość kroku": "Step size",
        "Wielokrotny wybór": "Multiple choice",
        "Więcej...": "More…",
        "Wklej komponent": "Paste component",
        "Właściwości": "Characteristics",
        "Włączone": "Enabled",
        "Województwo": "Province",
        "Wpis domyślny": "Default entry",
        "Wpis został dodany.": "The post has been added.",
        "Wpis został usunięty.": "The post has been deleted.",
        "Wpis został zaktualizowany.": "The post has been updated.",
        "Wprowadzona wartość jest nieprawidłowa": "The value you entered is invalid",
        "Wprowadzona wartość jest za długa": "The value you entered is too long",
        "Wprowadzona wartość jest za krótka": "The value you entered is too short",
        "Wprowadzona wartość jest za niska": "The value you entered is too low",
        "Wprowadzona wartość jest za wysoka": "The value you entered is too high",
        "Wprowadzona wartość nie jest poprawna": "The value you entered is not valid",
        "Wróć do logowania": "Back to login",
        "Wróć do strony głównej": "Back to Home Page",
        "Wrzesień": "September",
        "Wstaw cztery kolumny 1/4": "Insert four columns 1/4",
        "Wstaw dwie kolumny 1/3 oraz 2/3": "Insert two columns 1/3 and 2/3",
        "Wstaw dwie kolumny 2/3 oraz 1/3": "Insert two columns 2/3 and 1/3",
        "Wstaw dwie równe kolumny": "Insert two equal columns",
        "Wstaw kontrolkę": "Insert control",
        "Wstaw stronę": "Insert page",
        "Wstaw szablon informacyjny ze zdjęciem": "Insert information template with photo",
        "Wstaw trzy kolumny 1/3": "Insert three columns 1/3",
        "Wstaw z dołu": "Insert from below",
        "Wstaw z góry": "Insert from above",
        "Wstaw z lewej strony": "Insert left",
        "Wstaw z prawej strony": "Insert right",
        "Wstecz": "Back",
        "Wszystkie": "All",
        "Wszystkie niezapisane zmiany zostaną utracone.": "Any unsaved changes will be lost.",
        "Wszystkie próby": "All attempts",
        "wybierz": "select",
        "Wybierz": "Select",
        "wybierz długość": "choose length",
        "Wybierz plik": "Select a file",
        "Wybierz plik lub katalog": "Select a file or directory",
        "Wybierz pliki": "Select files",
        "Wybierz pliki (do {0})": "Select files (to {0})",
        "Wybierz serwer": "Select server",
        "Wybierz typ powiadomienia aby zobaczyć dostępne klucze.": "Select the notification type to see the available keys.",
        "Wybierz zaznaczone": "Choose selected",
        "wybierz...": "select...",
        "Wybór": "Choice",
        "Wybór języka": "Language",
        "Wybrana data jest mniejsza niż dozwolona %0.": "Selected date is before the permitted one %0.",
        "Wybrana data jest większa niż dozwolona %0.": "Selected date is above the permitted one %0.",
        "Wybrana liczba jest mniejsza niż dozwolona %0.": "Selected number is lesser than allowed %0.",
        "Wybrana liczba jest większa niż dozwolona %0.": "Selected number is greater then allowed %0.",
        "Wybrane": "Selected",
        "Wybrane elementy zostały przeniesione.": "The selected items have been moved.",
        "Wybrane elementy zostały usunięte.": "The selected items have been removed.",
        "Wybrane pliki": "Selected files",
        "Wybrane pliki: {0}": "Selected files: {0}",
        "Wybrany język": "Selected language",
        "Wyczyść": "Clear",
        "Wydział": "Department",
        "Wykonaj": "Do",
        "Wyloguj się": "Log out",
        "Wymagaj znaków specjalnych w hasłach": "Require special characters in passwords",
        "Wymagane": "Required",
        "Wymagane jest ustawienie minimalnej długości hasła": "Minimum password length is required",
        "Wymagane jest wybranie sekcji": "A section must be selected",
        "Wymagane jest wybranie źródła danych": "Data source is required",
        "Wymiary": "Dimensions",
        "Wymuś zmianę hasła": "Force password change",
        "Wyrażenia warunkowe": "Conditional expressions",
        "Wyrażenie regularne": "Regular expression",
        "Wysłane": "Sent",
        "Wysokość": "Height",
        "Wystąpił błąd połączenia. Upewnij się że masz włączony internet i spróbuj ponownie.": "A connection error has occurred. Make sure you are online and try again.",
        "Wystąpił błąd serwera podczas obsługiwania wybranej operacji. Spróbuj ponownie.": "A server error occurred while handling the selected operation. Please try again.",
        "Wysyłaj tym kanałem": "Send through this channel",
        "Wyszukaj wszędzie": "Search everywhere",
        "Wyszukaj...": "Search...",
        "Wyszukiwany ciąg znaków": "Search set of characters",
        "Wyślij": "Send",
        "Wyświetlam": "Displaying",
        "Wytnij": "Cut",
        "Wyzeruj licznik": "Reset counter",
        "Wzorzec": "Pattern",
        "Zaakceptowany": "Accepted",
        "Zabierz dostęp": "Take away access",
        "Zablokowany": "Blocked",
        "zacznij pisać aby wyszukać użytkownika...": "start typing to search for a user...",
        "Zaczyna sie od": "It starts with",
        "Zadanie zostało uruchomione.": "The task has started.",
        "Zakolejkowane": "Pending",
        "Zakończone": "Completed",
        "Zakres czasu": "Time range",
        "Zaloguj się": "Sign In",
        "Zaloguj się na swoje konto": "Log in to your account",
        "Załączniki": "Attachments",
        "Zamiany zostały zapisane.": "The changes have been saved.",
        "Zamknij": "Close",
        "Zaokrąglenie": "Rounding",
        "Zapamiętaj logowanie": "Remember login",
        "Zapisz": "Save",
        "Zapisz filtr": "Save filter",
        "Zapisz i wróć": "Save and return",
        "Zapisz zmiany": "Save changes",
        "Zaplanowane": "Scheduled",
        "Zapomniałeś hasła?": "Forgot your password?",
        "Zasięg": "Range",
        "Zastępstwa": "Substitutions",
        "Zastępstwo": "Add a substitution",
        "Zastępstwo wygasło.": "The substitution has expired.",
        "Zastępstwo wygaśnie za 10 minut.": "The substitution will expire in 10 minutes.",
        "Zastępstwo wygaśnie za 5 minut.": "The substitution will expire in 5 minutes.",
        "Zastępstwo wygaśnie za minutę.": "The substitution will expire in a minute.",
        "Zastępstwo zostało dodane.": "The substitution has been added.",
        "Zastępstwo zostało usunięte.": "The substitution has been removed.",
        "Zastępstwo zostało zaktualizowane.": "The substitution has been updated.",
        "Zastępuje osobę": "Substituting",
        "Zawiera": "Contains",
        "Zawsze": "Always",
        "Zaznacz wszystkie": "Select all",
        "Zdarzenie": "Event",
        "Zdjęcie": "Photo",
        "Zdjęcie użytkownika": "User's photo",
        "Zespoły": "Teams",
        "Zespół": "Team",
        "Zespół aktywny": "Active team",
        "Zespół został dodany.": "The team has been added.",
        "Zespół został usunięty.": "The team has been removed.",
        "Zespół został zaktualizowany.": "The team has been updated.",
        "Zgoda": "Consent",
        "Zmiana hasła": "Password change",
        "Zmiana rozmiaru obrazu": "Resize an image",
        "Zmień hasło": "Change password",
        "Zmień nazwę": "Rename",
        "Zmień nazwę katalogu": "Rename a directory",
        "Zmień nazwę pliku": "Rename a file",
        "Zmień rozmiar": "Resize",
        "Zmień status edytowanej wersji": "Change the status of the edited version",
        "Zmniejsz": "Reduce",
        "Zmodyfikował": "Modified by",
        "znaki": "characters",
        "Zobacz sesję użytkownika": "View user session",
        "Zobacz szczegóły sesji użytkownika": "View user session details",
        "Zobacz szczegóły zdarzenia": "View event details",
        "Zobacz wszystkie": "See all",
        "Zresetuj hasło": "Reset password",
        "Zwrócony": "Returned",
        "Źródła danych": "Data Sources",
        "Źródło": "Source",
        "Źródło danych": "Data source",
        "źródło danych zostało dodane.": "the data source has been added.",
        "Źródło danych zostało zaktualizowane.": "The data source has been updated.",
        "źródło zostało usunięte.": "the source has been removed.",
        "Żaden kanał komunikacji nie został włączony w ustawieniach powiadomień.": "No communication channel has been enabled in the notification settings.",
        "...": "...",
        "' + file.filename + '": "' + file.filename + '",
        "' + func.name + '": "' + func.name + '",
        "%0 z %1": "%0 of %1",
        "<Brak nazwy>": "<No Name>",
        "${copied ? 'Skopiowano' : 'Kopiuj'}": "${copied ? 'Copied' : 'Copy'}",
        "${el.text}": "${el.text}",
        "${form.variants > 1 ? 'Generuj obrazy' : 'Generuj obraz'}": "${form.variants > 1 ? 'Generate images' : 'Generate image'}",
        "${getCurrentScope().label}": "${getCurrentScope().label}",
        "${item.entityName}": "${item.entityName}",
        "${item.text}": "${item.text}",
        "${loading ? 'Ładowanie elementów...' : 'Nie znaleziono żadnych elementów.'}": "${loading ? 'Loading items...' : 'No items found.'}",
        "${props.inputPlaceholder}": "${props.inputPlaceholder}",
        "${str}": "${str}",
        "${tokenVisibility ? 'Ukryj' : 'Pokaż'} token": "${tokenVisibility ? 'Hide' : 'Show'} token",
        "1:1": "1:1",
        "2 dni": "2 days",
        "2 miesiące": "2 months",
        "2 tygodnie": "2 weeks",
        "3 dni": "3 days",
        "3 miesiące": "3 months",
        "4 dni": "4 days",
        "4:7": "4:7",
        "5 dni": "5 days",
        "6 dni": "6 days",
        "7:4": "7:4",
        "Aby móc przetestować poprawność kluczy, najpierw zapisz formularz.": "To test the correctness of the keys, please save the form first.",
        "Adaptive sampling": "Adaptive sampling",
        "Adres IP: %0": "IP Address: %0",
        "Akcent": "Accent",
        "Akceptacja dokumentów": "Document approval",
        "Akceptacja dokumentu": "Document approval",
        "Akceptacja własnych dokumentów": "Approval of own documents",
        "Akceptacja wszystkich dokumentów": "Approval of all documents",
        "Akceptowalny typ plików": "Acceptable file type",
        "Akceptowany format danych": "Accepted data format",
        "Aktualizacja: {0}": "Update: {0}",
        "Aktualna sesja": "Current session",
        "Aktywna": "Active",
        "Aktywne sesje": "Active sessions",
        "Aktywne źródła": "Active sources",
        "Aktywni": "Active",
        "Aktywny": "Active",
        "Aktywuj": "Activate",
        "Aktywuj tłumaczenia": "Activate translations",
        "Aktywuj zestaw inteligentnych narzędzi": "Activate smart tools set",
        "Alert": "Alert",
        "Alias": "Alias",
        "Alias został przepięty.": "Alias has been reassigned.",
        "Anonimizuj": "Anonymize",
        "Anonimowy": "Anonymous",
        "Aplikacja": "Application",
        "Aplikacja zostanie zrestartowana w przeciągu maksymalnie 60 sekund.": "The application will be restarted within a maximum of 60 seconds.",
        "application/json": "application/json",
        "application/x-www-form-urlencoded": "application/x-www-form-urlencoded",
        "Asset został aktywowany.": "Asset has been activated.",
        "Asset został wyłączony.": "Asset has been deactivated.",
        "Asystenci": "Assistants",
        "Asystent AI": "AI Assistant",
        "Asystent został dodany.": "Assistant has been added.",
        "Asystent został zaktualizowany.": "Assistant has been updated.",
        "Asystenta AI": "AI Assistant",
        "Audio": "Audio",
        "Automatyzacja": "Automation",
        "Azure AI": "Azure AI",
        "Azure Application Insights": "Azure Application Insights",
        "Azure OpenAI": "Azure OpenAI",
        "Basic Auth": "Basic Auth",
        "Baza danych tego klienta jest nieaktualna.": "The client's database is outdated.",
        "Bieżący dokument": "Current document",
        "Blokada konta do": "Account lock until",
        "Blokowanie kont": "Account blocking",
        "Błędny": "Invalid",
        "Błędy": "Errors",
        "Błędy walidacji należy przypisać do \"entry.errors.value\" w postaci tablicy komunikatów.": "Validation errors should be assigned to \"entry.errors.value\" as an array of messages.",
        "Brak cennika dla wybranego dostawcy.": "No price list for the selected provider.",
        "Brak danych": "No data",
        "Brak dodanych kolumn": "No columns added",
        "Brak elementów": "No items",
        "Brak kolekcji": "No collection",
        "Brak opcji": "No options",
        "Brak wyboru": "No selection",
        "Brak zdefiniowanych ról.": "No defined roles.",
        "Brak zdefiniowanych zespołów.": "No defined teams.",
        "Bramka SMS": "SMS Gateway",
        "Cache będzie przechowywany z wykorzystaniem serwera Redis.": "Cache will be stored using a Redis server.",
        "Cache po stronie serwera": "Server-side cache",
        "Cache-Control": "Cache-Control",
        "Cena": "Price",
        "Cena w dolarach sumy tokenów wejściowych i wyjściowych.": "Price in dollars for the sum of input and output tokens.",
        "Cennik Azure OpenAI": "Azure OpenAI pricing",
        "Cennik AzureAI": "AzureAI pricing",
        "Cennik OpenAI": "OpenAI pricing",
        "Centrum powiadomień": "Notification Center",
        "Ciemny": "Dark",
        "Client ID": "Client ID",
        "Client Secret": "Client Secret",
        "Co 10 dni": "Every 10 days",
        "Co 10 minut": "Every 10 minutes",
        "Co 12 godzin": "Every 12 hours",
        "Co 15 minut": "Every 15 minutes",
        "Co 2 godziny": "Every 2 hours",
        "Co 2 minuty": "Every 2 minutes",
        "Co 2 tygodnie": "Every 2 weeks",
        "Co 20 minut": "Every 20 minutes",
        "Co 3 godziny": "Every 3 hours",
        "Co 3 miesiące": "Every 3 months",
        "Co 30 minut": "Every 30 minutes",
        "Co 4 miesiące": "Every 4 months",
        "Co 5 dni": "Every 5 days",
        "Co 5 minut": "Every 5 minutes",
        "Co 6 godzin": "Every 6 hours",
        "Co chcesz wygenerować?": "What do you want to generate?",
        "Co drugi dzień": "Every other day",
        "Co drugi miesiąc": "Every other month",
        "Co minutę": "Every minute",
        "Co pół roku": "Every six months",
        "Connection String": "Connection String",
        "Czas uruchomienia": "Runtime",
        "Czat z asystentem": "Chat with assistant",
        "Czy aby na pewno chesz zrestartować aplikacje?": "Are you sure you want to restart the application?",
        "Czy na pewno chcesz usunąć widget?": "Are you sure you want to delete the widget?",
        "DALL-E 3": "DALL-E 3",
        "Dane": "Data",
        "Dane w tabeli": "Data in table",
        "Dane wejściowe": "Input data",
        "Dane wyjściowe": "Output data",
        "Data opuszczenia zespołu": "Team departure date",
        "Data otrzymania": "Date received",
        "Data uruchomienia": "Start date",
        "Data uruchomienia (UTC)": "Start date (UTC)",
        "Data utworzenia: ": "Creation date: ",
        "Data użycia": "Usage date",
        "Data wygaśnięcia": "Expiration date",
        "Data wywołania": "Invocation date",
        "Data zapytania": "Query date",
        "Data: %0 | Status: %1": "Date: %0 | Status: %1",
        "Dedykowane": "Dedicated",
        "DeepL": "DeepL",
        "Definicja": "Definition",
        "Definicja (JSON)": "Definition (JSON)",
        "DELETE: Usunięcie wpisu": "DELETE: Entry deletion",
        "Design Studio": "Design Studio",
        "Dezaktywuj": "Deactivate",
        "Długość czasu przechowywania": "Retention period",
        "Długość trwania sesji [min": "Session duration [min",
        "Dni": "Days",
        "Do": "To",
        "Docs": "Docs",
        "Dodaj asystenta": "Add assistant",
        "Dodaj automatyzacje": "Add automation",
        "Dodaj do zespołu": "Add to team",
        "Dodaj dokument": "Add document",
        "Dodaj endpoint": "Add endpoint",
        "Dodaj funkcję": "Add function",
        "Dodaj grupę": "Add group",
        "Dodaj jeden lub więcej widgetów, aby uzyskać wgląd w postępy swojego zespołu.": "Add one or more widgets to get insights into your team's progress.",
        "Dodaj komponent": "Add component",
        "Dodaj moduł": "Add module",
        "Dodaj nowy folder": "Add new folder",
        "Dodaj nowy wątek": "Add new thread",
        "Dodaj plik": "Add file",
        "Dodaj pliki": "Add files",
        "Dodaj podsumowanie": "Add summary",
        "Dodaj pozycję": "Add item",
        "Dodaj scenariusz": "Add scenario",
        "Dodaj sekcję": "Add section",
        "Dodaj shortcode": "Add shortcode",
        "Dodaj słowo": "Add word",
        "Dodaj synonimy": "Add synonyms",
        "Dodaj token": "Add token",
        "Dodaj webhooka": "Add webhook",
        "Dodaj widget": "Add widget",
        "dodał": "added",
        "dodane i edytowane przeze mnie": "added and edited by me",
        "Dodanie asystenta": "Adding assistant",
        "Dodanie dokumentu": "Adding document",
        "Dodanie endpointu": "Adding endpoint",
        "Dodanie funkcji": "Adding function",
        "Dodanie komponentu": "Adding component",
        "Dodanie modułu": "Adding module",
        "Dodanie pliku": "Adding file",
        "Dodanie scenariusza": "Adding scenario",
        "Dodanie webhooka": "Adding webhook",
        "Dodanie źródła": "Adding source",
        "Dodano za dużo plików (maksymalnie %0).": "Too many files added (maximum %0).",
        "dodany": "added",
        "Dodatkowe informacje": "Additional information",
        "Dodawanie dokumentów": "Adding documents",
        "Dodawanie i edycja własnych dokumentów": "Adding and editing own documents",
        "Dodawanie wartości dwóch kolumn": "Adding values of two columns",
        "Dokument PDF": "PDF document",
        "Dokument tekstowy": "Text document",
        "Dokument został usunięty.": "Document has been deleted.",
        "Dokumenty": "Documents",
        "Dołącz stack trace": "Include stack trace",
        "Dołączanie plików": "Attaching files",
        "Domena": "Domain",
        "Domyślne": "Default",
        "Domyślne filtry": "Default filters",
        "Domyślnie ukryte": "Hidden by default",
        "Domyślnie widoczne": "Visible by default",
        "Dostawca": "Provider",
        "Dostęp do modułu": "Access to module",
        "Dostępne": "Available",
        "Dostępny limit": "Available limit",
        "dowolnej wybranej wersji": "any selected version",
        "Dozwolone rozszerzenia plików": "Allowed file extensions",
        "Dozwolone rozszerzenia: %0.": "Allowed extensions: %0.",
        "Dozwolone są tylko małe i duże litery": "Only uppercase and lowercase letters are allowed",
        "Dozwolone są tylko małe litery i znak -": "Only lowercase letters and the '-' character are allowed",
        "Dozwolone typy plików: %0.": "Allowed file types: %0.",
        "Dsn": "DSN",
        "Duplikuj": "Duplicate",
        "Duże litery": "Uppercase",
        "Duży": "Large",
        "Duży układ siatki": "Large grid layout",
        "Dyrektywa max-age=N wskazuje, że odpowiedź pozostaje świeża do N sekund po wygenerowaniu odpowiedzi.": "The max-age=N directive indicates that the response remains fresh for N seconds after the response is generated.",
        "Dyrektywa no-store wskazuje, że jakikolwiek cache dowolnego rodzaju nie powinien przechowywać tej odpowiedzi.": "The no-store directive indicates that any cache of any kind should not store this response.",
        "Dyrektywa PRIVATE wskazuje, że odpowiedź może być przechowywana wyłącznie w prywatnej pamięci podręcznej (np. lokalnej pamięci przeglądarki).": "The PRIVATE directive indicates that the response may be stored only in a private cache (e.g., the browser's local cache).",
        "Dyrektywa PUBLIC wskazuje, że odpowiedź może być przechowywana we współdzielonej pamięci podręcznej (np. proxy).": "The PUBLIC directive indicates that the response may be stored in a shared cache (e.g., a proxy).",
        "Dyrektywa s-maxage jest ignorowana przez prywatne pamięci podręczne i zastępuje wartość określoną przez dyrektywę max-age dla współdzielonych pamięci podręcznych.": "The s-maxage directive is ignored by private caches and overrides the max-age directive value for shared caches.",
        "Dyrektywa s-maxage wskazuje, jak długo odpowiedź pozostaje aktualna we współdzielonej pamięci podręcznej (np. proxy).": "The s-maxage directive indicates how long the response remains fresh in a shared cache (e.g., a proxy).",
        "Dyrektywy zakresu": "Scope directives",
        "Działania masowe": "Bulk actions",
        "dzień": "day",
        "Edycja asystenta": "Editing assistant",
        "Edycja dokumentów": "Editing documents",
        "Edycja dokumentu": "Editing document",
        "Edycja endpointu": "Editing endpoint",
        "Edycja funkcji": "Editing function",
        "Edycja komponentu": "Editing component",
        "Edycja logów": "Editing logs",
        "Edycja modułu": "Editing module",
        "Edycja pliku": "Editing file",
        "Edycja scenariusza": "Editing scenario",
        "Edycja w popupie": "Editing in popup",
        "Edycja webhooka": "Editing webhook",
        "Edycja wszystkich dokumentów": "Editing all documents",
        "Edycja źródła": "Editing source",
        "Edytor": "Editor",
        "Edytor lokalizowany": "Localized editor",
        "Edytuj członka grupy": "Edit group member",
        "Edytuj dashboard": "Edit dashboard",
        "Edytuj dokument": "Edit document",
        "Edytuj filtr": "Edit filter",
        "Edytuj kategorię": "Edit category",
        "Eksport": "Export",
        "Eksport do Excela": "Export to Excel",
        "Eksport dokumentów": "Export documents",
        "Eksport użytkowników": "Export users",
        "Email potwierdzony": "Email confirmed",
        "Endpoint został dodany.": "Endpoint has been added.",
        "Endpoint został zaktualizowany.": "Endpoint has been updated.",
        "Eskportuj": "Export",
        "Exportuj do Excela": "Export to Excel",
        "Filmy": "Videos",
        "Filtr dla synchronizacji działów": "Filter for department synchronization",
        "Filtr dla synchronizacji użytkowników": "Filter for user synchronization",
        "Filtr domyślny": "Default filter",
        "Filtrowanie": "Filtering",
        "Filtrowanie listy dokumentów": "Filtering document list",
        "Firebase": "Firebase",
        "Firebase config": "Firebase config",
        "Folder plików": "File folder",
        "Foldery": "Folders",
        "Formularz bazuje na nieaktualnej wersji definicji.": "The form is based on an outdated version of the definition.",
        "Formularz został skopiowany.": "The form has been copied.",
        "Formularz został zaakceptowany.": "The form has been approved.",
        "Formuła jest nieprawidłowa": "The formula is invalid",
        "Funkcja została dodana.": "Function has been added.",
        "Funkcja została usunięta.": "Function has been removed.",
        "Funkcja została zaktualizowana.": "Function has been updated.",
        "Funkcje": "Functions",
        "Funkcje asystenta": "Assistant functions",
        "Funkcjonalności": "Functionalities",
        "Generator obrazów AI": "AI Image Generator",
        "Generowanie formularza zakończone.": "Form generation completed.",
        "Generowanie obrazów": "Image generation",
        "Generowanie obrazów zakończone.": "Image generation completed.",
        "Generuj": "Generate",
        "Generuj formularz": "Generate form",
        "Generuj metadane": "Generate metadata",
        "Generuj metadane (AI)": "Generate metadata (AI)",
        "Generuj nowy obraz": "Generate new image",
        "Generuj obraz": "Generate image",
        "GET: Lista wpisów (sortowanie i stronicowanie)": "GET: List of entries (sorting and pagination)",
        "GET: Pobranie danych": "GET: Retrieve data",
        "GET: Pobranie wpisu": "GET: Retrieve entry",
        "GET: Schemat (definicja formularza)": "GET: Schema (form definition)",
        "Godziny": "Hours",
        "Google Translate V2": "Google Translate V2",
        "GPT-4 Vision": "GPT-4 Vision",
        "GPT-4o Omni": "GPT-4o Omni",
        "Grafika": "Graphics",
        "Grafika wektorowa": "Vector graphics",
        "Harmonogram został zmieniony": "The schedule has been changed.",
        "Health": "Zdrowie",
        "Hierarchia": "Hierarchy",
        "Historia": "History",
        "Historia zapytań do AI": "AI Query History",
        "i": "and",
        "ID lub typ zadania": "ID or task type",
        "Id obiektu": "Object ID",
        "Id wpisu": "Entry ID",
        "ID zadania": "Task ID",
        "Id zapytania": "Query ID",
        "Identyfikator": "Identifier",
        "Identyfikator wdrożenia": "Deployment Identifier",
        "Identyfikator zadania": "Task Identifier",
        "Ilość znaków odpowiedzi": "Number of response characters",
        "Import": "Import",
        "Import modułu": "Module import",
        "Import użytkowników": "User import",
        "Importuj definicję formularza": "Import form definition",
        "Indeks": "Index",
        "Indeks został usunięty.": "The index has been removed.",
        "Informacja": "Information",
        "Instalacja": "Installation",
        "Instrukcja": "Instruction",
        "Instrukcja modelu (prompt).": "Model instruction (prompt).",
        "Interpreter kodu": "Code interpreter",
        "Interpreter kodu umożliwia asystentowi pisanie i uruchamianie kodu. To narzędzie może przetwarzać pliki z różnymi danymi i formatowaniem oraz generować pliki.": "The code interpreter allows the assistant to write and run code. This tool can process files with various data and formatting, and generate files.",
        "Jakość": "Quality",
        "Jakość HD": "HD Quality",
        "Jakość standardowa": "Standard quality",
        "Jakość wygnerowanego obrazu.": "Generated image quality.",
        "Jasny": "Light",
        "Jeśli danego użytkownika nie ma na liście, oznacza to, że brakuje podstawowych danych takich jak Imię i Nazwisko. Uzupełnij te dane w dziale Użytkownicy.": "If a user is not on the list, it means that basic data such as Name and Surname are missing. Fill in these details in the Users section.",
        "Jeśli pole jest puste, wówczas używana jest wartość domyślna. W przeciwnym wypadku wartość domyślna jest nadpisywana.": "If the field is empty, the default value is used. Otherwise, the default value is overridden.",
        "Kanały": "Channels",
        "Katalog główny": "Main directory",
        "Katalog został utworzony": "The directory has been created.",
        "Key": "Key",
        "Key pair": "Key pair",
        "Klucz": "Key",
        "Klucz API": "API Key",
        "Klucz prywatny": "Private key",
        "Klucz publiczny": "Public key",
        "Klucze są nie prawidłowe.": "The keys are invalid.",
        "Klucze są prawidłowe.": "The keys are valid.",
        "Kod walidacji": "Validation code",
        "Kod wertfikacyjny": "Verification code",
        "Kod weryfikacyjny jest nieprawidłowy.": "The verification code is incorrect.",
        "Kod został skopiowany do schowka.": "The code has been copied to the clipboard.",
        "Kod źródłowy": "Source code",
        "Kolejka": "Queue",
        "Kolor drugorzędny: %0": "Secondary color: %0",
        "Kolor icon na panelu z aplikacjami: %0": "Icon color on the applications panel: %0",
        "Kolor podstawowy: %0": "Primary color: %0",
        "Kolor przewodni obrazu": "Image dominant color",
        "Kolor tekstu aktywnej pozycji menu: %0": "Active menu item text color: %0",
        "Kolor tła panelu z aplikacjami: %0": "Applications panel background color: %0",
        "Kolory zostały przywrócone.": "Colors have been restored.",
        "Kolory zostały zapisane.": "Colors have been saved.",
        "Kolumna": "Column",
        "Kolumny": "Columns",
        "Komponent którego wartość zostanie użyta jako nazwa elementu nadrzędnego. Dostępne tylko komponenty typu ``Tekst``.": "Component whose value will be used as the parent element name. Only ``Text`` type components are available.",
        "Komponent relacyjny": "Relational component",
        "Komponent został dodany.": "The component has been added.",
        "Komponent został usunięty.": "The component has been removed.",
        "Komponent został zaktualizowany.": "The component has been updated.",
        "Komponent źródłowy": "Source component",
        "Komponenty": "Components",
        "Konfiguracja asystenta ai": "AI assistant configuration",
        "Konfiguracja harmonogramu (czas UTC)": "Schedule configuration (UTC time)",
        "Konfiguracja kolumn tabeli": "Table column configuration",
        "Konfiguracja stopki oraz logo umieszczanych w wysyłanych powiadomieniach kanałem mailowym. Stopka może być skonfigurowana indywidualnie dla każdej wersji jezykowej, logo jest wspólne dla wszystkich.": "Configuration of the footer and logo placed in email notifications. The footer can be configured individually for each language version, the logo is common for all.",
        "Konfiguracja zaawansowana": "Advanced configuration",
        "Konfiguracja zapytania": "Query configuration",
        "Konta użytkowników": "User accounts",
        "Konta użytkowników zostały odblokowane": "User accounts have been unlocked.",
        "Konta użytkowników zostały przypisane do zespołu": "User accounts have been assigned to the team.",
        "Konta użytkowników zostały usunięte z systemu": "User accounts have been removed from the system.",
        "Konta użytkowników zostały zablokowane": "User accounts have been blocked.",
        "Kontakt": "Contact",
        "Konto aktywne": "Active account",
        "Konto pozostanie nieaktywne dopóki adres e-mail nie zostanie potwierdzony": "The account will remain inactive until the email address is confirmed.",
        "Konto z dostępem do panelu administracyjnego": "Account with access to the admin panel",
        "Konto zanonimizowane": "Account anonymized",
        "Kontrolka": "Control",
        "Konwertuj obrazy do formatu WebP": "Convert images to WebP format",
        "Kopiuj adres": "Copy address",
        "Kopiuj definicję": "Copy definition",
        "Kopiuj pole": "Copy field",
        "Krok minut": "Minute step",
        "Krok minut musi być liczbą z zakresu od 1 do 60.": "The minute step must be a number between 1 and 60.",
        "LDAPS (LDAP over SSL)": "LDAPS (LDAP over SSL)",
        "Licencja": "License",
        "Liczba danych wejściowych podana w tokenach.": "Number of input data given in tokens.",
        "Liczba danych wyjściowych podana w tokenach.": "Number of output data given in tokens.",
        "Liczba wariantów": "Number of variants",
        "Liczba wyników na stronie": "Number of results per page",
        "Lider": "Leader",
        "Limit": "Limit",
        "Limit API": "API Limit",
        "Limit konta OpenAI": "OpenAI account limit",
        "Lista dozwolonych / wykluczonych żądań HTTP": "Allowed / excluded HTTP requests list",
        "Lista elementów zostanie zawężona tylko do tych, które są pozwiązane z wybranymi wartościami z zaznaczonych komponentów.": "The list of items will be narrowed down to those related to the selected values from the marked components.",
        "Lista ID": "ID List",
        "Lista indeksów": "Index List",
        "Lista rozszerzeń oddzielona przecinkami.": "Comma-separated list of extensions.",
        "Lista zaufanych adresów IP": "Trusted IP addresses list",
        "Listy": "Lists",
        "Logi": "Logs",
        "Logi wydajnościowe": "Performance logs",
        "Logowanie zależności / żądań typu HTTP": "Dependency / HTTP request logging",
        "Loguj wybrane": "Log selected",
        "Lokalizowane": "Localized",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit.": "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit.": "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quam commodi autem id, veniam, illum temporibus.": "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quam commodi autem id, veniam, illum temporibus.",
        "Maksymalna rozdzielość %0x%1px": "Maximum resolution %0x%1px",
        "Maksymalny rozmiar pliku: %0MB.": "Maximum file size: %0MB.",
        "Maksymalny rozmiar pojedynczego pliku": "Maximum single file size",
        "Małe litery": "Lowercase letters",
        "Mały": "Small",
        "Mały układ siatki": "Small grid layout",
        "Mappingi": "Mappings",
        "Metoda": "Method",
        "Metoda autentykacji": "Authentication method",
        "Metoda musi zwracać obiekt \"entry.errors\".": "The method must return the \"entry.errors\" object.",
        "miesiąc": "month",
        "Miesiące": "Months",
        "Minimalna ilość plików": "Minimum number of files",
        "Minuty": "Minutes",
        "Model": "Model",
        "Model danych": "Data model",
        "Moderacja włączona": "Moderation enabled",
        "Moderacja wyłączona": "Moderation disabled",
        "Moduł został dodany.": "The module has been added.",
        "Moduł został usunięty.": "The module has been removed.",
        "Moduł został wyeksportowany.": "The module has been exported.",
        "Moduł został zaktualizowany.": "The module has been updated.",
        "Modyfikacja dokumentów": "Document modification",
        "Modyfikuj istniejący wątek": "Modify existing thread",
        "Moje dokumenty": "My documents",
        "Moje pliki": "My files",
        "Monitoruj statystyki .NET": "Monitor .NET statistics",
        "Monitoruj żądania HTTP": "Monitor HTTP requests",
        "Możesz podać nową nazwę pliku.": "You can provide a new file name.",
        "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są synchronizowane z zewnętrzną bazą danych %0.": "The ability to modify this account is limited. Inactive fields are synchronized with the external database %0.",
        "Możliwość wielokrotnego wyboru": "Multiple selection option",
        "Mój tytuł": "My title",
        "multipart/form-data": "multipart/form-data",
        "Na pewno usunąć": "Are you sure you want to delete",
        "Nadawanie uprawnień do dokumentów": "Granting permissions to documents",
        "Nadawanie uprawnień do własnych dokumentów": "Granting permissions to own documents",
        "Nadawanie uprawnień do wszystkich dokumentów": "Granting permissions to all documents",
        "Nadawca": "Sender",
        "Nagłówek": "Header",
        "Nagłówek dokumentu": "Document header",
        "Najstarsza wiadomość": "Oldest message",
        "Narzędzie integruje się z platformą Firebase (powiadomienia push)": "The tool integrates with the Firebase platform (push notifications)",
        "Narzędzie integruje się z platformą SMSAPI.pl": "The tool integrates with the SMSAPI.pl platform",
        "Nazwa aliasu": "Alias name",
        "Nazwa asystenta": "Assistant name",
        "Nazwa filtra": "Filter name",
        "Nazwa formularza": "Form name",
        "Nazwa funkcji": "Function name",
        "Nazwa jest wymagana": "Name is required",
        "Nazwa kolumny": "Column name",
        "Nazwa komponentu": "Component name",
        "Nazwa modelu": "Model name",
        "Nazwa modułu": "Module name",
        "Nazwa musi mieć co najmniej 3 znaki": "The name must be at least 3 characters long",
        "Nazwa musi mieć co najmniej 5 znaków": "The name must be at least 5 characters long",
        "Nazwa narzędzia": "Tool name",
        "Nazwa nie może być dłuższa niż 30 znaków": "The name cannot be longer than 30 characters",
        "Nazwa nie może być dłuższy niż 50 znaków": "The name cannot be longer than 50 characters",
        "Nazwa powiadomienia": "Notification name",
        "Nazwa sekcji w menu": "Menu section name",
        "Nazwa sekcji...": "Section name...",
        "Nazwa typu": "Type name",
        "Nazwa urządzenia": "Device name",
        "Nazwa wątku": "Thread name",
        "Nazwa webhooka": "Webhook name",
        "Nazwa zasobu": "Resource name",
        "Nazwy wartości": "Value names",
        "Nie loguj wybranych": "Do not log selected",
        "Nie masz uprawnień do tego dokumentu": "You do not have permission for this document",
        "Nie masz uprawnień do tego widoku. Stan widoku mógł ulec zmianie, odśwież i sprawdź lub spróbuj ponownie później.": "You do not have permission for this view. The view status may have changed, refresh and check or try again later.",
        "Nie możesz dodać tego samego widgetu dwa razy": "You cannot add the same widget twice",
        "Nie możesz modyfikować profilu zastępowanego użytkownika.": "You cannot modify the profile of the replaced user.",
        "Nie podano wymaganej ilości znaków: %0. (%1)": "The required number of characters has not been provided: %0. (%1)",
        "Nie udało się pobrać listy kolejek": "Failed to retrieve the list of queues",
        "Nie udało się pobrać listy zespołów": "Failed to retrieve the list of teams",
        "Nie udało się pobrać obrazu.": "Failed to retrieve the image.",
        "Nie udało się pobrać ustawień kont użytkowników": "Failed to retrieve user account settings",
        "Nie udało się pobrać widgetu/ów": "Failed to retrieve widget(s)",
        "Nie udało się wygenerować formularza": "Failed to generate the form",
        "Nie udało się wykonać akcji": "Failed to execute the action",
        "Nie udało się załadować widgetu AI.": "Failed to load AI widget.",
        "Nie udało się zapisać filtra": "Failed to save the filter",
        "Nie wybrano żadnego użytkownika.": "No user selected.",
        "Nie wymaga uwierzytelniania": "No authentication required",
        "Nie znaleziono dostępnych widgetów": "No available widgets found",
        "Nie znaleziono filtrów.": "No filters found.",
        "Nie znaleziono formularza.": "No form found.",
        "Nie znaleziono kolumn.": "No columns found.",
        "Nie znaleziono pasującego widgetu": "No matching widget found.",
        "Nie znaleziono pasujących elementów.": "No matching items found.",
        "Nie znaleziono żadnego pola.": "No fields found.",
        "Nie znaleziono żadnych użytkowników.": "No users found.",
        "Nieaktywni": "Inactive",
        "Nieaktywny": "Inactive",
        "niedozwolone rozszerzenie pliku (%0)": "disallowed file extension (%0)",
        "niedozwolony typ pliku (%0)": "disallowed file type (%0)",
        "Niepoprawne wyrażenie regularne": "Invalid regular expression",
        "Nieprawidłowa definicja": "Invalid definition",
        "Nieudane logowania": "Failed logins",
        "Nieznane narzędzie": "Unknown tool",
        "Nieznany model": "Unknown model",
        "Nieznany typ pliku": "Unknown file type",
        "no-store (wyłącza cache)": "no-store (disables cache)",
        "np. (&(objectClass=organizationalUnit))": "e.g., (&(objectClass=organizationalUnit))",
        "np. (&(objectClass=user)": "e.g., (&(objectClass=user))",
        "Numer telefonu": "Phone number",
        "OAuth2 dla Exchange Online": "OAuth2 for Exchange Online",
        "Obiekt %0 został %1": "Object %0 has been %1",
        "Obraz został pomyślnie opisany": "Image has been successfully described",
        "Obraz został wygenerowany.": "Image has been generated.",
        "Obsługiwane formaty plików: txt, md, pdf, docx.": "Supported file formats: txt, md, pdf, docx.",
        "Od": "From",
        "od %0": "from %0",
        "Odblokuj": "Unlock",
        "Odpowiedzi: %0 na %1": "Answers: %0 out of %1",
        "Odpowiedź dowolna": "Any response",
        "Odśwież": "Refresh",
        "Odznacz": "Uncheck",
        "Omnitool": "Omnitool",
        "OpenAI": "OpenAI",
        "Operacje": "Operations",
        "Opis błędu": "Error description",
        "Opis funkcji": "Function description",
        "Opis obrazu": "Image description",
        "Opisz jaki formularz chcesz wygenerować": "Describe what form you want to generate",
        "Optymalizuj rozdzielczość obrazów": "Optimize image resolution",
        "Oraz hasło": "And password",
        "Organizacja została usunięta.": "Organization has been removed.",
        "Ostrzeżenia": "Warnings",
        "Ostrzeżenie": "Warning",
        "Otwórz": "Open",
        "Oznacz": "Mark",
        "Oznacz jako nieprzeczytane": "Mark as unread",
        "Oznacz jako przeczytane": "Mark as read",
        "Oznacz powiadomienia": "Mark notifications",
        "Oznacz wszystkie jako przeczytane": "Mark all as read",
        "Oznacz wybrane jako nieprzeczytane": "Mark selected as unread",
        "Oznacz wybrane jako przeczytane": "Mark selected as read",
        "Paleta kolorów": "Color palette",
        "Parametry funkcji": "Function parameters",
        "Pełny": "Full",
        "Pierwiastkowanie wartości kolumny": "Square rooting column value",
        "Pigułki": "Pills",
        "Piktogram": "Pictogram",
        "Plik audio": "Audio file",
        "Plik binarny": "Binary file",
        "Plik CSS": "CSS file",
        "Plik CSV": "CSV file",
        "Plik DOC": "DOC file",
        "Plik DOCX": "DOCX file",
        "Plik GIF": "GIF file",
        "Plik HTML": "HTML file",
        "Plik JPEG": "JPEG file",
        "Plik JPG": "JPG file",
        "Plik MP4": "MP4 file",
        "Plik PNG": "PNG file",
        "Plik skompresowany": "Compressed file",
        "Plik ZIP": "ZIP file",
        "Plik został dodany.": "File has been added.",
        "Plik został usunięty.": "File has been removed.",
        "Plik:": "File:",
        "Pliki": "Files",
        "Pliki asystenta": "Assistant files",
        "Pliki współdzielone": "Shared files",
        "Pliki: %0": "Files: %0",
        "Po jednym synonimie w wierszu": "One synonym per line",
        "Po wybraniu tej opcji konta użytkowników zostaną usunięte z systemu. Czy chcesz kontynuować?": "By selecting this option, user accounts will be removed from the system. Do you want to continue?",
        "Pobierz": "Download",
        "Początkowa ilość wierszy": "Initial number of rows",
        "Podaj kod weryfikacyjny": "Enter verification code",
        "Podaj nową nazwę": "Enter a new name",
        "Podano adres": "Address provided",
        "Podano nieprawidłowe dane. (%0)": "Invalid data provided. (%0)",
        "Podczas pierszego uruchomienia wymagane jest utworzenie konta głównego administratora.": "Creating a main administrator account is required during the first run.",
        "Podgląd formularza": "Form preview",
        "Podgląd tabeli": "Table preview",
        "Podkreślenie": "Underline",
        "Podziedziałek - Piątek": "Monday - Friday",
        "Pokaż tylko moje dokumenty": "Show only my documents",
        "Pokaż w koncie użytkownika": "Show in user account",
        "Pole \"%0\" jest wymagane. (%1)": "Field \"%0\" is required. (%1)",
        "Pole \"%0\" zawiera niedozwoloną wartość.": "Field \"%0\" contains an invalid value.",
        "Pole \"Email\" jest wymagane.": "The \"Email\" field is required.",
        "Pole \"Nazwa użytkownika\" jest wymagane.": "The \"Username\" field is required.",
        "Pole jest wymagane": "Field is required",
        "Pole może zawierać jedynie cyfry. (%0)": "Field may contain only digits. (%0)",
        "Pole może zawierać jedynie litery i cyfry. (%0)": "Field may contain only letters and digits. (%0)",
        "Pole może zawierać jedynie litery. (%0)": "Field may contain only letters. (%0)",
        "Pole może zawierać tylko ciało metody walidującej dane w postaci natywnego kodu JavaScript.": "Field may contain only the body of the data validation method in the form of native JavaScript code.",
        "Pole nie może zawierać spacji": "Field may not contain spaces",
        "Pole skopiowane": "Field copied",
        "Pole tekstowe": "Text field",
        "Pole typu \"%0\" jest niedozwolone w tym formularzu.": "Field of type \"%0\" is not allowed in this form.",
        "Pole wymaga podania przynajmniej jednego znaku specjalnego.": "Field requires at least one special character.",
        "Pole wymaga podania przynajmniej jednej cyfry.": "Field requires at least one digit.",
        "Pole wymaga podania przynajmniej jednej dużej litery.": "Field requires at least one uppercase letter.",
        "Pole wymaga podania przynajmniej jednej małej litery.": "Field requires at least one lowercase letter.",
        "Polityka prywatności": "Privacy policy",
        "Poprawny": "Correct",
        "Posiada załączniki": "Has attachments",
        "POST: Lista wpisów (dowolne wyszukiwanie)": "POST: List of entries (any search)",
        "POST: Upload załączników": "POST: Upload attachments",
        "POST: Utworzenie wpisu": "POST: Create entry",
        "POST: Zapisanie zmian": "POST: Save changes",
        "Potwierdzenia": "Confirmations",
        "Potwierdzenie włączenia": "Confirmation of activation",
        "Potwierdzenie wylogowania użytkownika": "Confirmation of user logout",
        "Potwierdzenie wyłączenia": "Confirmation of deactivation",
        "Potwierdź": "Confirm",
        "Powiadomienia mailowe": "Email notifications",
        "Powiadomienie testowe zostało wysłane.": "Test notification has been sent.",
        "Powinno się dodać dyrektywę PRIVATE dla treści personalizowanych dla użytkownika, zwłaszcza dla odpowiedzi otrzymywanych po zalogowaniu i z uwzględnieniem uprawnień.": "You should add the PRIVATE directive for content personalized for the user, especially for responses received after logging in and considering permissions.",
        "powrót": "Back",
        "Powyższe obiekty zawierają tylko właściwości i są pozbawione wszystkich metod.": "The above objects contain only properties and are devoid of all methods.",
        "Poziom dostępu do webhooka": "Webhook access level",
        "Poziom dostępu użytkownika": "User access level",
        "Pozostałe": "Others",
        "Pozwól na dodawanie wierszy": "Allow adding rows",
        "pół roku": "Half a year",
        "Predefiniowane tła panelu z aplikacjami": "Predefined panel backgrounds with applications",
        "Predefiniowane zestawy kolorów": "Predefined color schemes",
        "private (tylko cache przeglądarki)": "private (browser cache only)",
        "Prometheus": "Prometheus",
        "Proporcje obrazu": "Image proportions",
        "Proste uprawnienia": "Simple permissions",
        "Próbkowanie": "Sampling",
        "Przebudowanie indeksów": "Rebuilding indexes",
        "Przebudowanie indeksównnOperacja może trwać od kilku sekund do kilku godzin w zależności od rozmiaru indeksów.nnNależy używać tylko w przypadku konieczności zaaplikowania zmian dokonanych w słownikach synonimów lub słów wykluczonych.": "Rebuilding indexes\n\nThe operation may take from a few seconds to several hours depending on the size of the indexes.\n\nUse only if necessary to apply changes made to synonym or excluded word dictionaries.",
        "Przeindeksowanie danych": "Reindexing data",
        "Przeindeksowanie danychnnOperacja może trwać od kilku sekund do kilku godzin w zależności od ilości danych w bazie.nnNależy używać tylko w przypadku zauważenia braku danych w indeksach.": "Reindexing data\n\nThe operation may take from a few seconds to several hours depending on the amount of data in the database.\n\nUse only if you notice a lack of data in the indexes.",
        "Przejdź do konfiguracji, aby włączyć narzędzia": "Go to configuration to enable tools",
        "Przekroczono dozwoloną ilość znaków: %0. (%1)": "Exceeded allowed number of characters: %0. (%1)",
        "przekroczony dozwolony rozmiar pliku (%0 MB)": "exceeded allowed file size (%0 MB)",
        "przeniesiony do kosza": "moved to trash",
        "Przenieś${selected.length > 1 ? ' zaznaczone' : ''}": "Move${selected.length > 1 ? ' selected' : ''}",
        "przeniósł do kosza": "moved to trash",
        "Przepisz limit konta oraz aktualne stawki za 1000 tokenów ze strony OpenAI.": "Rewrite account limit and current rates for 1000 tokens from the OpenAI site.",
        "Przepnij alias": "Pin alias",
        "Przesyłanie plików powiodło się.": "File upload succeeded.",
        "Przetestuj": "Test",
        "Przetestuj poprawność zapisanych kluczy": "Test the correctness of saved keys",
        "Przetestuj ustawienia Sms": "Test SMS settings",
        "Przykład:": "Example:",
        "Przykład: domena\\użytkownik": "Example: domain\\user",
        "Przykład: użytkownik@domena": "Example: user@domain",
        "Przykładowa struktura danych": "Sample data structure",
        "Przykłady": "Examples",
        "Przykłady konfiguracji": "Configuration examples",
        "Przypisanie do zespołu": "Assignment to team",
        "Przypisanie ról": "Role assignment",
        "Przywrócenie": "Restoration",
        "przywrócił z kosza": "restored from trash",
        "przywrócony z kosza": "restored from trash",
        "Przywróć domyślne": "Restore defaults",
        "Przywróć filtr": "Restore filter",
        "public (cache przeglądarki i proxy)": "public (browser and proxy cache)",
        "PUT: Aktualizacja wpisu": "PUT: Update entry",
        "Query string": "Query string",
        "ReCaptcha": "ReCaptcha",
        "Relacyjne": "Relational",
        "Request body": "Request body",
        "RESTful API": "RESTful API",
        "Rodzaj źródła": "Source type",
        "rok": "Year",
        "Rola domyślna": "Default role",
        "Role zespołu": "Team roles",
        "Role zostały przypisane": "Roles have been assigned",
        "Root": "Root",
        "Rozdzielczość": "Resolution",
        "Rozdzielczość wygnerowanego obrazu.": "Resolution of the generated image.",
        "Rozmiar okna popup": "Popup window size",
        "Rozmiar pliku": "File size",
        "Rozmiar podawany w bajtach.": "Size given in bytes.",
        "Rozmieść równomiernie": "Distribute evenly",
        "Scrollowalne okna popup": "Scrollable popup windows",
        "SenderId": "SenderId",
        "Sentry": "Sentry",
        "Server Side Cache": "Server Side Cache",
        "Sheduler": "Scheduler",
        "Skopiuj kod": "Copy code",
        "Słowa wykluczone": "Excluded words",
        "Słowo": "Word",
        "Słowo bazowe": "Base word",
        "Słowo wykluczone": "Excluded word",
        "Słowo zostało dodane.": "Word has been added.",
        "Słowo zostało usunięte.": "Word has been removed.",
        "Słowo zostało zaktualizowane.": "Word has been updated.",
        "Specjalizowane": "Specialized",
        "Specyfikacja": "Specification",
        "Sposób prezentacji zakładek": "Tab presentation method",
        "Standardowa szczegółowość": "Standard detail level",
        "Standardowe uprawnienia": "Standard permissions",
        "Start": "Start",
        "Status modułu": "Module status",
        "Status webhooka": "Webhook status",
        "Status żądania": "Request status",
        "Stopka": "Footer",
        "Strona": "Page",
        "Struktura organizacyjna": "Organizational structure",
        "Studio": "Studio",
        "Styl obrazu": "Image style",
        "Sukces": "Success",
        "Suma danych wejściowych i wyjściowych podana w tokenach.": "Sum of input and output data provided in tokens.",
        "Suma tokenów": "Token sum",
        "Symbol jest wymagany": "Symbol is required",
        "Symbol jest zajęty": "Symbol is taken",
        "Symbol musi mieć co najmniej 3 znaki": "Symbol must be at least 3 characters long",
        "Symbol musi mieć co najmniej 5 znaków": "Symbol must be at least 5 characters long",
        "Symbol nie może być dłuższy niż 10 znaków": "Symbol cannot be longer than 10 characters",
        "Symbol nie może być dłuższy niż 20 znaków": "Symbol cannot be longer than 20 characters",
        "Symbol w adresie URL": "Symbol in URL",
        "Synonimy": "Synonyms",
        "Synonimy zostały dodane.": "Synonyms have been added.",
        "Synonimy zostały usunięte.": "Synonyms have been removed.",
        "Synonimy zostały zaktualizowane.": "Synonyms have been updated.",
        "System": "System",
        "System powiadomień": "Notification system",
        "Szablon modułu": "Module template",
        "Szacowana kwota:": "Estimated amount:",
        "Szczegółowość obrazu": "Image detail",
        "Szczegóły asystenta": "Assistant details",
        "Szczegóły elementu": "Item details",
        "Szczegóły zapytania": "Query details",
        "Szukaj we wszystkich wersjach": "Search all versions",
        "Szukane słowo": "Searched word",
        "Średni": "Average",
        "Ta strona jest chroniona przez reCAPTCHA i Google.": "This page is protected by reCAPTCHA and Google.",
        "Tabela": "Table",
        "Tekst lokalizowany": "Localized text",
        "Ten dashboard nie zawiera jeszcze żadnych widgetów": "This dashboard does not yet contain any widgets",
        "Tenant ID": "Tenant ID",
        "Test konfiguracji": "Configuration test",
        "Test ustawień pomyślnie zakończony.": "Configuration test successful.",
        "Testowy numer telefonu": "Test phone number",
        "Testuj": "Test",
        "Testuj zapytanie": "Test query",
        "Tłumaczenia": "Translations",
        "Token": "Token",
        "Token został usunięty.": "Token has been removed.",
        "Tokeny": "Tokens",
        "Tokeny dostępu": "Access tokens",
        "Tokeny wejściowe": "Input tokens",
        "Tokeny wyjściowe": "Output tokens",
        "Tracing": "Tracing",
        "Trigger": "Trigger",
        "Trwa generowanie formularza": "Form is being generated",
        "Trwa generowanie obrazów": "Images are being generated",
        "Trwa generowanie obrazu": "Image is being generated",
        "Trwa generowanie opisu dla wybranego obrazu": "Generating description for the selected image",
        "Tworzenie dokumentów": "Document creation",
        "Twój token": "Your token",
        "tydzień": "week",
        "Tydzień": "Week",
        "tylko dokumenty wersjonowane": "only versioned documents",
        "Tylko liderzy": "Only leaders",
        "Typ logowania": "Login type",
        "Typ pliku": "File type",
        "Typ właściwy": "Correct type",
        "typu \"%0\" o ID \"%1\"": "type \"%0\" with ID \"%1\"",
        "Udało się zapisać ustawienia widgetów": "Widget settings saved successfully",
        "Układ listy": "List layout",
        "Ukryta": "Hidden",
        "Ukryty": "Hidden",
        "Uprawnienia do dokumentów": "Document permissions",
        "Url": "URL",
        "Ustawienia Firebase": "Firebase settings",
        "Ustawienia sesji": "Session settings",
        "ustawienia widgetu": "widget settings",
        "Ustawienia widgetu": "Widget settings",
        "usunął": "removed",
        "Usunięcie dokumentu": "Document removal",
        "Usunięcie formularzannOperacja jest nieodwracalna. Zostaną utracone wszystkie powiązane dokumenty!nnCzy na pewno chcesz usunąć wybrany formularz?": "Form removal\n\nThis operation is irreversible. All associated documents will be lost!\n\nAre you sure you want to delete the selected form?",
        "Usunięcie indeksunnOperacja jest nieodwracalna. Czy na pewno chcesz usunąć wybrany indeks?": "Index removal\n\nThis operation is irreversible. Are you sure you want to delete the selected index?",
        "usunięty": "deleted",
        "Usuń dział": "Delete section",
        "Usuń grupę": "Delete group",
        "Usuń indeks": "Delete index",
        "Usuń pozycję": "Delete item",
        "Usuń sekcję": "Delete section",
        "Usuń urządzenie": "Delete device",
        "Usuń urządzenie aby odwołać zapamiętane automatyczne logowanie na swoje konto": "Delete device to cancel saved automatic login to your account",
        "Usuń${selected.length > 1 ? ' zaznaczone' : ''}": "Delete${selected.length > 1 ? ' selected' : ''}",
        "Usuwanie dokumentów": "Deleting documents",
        "Usuwanie użytkowników": "Deleting users",
        "Usuwanie własnych dokumentów": "Deleting own documents",
        "Usuwanie wszystkich dokumentów": "Deleting all documents",
        "Utwórz grupy": "Create groups",
        "Utwórz kopię formularza": "Create a copy of the form",
        "Utwórz kopię z": "Create a copy from",
        "Utwórz nowy wątek": "Create a new thread",
        "UWAGA: Brak obsługi TypeScript oraz w kodzie metody nie można robić importów.": "NOTE: TypeScript is not supported, and imports cannot be made in the method code.",
        "Użyte narzędzie": "Used tool",
        "Użytkownik \"%0\" %1 obiekt %2": "User \"%0\" %1 object %2",
        "Użytkownik nie może się zalogować dopóki konto nie jest aktywne.": "User cannot log in until the account is active.",
        "Użytkownik nie należy do żadnego zespołu.": "User does not belong to any team.",
        "Użytkownik został wylogowany.": "User has been logged out.",
        "Użytkownik został zanonimizowany.": "User has been anonymized.",
        "Użyty Model": "Used model",
        "W ciele metody walidującej dostępne są poniższe zmienne:": "The following variables are available in the validation method body:",
        "W każdy dzień tygodnia": "Each day of the week",
        "W zespole od %0": "In the team since %0",
        "W zespole od %0 do %1": "In the team from %0 to %1",
        "Walidacja danych": "Data validation",
        "Walidacja po stronie API": "API-side validation",
        "Walidacja typów MIME": "MIME type validation",
        "Wartości od 0 - 1.0 (0 oznacza 0%, a 1 oznacza 100%)": "Values from 0 - 1.0 (0 means 0%, and 1 means 100%)",
        "Wartość domyślna: (&(objectClass=organizationalUnit))": "Default value: (&(objectClass=organizationalUnit))",
        "Wartość domyślna: (&(objectClass=user)": "Default value: (&(objectClass=user)",
        "Warunki korzystania z usług Google": "Google Terms of Service",
        "Wątek": "Thread",
        "Wątek główny": "Main thread",
        "Wątek główny jest kontynuowany automatycznie przez asystenta.": "The main thread is automatically continued by the assistant.",
        "Wczytywanie danych...": "Loading data...",
        "Webhook": "Webhook",
        "Webhook został dodany.": "Webhook has been added.",
        "Webhook został usunięty.": "Webhook has been removed.",
        "Webhook został zaktualizowany.": "Webhook has been updated.",
        "Webhooki": "Webhooks",
        "Weekend": "Weekend",
        "Wersja API": "API version",
        "wersji zaakceptowanej": "approved version",
        "Wersjonowanie": "Versioning",
        "Wersjonowanie dokumentów włączone": "Document versioning enabled",
        "Wersjonowanie dokumentów wyłączone": "Document versioning disabled",
        "Wersjonowanie można włączyć tylko podczas dodawania nowego formularza.": "Versioning can only be enabled when adding a new form.",
        "Widgety": "Widgets",
        "Widoczność modułu w menu": "Module visibility in the menu",
        "Widoczność sekcji w menu modułu": "Section visibility in the module menu",
        "Widok ustawień": "Settings view",
        "Wiersz": "Row",
        "Więcej": "More",
        "więcej...": "more...",
        "Właściwości szablonu": "Template properties",
        "Włącz": "Enable",
        "Włącz walidację po stronie API": "Enable API-side validation",
        "Włącza bardzo restrykcyjne sprawdzanie typów MIME dla wysyłanych plików. W przypadku zgłaszania błędów dla plików, których zawartości jesteśmy pewni, należy tą opcję pozostawić nieaktywną.": "Enables very strict MIME type checking for uploaded files. If errors are reported for files whose contents are known, this option should be left disabled.",
        "Włączenie moderacji danych wejściowych jest zalecane.": "Enabling input data moderation is recommended.",
        "Włączony": "Enabled",
        "Wpis został zaakceptowany.": "Entry has been accepted.",
        "Wpisz adresy po przecinku.": "Enter addresses separated by commas.",
        "Wpisz wiadomość": "Enter message",
        "Wprowadzamy URL do seriwsów które chemy wykluczyć. Dane mogą być odzielone przecinkiem lub być w nowej linii": "We input URLs to services that we want to exclude. Data may be separated by commas or in new lines.",
        "Wskazuje, jak długo odpowiedź pozostaje aktualna w pamięci serwera Redis.": "Indicates how long the response remains valid in Redis server memory.",
        "Wstępne filtrowanie danych": "Preliminary data filtering",
        "Wszystkie dane osobowe użytkownika znajdujące się w systemie zostaną usunięte!nnCzy na pewno chcesz wykonać akcję??": "All personal data of the user in the system will be removed!\n\nAre you sure you want to perform this action?",
        "Wszystko": "All",
        "Wybierz formularz zawierający dodatkowe pola dla kont użytkownków": "Select a form with additional fields for user accounts",
        "Wybierz katalog do którego chcesz przenieść wybrane elementy.": "Select the directory to which you want to move the selected items.",
        "wybierz model": "select model",
        "Wybierz plik.": "Select file.",
        "Wybierz shortcode": "Select shortcode",
        "Wybierz użytkownika": "Select user",
        "Wybierz użytkowników dla których chcesz wykonać akcje": "Select users for whom you want to perform actions",
        "Wybierz wątek w którym chcesz prowadzić konwersację.": "Select the thread in which you want to conduct the conversation.",
        "Wybór+": "Selection+",
        "Wybrane dokumenty": "Selected documents",
        "wybrane elementy?": "selected items?",
        "Wybrane: %0": "Selected: %0",
        "wybranego elementu": "selected item",
        "Wybrano %0 użytkowników.": "%0 users selected.",
        "Wybrano jednego użytkownika.": "One user selected.",
        "Wybrany element został usunięty.": "Selected item has been removed.",
        "wybrany element?": "selected item?",
        "wybranych elementów": "selected items",
        "Wybróbuj możliwości AI": "Try AI capabilities",
        "Wygenerowana odpowiedź przez model AI.": "Response generated by the AI model.",
        "Wygenerowane obrazy": "Generated images",
        "Wygeneruj metadane dla przesyłanych obrazów": "Generate metadata for uploaded images",
        "Wyklucz logowanie zależności": "Exclude dependency logging",
        "Wykorzystane": "Used",
        "Wykresy": "Charts",
        "Wyloguj zalogowanego obecnie użytkownika": "Log out the currently logged-in user",
        "Wyłaczone": "Disabled",
        "Wyłączony": "Disabled",
        "Wymagane jest wybranie formularza": "Selecting a form is required",
        "Wymagane jest wybranie kategorii": "Selecting a category is required",
        "Wymagane jest wybranie komponentu": "Selecting a component is required",
        "Wymagane jest wybranie modułu": "Selecting a module is required",
        "Wymagane znaki": "Required characters",
        "Wynik testu": "Test result",
        "Wyniki": "Results",
        "Wyniki testu zawszę będą ograniczone do maksymalnie 10 pozycji.": "Test results will always be limited to a maximum of 10 items.",
        "Wyrażenie w postaci \"/pattern/flag\" nie jest obsługiwane, użyj same wartości pattern": "Expression in the form \"/pattern/flag\" is not supported, use only the pattern value",
        "Wysoka szczegółowość": "High detail",
        "Wystąpił błąd podczas ładowania obrazu. Spróbuj ponownie.": "An error occurred while loading the image. Please try again.",
        "Wystąpił błąd podczas próby exportu użytkowników.": "An error occurred while attempting to export users.",
        "Wystąpił błąd podczas próby importu definicji modułu.": "An error occurred while attempting to import the module definition.",
        "Wystąpił błąd podczas próby importu użytkowników.": "An error occurred while attempting to import users.",
        "Wystąpił problem podczas przesyłania obrazu.": "An issue occurred while uploading the image.",
        "Wysyłaj dane o użytkowniku": "Send user data",
        "Wyszukaj (Ctrl+Q)": "Search (Ctrl+Q)",
        "wyszukaj użytkownika...": "search for a user...",
        "Wyszukaj widget": "Search widget",
        "Wyszukiwanie": "Search",
        "Wyszukiwanie plików": "File search",
        "Wyszukiwanie plików umożliwia asystentowi korzystanie z wiedzy z plików. Po przesłaniu pliku asystent automatycznie decyduje, kiedy pobrać treść, na podstawie żądań użytkownika.": "File search allows the assistant to use knowledge from files. After uploading a file, the assistant automatically decides when to fetch the content based on user requests.",
        "Wyślij kod": "Send code",
        "Wyświetlanie dokumentów": "Displaying documents",
        "Wywołanie funkcji": "Function call",
        "Z uprawnieniami": "With permissions",
        "Z uprawnieniami do modułu": "With module permissions",
        "Zaawansowane uprawnienia": "Advanced permissions",
        "Zablokuj": "Block",
        "Zachowaj oryginalny format obrazów": "Preserve original image format",
        "Zacznij pisać Form lub Entry aby uruchomić podpowiadanie.": "Start typing Form or Entry to activate auto-suggestions.",
        "Zadanie przebudowania indeksów zostało rozpoczęte.": "Index rebuilding task has started.",
        "Zadanie przeindeksowania danych zostało rozpoczęte.": "Data reindexing task has started.",
        "Zadanie zostało włączone": "Task has been enabled",
        "Zadanie zostało wyłączone": "Task has been disabled",
        "Zagrożenie": "Threat",
        "Zaimportowano definicję modułu.": "Module definition has been imported.",
        "Zaimportowano listę użytkowników.": "User list has been imported.",
        "Zakładki": "Bookmarks",
        "Zakończ edycje": "Finish editing",
        "Zakończ sesję": "End session",
        "Zakończ sesję aby wylogować użytkownika": "End session to log out the user",
        "Zakres": "Scope",
        "Zaktualizuj formularz": "Update form",
        "Zależna od kontrolki": "Dependent on control",
        "Zalogowany": "Logged in",
        "Zapamiętane urządzenia": "Remembered devices",
        "Zapisywanie obrazów zakończone.": "Image saving complete.",
        "Zapisz i zamknij": "Save and close",
        "Zapisz ustawienia": "Save settings",
        "Zapisz ustawienia widgetów": "Save widget settings",
        "Zapisz widget": "Save widget",
        "Zapisz wybrane": "Save selected",
        "Zapytanie SQL do pobrania po ID": "SQL query to fetch by ID",
        "Zapytanie SQL do wyszukiwania": "SQL query for searching",
        "Zastosuj zmiany w aplikacji": "Apply changes in the application",
        "Zaufane adresy IP": "Trusted IP addresses",
        "Zaznacz": "Select",
        "Zdjęcia": "Photos",
        "Zmiana ustawień może doprowadzić do utraty dostępu do panelu!nnCzy na pewno chcesz zapisać zmiany??": "Changing settings may lead to loss of access to the panel!\n\nAre you sure you want to save the changes?",
        "Zmiany ustawień monitoringu wymagają restartu aplikacji.": "Changes to monitoring settings require an application restart.",
        "Zmiany zaczną obowiązywać dopiero po przebudowaniu indeksów.": "Changes will only take effect after index rebuilding.",
        "Zmiany zostały zapisane.": "Changes have been saved.",
        "Zmiany zostaną zastosowane w aplikacji w przeciągu kikunastu sekund": "Changes will be applied in the application within a few seconds",
        "Zmieniono ustawienia widgetu": "Widget settings have been changed",
        "Zmień widok": "Change view",
        "zmodyfikował": "modified",
        "zmodyfikowany": "modified",
        "Znaki specialne": "Special characters",
        "Zobacz wszystkie zastępstwa": "View all substitutions",
        "Został przywrócony oryginalny harmonogram": "The original schedule has been restored",
        "został wysłany": "has been sent",
        "Zrestartuj aplikacje": "Restart the application",
        "Źródła LDAP": "LDAP sources",
        "Źródło aktywne": "Active source",
        "Źródło LDAP zostało dodane.": "LDAP source has been added.",
        "Źródło LDAP zostało zaktualizowane.": "LDAP source has been updated.",
        "Żródło danych": "Data source",
        "Ładowanie elementów...": "Loading items...",
        "Nie znaleziono żadnych elementów": "No items found",
        "Język": "Language"
    }
};
