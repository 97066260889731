<template>
    <component :is="tag" class="dropdown-item" v-bind="{...$props, ...$attrs}" ref="button" @click.stop="onClick">
        <slot name="default"></slot>
        <confirmation v-if="loaded && message" :message="props.message" :target="button" :value="item" @confirm="onConfirm" />
    </component>
</template>

<script lang="ts" setup>
import { ref, inject, onMounted, type ComputedRef } from 'vue';

export interface Props
{
    message?: string;
    tag?: string;
}

defineOptions({
    name: 'list-view-button',
    inheritAttrs: false
});

const props = defineProps({
  "message": { default: null },
  "tag": { default: 'button' }
});

const button = ref<any>(null);
const item = inject<ComputedRef<any>>('item');
const dropdownRef = inject<any>('dropdownRef');
const loaded = ref(false);
const emit = defineEmits(["confirm"]);

onMounted(() =>
{
    loaded.value = true;
});

const onConfirm = (item: any): any =>
{
    emit('confirm', item);
};

const onClick = (): void =>
{
    if (!props.message)
        dropdownRef.value.hide();
};
</script>
