<script setup lang="ts">
import { ref, computed, inject, onMounted } from 'vue';

defineOptions({
    name: 'ideo-dropdown-item-button'
});

const props = defineProps({
  "tag": { default: 'button' },
  "active": { type: Boolean, default: false },
  "activeClass": { default: 'active' },
  "disabled": { type: Boolean, default: false },
  "variant": { default: null },
  "preventClose": { type: Boolean, default: false },
  "message": { default: null },
  "messagePlacement": { default: 'right' },
  "value": { default: null }
});

const emit = defineEmits(["confirm"]);

const hideDropdown = inject<any>('hideDropdown');
const buttonRef = ref<any>(null);
const loaded = ref(false);

const customClasses = computed<Record<string, boolean>>(() => ({
    'dropdown-item': true,
    [props.activeClass]: props.active,
    [`text-${props.variant}`]: props.variant != null
}));

onMounted(() =>
{
    loaded.value = true;
});

const onConfirm = (item: any): void =>
{
    emit('confirm', item);
};

const onClick = (): void =>
{
    if (props.preventClose || props.message)
        return;

    hideDropdown();
};
</script>

<template>
    <component :is="tag" type="button" ref="buttonRef" :class="customClasses" :disabled="disabled" :data-dd="$.uid" @click="onClick">
        <slot name="default"></slot>
        <confirmation v-if="loaded && message" :message="message" :target="buttonRef" :value="value" @confirm="onConfirm" :placement="messagePlacement" />
    </component>
</template>
