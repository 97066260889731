<script setup lang="ts">
// TODO: Improve types (don't use "any")
import TreeSelect from 'primevue/treeselect';
import { useLocalization } from '@/plugins/localization';

const { $t } = useLocalization();

defineOptions({
    name: 'ideo-form-tree-select',
    components: {
        'tree-select': TreeSelect
    }
});

const model = defineModel<number>();

defineProps({
  "options": null,
  "fluid": { type: Boolean, default: true },
  "placeholder": { default: '' }
});

const handleChange = (value: Record<string, boolean>) =>
{
    model.value = parseInt(Object.keys(value)[0]);
};
</script>

<template>
    <tree-select
        :model-value="{[model]: true}"
        :fluid="fluid"
        :options="options"
        :placeholder="placeholder || $t('[[[Wybierz opcję]]]')"
        @change="handleChange"
    />
</template>

<style lang="scss">
:root {
    --p-border-radius-md: /* TODO */;
    --p-treeselect-background: var(--bs-tertiary-bg);
    --p-treeselect-border-color: var(--bs-border-color);
    --p-treeselect-border-radius: var(--bs-border-radius);
    --p-treeselect-chip-border-radius: /* TODO */;
    --p-treeselect-color: /* TODO */;
    --p-treeselect-disabled-background: /* TODO */;
    --p-treeselect-disabled-color: /* TODO */;
    --p-treeselect-dropdown-color: /* TODO */;
    --p-treeselect-dropdown-width: 32px;
    --p-treeselect-empty-message-padding: /* TODO */;
    --p-treeselect-filled-background: /* TODO */;
    --p-treeselect-filled-focus-background: /* TODO */;
    --p-treeselect-focus-border-color: var(--bs-border-color);
    --p-treeselect-focus-ring-color: /* TODO */;
    --p-treeselect-focus-ring-offset: /* TODO */;
    --p-treeselect-focus-ring-style: /* TODO */;
    --p-treeselect-focus-ring-width: /* TODO */;
    --p-treeselect-focus-ring-shadow: /* TODO */;
    --p-treeselect-hover-border-color: var(--bs-border-color);
    --p-treeselect-invalid-border-color: var(--bs-danger);
    --p-treeselect-overlay-background: var(--bs-tertiary-bg);
    --p-treeselect-overlay-border-color: var(--bs-border-color);
    --p-treeselect-overlay-border-radius: var(--bs-border-radius);
    --p-treeselect-overlay-color: /* TODO */;
    --p-treeselect-overlay-shadow: var(--bs-box-shadow);
    --p-treeselect-padding-x: 0.75rem;
    --p-treeselect-padding-y: 0.375rem;
    --p-treeselect-placeholder-color: /* TODO */;
    --p-treeselect-shadow: /* TODO */;
    --p-treeselect-transition-duration: 0;
    --p-treeselect-tree-padding: 4px;
}
</style>
