<script lang="ts" setup>
const query = defineModel<string>('query');
const props = defineProps({
  "placeholder": null,
  "count": null,
  "total": null
});

function clear()
{
    query.value = '';
}
</script>

<template>
    <div class="combobox-container">
        <ideo-input-group class="border-bottom">
            <ideo-form-input v-model="query" type="text" name="search" :placeholder="props.placeholder" class="valid py-3 border-0"></ideo-form-input>
            <template #append>
                <ideo-button variant="link" icon="fas fa-fw fa-times" @click="clear" v-if="query?.length > 0"></ideo-button>
            </template>
        </ideo-input-group>
        <slot name="header"></slot>
        <div class="filters scroll py-1">
            <slot name="default"></slot>
        </div>
        <slot name="footer"></slot>
        <div class="d-flex justify-content-end align-items-center border-top p-2">
            <div>{{ $t('[[[%0 z %1|||{0}|||{1}]]]', props.count.toString(), props.total.toString()) }}</div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.combobox-container {
    :deep(.form-control) {
        background-color: transparent;
    }
    .filters {
        max-height: 295px;

        :deep(fieldset) {
            padding: 0;
        }
        :deep(.form-check) {
            margin: 0 !important;
            padding: 0 8px 0 29px;
            border-left: 3px solid transparent;

            &:hover {
                background-color: var(--bs-tertiary-bg);
                border-left-color: var(--bs-primary)
            }

            input {
                margin-top: 12px;
            }
            label {
                margin: 0 !important;
                padding: 8px 0 8px 4px;
                display: block;
                cursor: pointer;
            }
        }
    }
}
</style>
