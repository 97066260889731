// Blueprints
import { Blueprint } from './blueprints/Blueprint';

// Managers
import { EventManager } from './managers/EventManager';
import { SchemaManager } from './managers/SchemaManager';
import { DimensionsManager } from './managers/DimensionsManager';
import { ClipboardManager } from './managers/ClipboardManager';
import { LayoutManager } from './managers/LayoutManager';

// Services
import { StateStore } from './services/StateStore';
import { BlueprintFactory } from './services/BlueprintFactory';

// --------------------------------------------------

export interface ContentBuilderContract
{
    readonly events: EventManager;
    readonly schema: SchemaManager;
    readonly dimensions: DimensionsManager;
    readonly layout: LayoutManager;
    readonly clipboard: ClipboardManager;

    design: boolean;
    designMode(): boolean;

    internal: boolean;
    internalMode(): boolean;

    readonly blueprintId: number;
    readonly blueprint: Blueprint;
    getBlueprint(): Blueprint;

    update(): void
}

export abstract class ContentBuilder implements ContentBuilderContract
{
    protected state: StateStore;
    protected blueprintFactory: () => Blueprint;

    protected eventManager: EventManager;
    protected schemaManager: SchemaManager;
    protected dimensionsManager: DimensionsManager;
    protected layoutManager: LayoutManager;
    protected clipboardManager: ClipboardManager;

    public constructor(blueprintFactory: BlueprintFactory)
    {
        const state = new StateStore();
        const eventManager = new EventManager();
        const schemaManager = new SchemaManager(
            blueprintFactory,
            eventManager
        );
        const dimensionsManager = new DimensionsManager(
            schemaManager
        );
        const layoutManager = new LayoutManager(
            blueprintFactory,
            eventManager,
            schemaManager
        );
        const clipboardManager = new ClipboardManager(
            eventManager,
            schemaManager,
            layoutManager
        );

        this.state = state;
        this.eventManager = eventManager;
        this.schemaManager = schemaManager;
        this.dimensionsManager = dimensionsManager;
        this.layoutManager = layoutManager;
        this.clipboardManager = clipboardManager;
    }

    // --------------------------------------------------

    public get events(): EventManager
    {
        return this.eventManager;
    }

    public get schema(): SchemaManager
    {
        return this.schemaManager;
    }

    public get dimensions(): DimensionsManager
    {
        return this.dimensionsManager;
    }

    public get layout(): LayoutManager
    {
        return this.layoutManager;
    }

    public get clipboard(): ClipboardManager
    {
        return this.clipboardManager;
    }

    // --------------------------------------------------

    public get design(): boolean
    {
        return this.state.design;
    }

    public set design(value: boolean)
    {
        this.state.design = value;
    }

    public designMode(): boolean
    {
        return this.design;
    }

    // --------------------------------------------------

    public get internal(): boolean
    {
        return this.state.internal;
    }

    public set internal(value: boolean)
    {
        this.state.internal = value;
    }

    public internalMode(): boolean
    {
        return this.internal || this.design;
    }

    // --------------------------------------------------

    public get blueprint(): Blueprint
    {
        return this.schema.getBlueprint();
    }

    public set blueprint(blueprint: Blueprint)
    {
        this.schema.setBlueprint(blueprint);
    }

    public getBlueprint(): Blueprint
    {
        return this.blueprint;
    }

    public setBlueprint(blueprint: () => Blueprint): void
    {
        this.blueprintFactory = blueprint;
    }

    public get blueprintId(): number
    {
        return this.schema.id;
    }

    public setBlueprintIdentity(callback: () => number): void
    {
        this.schema.setIdentity(callback);
    }

    // --------------------------------------------------

    public update(): void
    {
        this.blueprint = this.blueprintFactory();
    }
}
