import { Blueprint } from './Blueprint';
import { ValidationErrors } from '../types/ValidationErrors';
import { instanceOfAggregateBlueprint } from './AggregateBlueprint';

export interface ValidatableBlueprint
{
    errors: ValidationErrors;
    validate(): Record<string, ValidationErrors>;
}

export const instanceOfValidatableBlueprint = (object: any): object is ValidatableBlueprint =>
{
    return object && 'validate' in object && typeof object.validate === 'function';
};

export const validateBlueprints = (components: Blueprint[]): Record<string, ValidationErrors> =>
{
    let errors = {};

    components.forEach(component =>
    {
        if (instanceOfValidatableBlueprint(component))
            errors = { ...errors, ...component.validate() };
    });

    return errors;
};

export const setValidationErrors = (blueprint: Blueprint, errors: Record<string, string[]>): void =>
{
    if (instanceOfValidatableBlueprint(blueprint))
    {
        const items = {};

        Object.entries(errors).forEach(([key, messages]) =>
        {
            const name = `${blueprint.name.toLowerCase()}.`;

            if (key.startsWith(name))
            {
                items[key.substring(name.length)] = messages;
            }
        });

        blueprint.errors = items;
    }

    if (instanceOfAggregateBlueprint(blueprint))
    {
        blueprint.components.forEach(component =>
        {
            setValidationErrors(component, errors);
        });
    }
};
