<template>
    <div :class="wrapperClasses">
        <div :class="navWrapperClasses" v-if="!flag(end)">
            <ul :class="navClasses">
                <portal-target :name="`tabs-nav-${$.uid}`" multiple />
                <li class="nav-item" v-if="'tabs-end' in $slots">
                    <slot name="tabs-end"></slot>
                </li>
            </ul>
        </div>
        <div :class="contentClasses">
            <slot name="default"></slot>
        </div>
        <div :class="navWrapperClasses" v-if="flag(end)">
            <ul :class="navClasses" :id="`tabs-nav-${$.uid}`"></ul>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { normalizeClasses } from '@/helpers/Utils';
import IdeoTab from './IdeoTab.vue';

@Options({
    name: 'ideo-tabs'
})
export default class IdeoTabs extends Vue
{
    public tabs: IdeoTab[] = [];
    public activeTabIndex: number = 0;

    @Prop({ default: 0 })
    public modelValue: number;

    @Prop({ default: false })
    public linear: boolean;

    @Prop({ default: () => ({}) })
    public activeNavItemClass: Record<string, boolean> | string[] | string;

    @Prop({ default: () => ({}) })
    public activeTabClass: Record<string, boolean> | string[] | string;

    @Prop({ default: null, validator: (value: string) => [null, 'start', 'center', 'end'].includes(value) })
    public align: string;

    @Prop({ default: false })
    public card: boolean;

    @Prop({ default: () => ({}) })
    public contentClass: Record<string, boolean> | string[] | string;

    @Prop({ default: false })
    public end: boolean;

    @Prop({ default: false })
    public fill: boolean;

    @Prop({ default: false })
    public justified: boolean;

    @Prop({ default: () => ({}) })
    public navClass: Record<string, boolean> | string[] | string;

    @Prop({ default: () => ({}) })
    public navWrapperClass: Record<string, boolean> | string[] | string;

    @Prop({ default: false })
    public pills: boolean;

    @Prop({ default: false })
    public underline: boolean;

    @Prop({ default: false })
    public vertical: boolean;

    public flag(value: any): boolean
    {
        return value !== false;
    }

    public get wrapperClasses(): Record<string, boolean>
    {
        const vertical = this.flag(this.vertical);
        const card = this.flag(this.card) && !vertical;

        return {
            'card': card,
            'row': vertical
        };
    }

    public get navWrapperClasses(): Record<string, boolean>
    {
        const vertical = this.flag(this.vertical);
        const card = this.flag(this.card) && !vertical;
        const end = this.flag(this.end);
        const top = !end;

        return {
            'card-header': card && top,
            'card-footer': card && end,
            'col-auto d-flex align-items-stretch': vertical,
            ...normalizeClasses(this.navWrapperClass)
        };
    }

    public get navClasses(): Record<string, boolean>
    {
        const vertical = this.flag(this.vertical);
        const card = this.flag(this.card) && !vertical;
        const underline = this.flag(this.underline);
        const pills = this.flag(this.pills) && !underline;
        const linear = this.flag(this.linear);
        const tabs = !pills && !underline && !linear;
        const end = this.flag(this.end);
        const top = !end;
        const fill = this.flag(this.fill);
        const justified = this.flag(this.justified);

        return {
            'nav': true,
            'flex-column': vertical,
            'nav-underline': underline,
            'nav-pills': pills,
            'nav-tabs': tabs && !vertical,
            'nav-tabs-end': tabs && end && !vertical,
            'card-header-tabs': tabs && card && top,
            'card-footer-tabs': tabs && card && end,
            'card-header-pills': pills && card && top,
            'card-footer-pills': pills && card && end,
            'card-header-underline': underline && card && top,
            'card-footer-underline': underline && card && end,
            'nav-fill': fill,
            'nav-justified': justified,
            'justify-content-center': this.align == 'center',
            'justify-content-end': this.align == 'end',
            'tabs-linear': linear,
            ...normalizeClasses(this.navClass)
        };
    }

    public get contentClasses(): Record<string, boolean>
    {
        const vertical = this.flag(this.vertical);
        const card = this.flag(this.card) && !vertical;

        return {
            'card-body': card,
            'col': vertical,
            ...normalizeClasses(this.contentClass)
        };
    }

    public registerTab(tab: IdeoTab): void
    {
        if (!this.tabs.includes(tab))
        {
            this.tabs.push(tab);

            if (tab.active !== false)
            {
                this.setActiveTab(tab);
            }
        }
    }

    public unregisterTab(tab: IdeoTab): void
    {
        if (this.tabs.includes(tab))
        {
            this.tabs = this.tabs.filter(p => p != tab);

            if (this.tabs.length > 0)
            {
                this.setActiveTab(this.tabs[0]);
            }
        }
    }

    public tabIndex(tab: IdeoTab): number
    {
        return this.tabs.indexOf(tab);
    }

    public tabActive(tab: IdeoTab): boolean
    {
        return this.tabIndex(tab) == this.activeTabIndex;
    }

    @Watch('modelValue', { immediate: true })
    public onModelValueChange(value: number, old: number): void
    {
        if (value != old)
        {
            this.activeTabIndex = value;
        }
    }

    @Emit('update:modelValue')
    public setActiveTab(tab: IdeoTab): number
    {
        this.activeTabIndex = this.tabIndex(tab);

        return this.activeTabIndex;
    }
}
</script>

<style scoped>
ul.nav:not(:has(> li)) {
    display: none;
}
</style>

<style lang="scss">
.tabs-linear {
    gap: 1rem;

    li.nav-item {
        .nav-link {
            text-transform: none;
            text-align: left;
            color: var(--bs-gray-500);
            border-bottom: 4px solid var(--bs-gray-500);
            padding-left: 0;
            padding-right: 0;

            &.active {
                color: var(--bs-primary);
                border-color: var(--bs-primary);
            }
            &:not(.active) {
                font-weight: 400 !important;
            }
        }

        &[data-active="true"] {
            .nav-link {
                border-color: var(--bs-primary);
            }
        }
    }
}
</style>
