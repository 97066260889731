import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { Entry, entry, instanceOfEntry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasAffix } from '@/components/builder/form/traits/HasAffix';
import { HasFilter } from '@/components/builder/form/traits/HasFilter';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { HasPlaceholder } from '@/components/builder/form/traits/HasPlaceholder';
import { AffixValue } from '@/components/builder/form/types/AffixValue';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';

export const Definition: BlueprintDefinition = {
    type: 'phone',
    name: '[[[Telefon]]]',
    icon: 'fa-phone-alt',
    group: 'special',
    position: 5
};

export class PhoneEntry extends ValidEntry<string>
{
    public type: string = Definition.type;
    public data: string = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }
    }

    public async collect(blueprint: PhoneContract, form: FormBuilderContract, preprocess: ProcessCallback): Promise<Entry>
    {
        const result = await preprocess(blueprint, this, form.blueprintId, form.entryId);

        return entry({
            type: this.type,
            data: this.data ?? form.expressions.executeExpression(blueprint.defaultValue),
            ...(result ?? {})
        });
    }

    public validate(blueprint: PhoneContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        const value = this.data ?? form.expressions.executeExpression(blueprint.defaultValue);

        if (!form.expressions.readonly(blueprint, true) && form.expressions.visible(blueprint, true))
        {
            if (form.expressions.required(blueprint) && (value == null || value.length == 0))
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${form.localization.translate(blueprint.label)}]]]`];
            }
            else if (value && this.hasValidPattern(blueprint) && !this.getPattern(blueprint).test(value))
            {
                this.errors.value = ["[[[Podana wartość jest nieprawidłowa.]]]"];
            }
            else if (form.expressions.customError(blueprint))
            {
                this.errors.custom = [form.expressions.customErrorMessage(blueprint, form)];
            }
        }

        return this.valid();
    }

    public getPattern(blueprint: PhoneContract): RegExp
    {
        return new RegExp(`^${blueprint.pattern}$`);
    }

    public hasValidPattern(blueprint: PhoneContract): boolean
    {
        try
        {
            if (blueprint.pattern)
            {
                this.getPattern(blueprint);

                return true;
            }
        }
        catch (e) { /**/ }

        return false;
    }
}

export const instanceOfPhoneEntry = (object: any): object is PhoneEntry =>
{
    return instanceOfEntry(object) && 'type' in object && object.type === Definition.type;
};

export interface PhoneContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasPlaceholder, HasAffix, HasHelp, HasWidth, HasFilter
{
    defaultValue: string;
    pattern: string;
}

export class PhoneType implements PhoneContract, ValidatableBlueprint, EntryFactory<PhoneEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public placeholder: Record<string, string>;
    public defaultValue: string;
    public pattern: string;
    public affix: AffixValue;
    public help: Record<string, string>;
    public minWidth: number;
    public width: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: Record<string, string>;
    public errors: ValidationErrors;
    public showFilter: boolean;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = { 'pl-PL': 'Telefon' };
        this.showLabel = true;
        this.placeholder = { 'pl-PL': '+__ _________' };
        this.defaultValue = '';
        this.pattern = '(\\+[0-9]{1,3} )?[0-9]{4,14}';
        this.help = {};
        this.affix = { prepend: '', append: '' };
        this.minWidth = 1;
        this.width = 0;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = {};
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
        this.showFilter = false;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public createEntry(data: any): PhoneEntry
    {
        return new PhoneEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        const isNotPCRE = /^\/.+\/[gimuy]*$|^[^/]+\/[gimuy]*$|^\/[^/]*$/;

        if (isNotPCRE.test(this.pattern))
        {
            this.errors.validationRule = ['[[[Wyrażenie w postaci "/pattern/flag" nie jest obsługiwane, użyj same wartości pattern]]]'];
        }
        else
        {
            try { new RegExp(this.pattern); }
            catch (e) { this.errors.pattern = ['[[[Niepoprawne wyrażenie regularne]]]']; }
        }

        return {
            [this.name]: this.errors
        };
    }
}
