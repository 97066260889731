import { Blueprint } from "../../base/blueprints/Blueprint";

export interface EntryFactory<T> extends Blueprint
{
    createEntry(data: any): T;
}

export const instanceOfEntryFactory = (object: any): object is EntryFactory<any> =>
{
    return object && 'createEntry' in object && typeof object.createEntry === 'function';
};
