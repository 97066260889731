<template>
    <div id="omnibox" class="d-flex align-items-stretch flex-fill">
        <div class="wrap" :class="{'focused': focused}">
            <button type="button" class="navbar-toggler ms-auto" @click="open">
                <i class="far fa-fw fa-magnifying-glass"></i>
            </button>
            <!-- <div class="input">
                <input v-model="search" type="text" class="form-control" :placeholder="$t('[[[Wyszukaj (Ctrl+Q)]]]')" ref="input" @focus="open" @input="onInput">
                <i class="icon fa-solid fa-magnifying-glass"></i>
                <i class="clear fa-solid fa-circle-xmark text-danger pointer" @click="clearAll" v-if="(search.length > 0)"></i>
            </div> -->
            <div class="popup" v-if="visible">
                <div class="results shadow scroll bg-body p-2 pb-0 rounded-2">
                    <div class="d-flex justify-content-center py-5 mb-2" v-if="filteredPaths.length == 0 && !hasResults && loading">
                        <loader></loader>
                    </div>
                    <div class="d-flex justify-content-center py-5 mb-2" v-if="filteredPaths.length == 0 && !hasResults && !loading">
                        {{ $t('[[[Nie znaleziono żadnych wyników.]]]') }}
                    </div>
                    <div class="list-group mb-2" v-if="filteredPaths.length > 0">
                        <router-link
                            class="list-group-item list-group-item-action pointer d-flex align-items-center"
                            v-for="(item, i) in filteredPaths"
                            :key="i"
                            :to="item.value"
                            @click="close"
                        >
                            <i class="fa fa-fw me-3" :class="item.icon"></i>
                            <span v-html="highlight(item.text)"></span>
                        </router-link>
                    </div>
                    <div class="row gx-2 mt-0" v-if="hasResults">
                        <div class="col-12 col-md-4">
                            <div class="alert alert-primary px-1 pt-2 pb-0 mb-2">
                                <ideo-form-checkbox v-model="onlyMine" :disabled="loading">
                                    {{ $t('[[[Pokaż tylko moje dokumenty]]]') }}<br>
                                    <small>{{ $t('[[[dodane i edytowane przeze mnie]]]') }}</small>
                                </ideo-form-checkbox>
                                <ideo-form-checkbox v-model="allVersions" :disabled="loading">
                                    {{ $t('[[[Szukaj we wszystkich wersjach]]]') }}<br>
                                    <small>{{ $t('[[[tylko dokumenty wersjonowane]]]') }}</small>
                                </ideo-form-checkbox>
                            </div>
                            <ideo-tree class="alert alert-secondary ps-1 pe-2 pt-1 mb-2" :pages="types" :expanded="expandedTypes" v-if="types.length > 0">
                                <template #toggle>&nbsp;</template>
                                <template #icon>&nbsp;</template>
                                <template #default="{node}">
                                    <template v-if="node.resource.count > 0">
                                        <span class="me-auto">
                                            <ideo-form-checkbox class="ms-n2 mb-n2" v-model="selectedTypes" :value="node.resource.key" :disabled="loading">
                                                {{ node.resource.name }}
                                            </ideo-form-checkbox>
                                        </span>
                                        <span class="badge rounded-pill text-bg-secondary text-white ms-2">
                                            {{ node.resource.count }}
                                        </span>
                                    </template>
                                    <span class="fw-bold" v-else>
                                        {{ node.resource.name }}
                                    </span>
                                </template>
                            </ideo-tree>
                            <ideo-tree class="alert alert-secondary ps-1 pe-2 pt-1 mb-2" :pages="groups" :expanded="expandedGroups" v-if="groups.length > 0">
                                <template #toggle>&nbsp;</template>
                                <template #icon>&nbsp;</template>
                                <template #default="{node}">
                                    <template v-if="node.parentId">
                                        <span class="me-auto">
                                            <ideo-form-checkbox class="ms-n2 mb-n2" v-model="selectedValues" :value="`${node.parentId}:${node.id}`" :disabled="loading">
                                                {{ node.resource.name }}
                                            </ideo-form-checkbox>
                                        </span>
                                        <span class="badge rounded-pill text-bg-secondary text-white ms-2">
                                            {{ node.resource.count }}
                                        </span>
                                    </template>
                                    <span class="fw-bold" v-else>
                                        {{ node.resource.name }}
                                    </span>
                                </template>
                            </ideo-tree>
                        </div>
                        <div class="col-12 col-md-8">
                            <div v-if="results.length > 0">
                                <router-link
                                    class="pointer d-block alert alert-light mb-2 p-2"
                                    v-for="(item, i) in results"
                                    :key="i"
                                    :to="routeUrl(item)"
                                    @click="close"
                                >
                                    <div class="d-flex w-100">
                                        <h5 class="mb-1 me-auto">{{ item.title }}</h5>
                                        <small v-if="item.dateModifiedUtc" :title="$t('[[[Aktualizacja: {0}|||%0]]]', $filters.datetime(item.dateModifiedUtc))">
                                            {{ item.dateModifiedUtc.toRelative() }}
                                        </small>
                                    </div>
                                    <div class="d-flex w-100">
                                        <small class="text-primary">{{ item.typeName }}</small>
                                        <span class="ms-auto" v-if="item.attachments.length > 0">
                                            <i class="fa-regular fa-paperclip-vertical" :title="$t('[[[Posiada załączniki]]]')"></i>
                                        </span>
                                    </div>
                                    <div class="d-flex w-100">
                                        <div class="mt-1 me-2" v-if="item.images.length == 1">
                                            <ideo-img :src="$filters.image(item.images[0].url, 'thumb')" thumbnail width="75" height="75" blank-color="#777" />
                                        </div>
                                        <div class="w-100">
                                            <p class="mb-1" v-if="item.description">
                                                {{ item.description }}
                                            </p>
                                            <div class="row row-cols-3 mt-1">
                                                <div class="col" v-for="(attrs, key) in item.attributes" :key="key">
                                                    <small>{{ key }}</small>
                                                    <div>
                                                        <span class="fs-6" v-for="(attr, a) in attrs" :key="a">{{ attr }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-1" v-if="item.images.length > 1">
                                                <span class="me-1" v-for="(img, i) in item.images" :key="i">
                                                    <ideo-img :src="$filters.image(img.url, 'thumb')" thumbnail width="35" height="35" blank-color="#777" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="d-flex justify-content-center pt-5" v-else-if="loading">
                                <loader></loader>
                            </div>
                            <div class="d-flex justify-content-center pt-5" v-else>
                                <i class="fa-solid fa-ban fa-10x text-white"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { NodeItem, Option } from '@/helpers/Interfaces';
import { RouteRecordNormalized } from 'vue-router';
import { Debounce, Ref, Watch } from '@/helpers/Decorators';
import SearchService, { SearchResult, SearchResultType, SearchResultGroup, SearchResultGroupValue } from '@/modules/core/common/services/SearchService';
import Pager from '@/helpers/Pager';
import Loader from '@/components/common/Loader.vue';

@Options({
    components: {
        'loader': Loader
    }
})
export default class Omnibox extends Vue
{
    @Ref()
    public input: () => HTMLElement;

    public focused: boolean = false;
    public loading: boolean = false;
    public sitemap: any[] = [];
    public paths: Option[] = [];
    public routes: string[] = [];
    public redirects: Record<string, string> = {};

    public search: string = '';
    public onlyMine: boolean = false;
    public allVersions: boolean = false;
    public pager: Pager = new Pager(1, 10);
    public results: SearchResult[] = [];

    public types: NodeItem<string, SearchResultType>[] = [];
    public expandedTypes: string[] = [null];
    public selectedTypes: string[] = [];

    public groups: NodeItem<string, SearchResultGroupValue>[] = [];
    public expandedGroups: string[] = [null];
    public selectedValues: string[] = [];

    public get filteredPaths(): Option[]
    {
        if (this.search.length > 0)
        {
            return this.paths
                .filter(p =>
                    p.text.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
                )
                .slice(0, 5);
        }

        return [];
    }

    public get visible(): boolean
    {
        return this.focused && this.search.length > 0;
    }

    public get hasResults(): boolean
    {
        return this.results.length > 0 || this.types.length > 0;
    }

    public async mounted(): Promise<void>
    {
        this.$router.getRoutes().forEach(this.prepareRoutes);
        this.preparePaths(await this.$sitemap.all());

        document.addEventListener('click', this.onClick);
        window.addEventListener("keydown", this.onKeyDown);
    }

    public unmounted(): void
    {
        document.removeEventListener('click', this.onClick);
        window.removeEventListener("keydown", this.onKeyDown);
    }

    public children(node: any): any[]
    {
        return node.children.filter((p: any) => p.allowed);
    }

    public preparePaths(items: any[], parent: Option = null): void
    {
        for (const item of items)
        {
            const route = {
                value: item.route,
                text: this.$t(parent ? `${parent.text} / ${item.name}` : item.name),
                icon: item.icon ? item.icon : parent.icon
            };

            if (route.value && this.routes.includes(route.value))
            {
                route.value = this.$router.resolve({ name: route.value });
                this.paths.push(route);
            }

            this.preparePaths(this.children(item), route);
        }
    }

    public prepareRoutes(route: RouteRecordNormalized): void
    {
        const params = route.path.split('/').filter(p => p.startsWith(':') && !p.endsWith('?'));

        if (route.name && params.length == 0)
        {
            this.routes.push(route.name.toString());
        }

        if (route.meta?.model)
        {
            this.redirects[route.meta.model as string] = route.name as string;
        }
    }

    public highlight(text: string): string
    {
        if (this.search.length > 0)
        {
            return text.replace(new RegExp(`(${this.search})`, 'ig'), (match, p1) => `<b>${p1}</b>`);
        }

        return text;
    }

    public routeUrl(result: SearchResult): any
    {
        return {
            name: this.redirects[result.type] ?? 'core-users',
            params: { id: result.id }
        };
    }

    public clear(): void
    {
        this.onlyMine = false;
        this.allVersions = false;
        this.results = [];

        this.types = [];
        this.expandedTypes = [null];
        this.selectedTypes = [];

        this.groups = [];
        this.expandedGroups = [null];
        this.selectedValues = [];
    }

    public clearAll(): void
    {
        this.search = '';
        this.clear();
    }

    public open(): void
    {
        this.focused = true;
    }

    public close(): void
    {
        this.focused = false;
    }

    public onClick(e: Event): void
    {
        if (!e.composedPath().find(p => p.id == "omnibox"))
        {
            this.close();
        }
    }

    public onKeyDown(event: KeyboardEvent): void
    {
        if (event.ctrlKey && event.key === "q")
        {
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();
            this.input().focus();
        }
    }

    public async onInput(): Promise<void>
    {
        if (this.search)
        {
            this.loading = true;

            if (this.search.length < 3)
            {
                this.clear();
            }
            else
            {
                await this.performSearch();
            }
        }
    }

    @Debounce(500)
    public async performSearch(): Promise<void>
    {
        try
        {
            if (this.search.length >= 3)
            {
                const response = await SearchService.getSearchResults(
                    this.search,
                    this.selectedTypes,
                    this.selectedValues.map(item => item.split(':') as [string, string]),
                    this.onlyMine,
                    this.allVersions,
                    this.pager
                );

                this.pager.setTotalRows(response.totalRows);
                this.results = response.items;
                this.prepareTypes(response.meta.types ?? []);
                this.prepareGroups(response.meta.groups ?? []);
            }
        }
        catch (ex)
        {
            this.pager.setTotalRows(0);
            this.results = [];
            this.$log.debug(ex);
        }
        finally
        {
            this.loading = false;
        }
    }

    public prepareTypes(types: SearchResultType[]): void
    {
        this.types = [];
        this.expandedTypes = [null];

        types.forEach(p =>
        {
            const path = p.name.split(' / ');
            const nodes = path.map((q, i) => ({
                id: path.take(i + 1).join(' / '),
                parentId: i > 0 ? path.take(i).join(' / ') : null,
                hasChildren: false,
                resource: { ...p, name: q }
            }));

            nodes.forEach((n, i) =>
            {
                if (i < nodes.length - 1)
                {
                    n.hasChildren = true;
                    n.resource.count = 0;
                    n.resource.key = n.parentId;
                }

                if (!this.expandedTypes.includes(n.parentId))
                {
                    this.expandedTypes.push(n.parentId);
                }

                if (this.types.findIndex(p => p.id == n.id) == -1)
                {
                    this.types.push(n as NodeItem<string, SearchResultType>);
                }
            });
        });
    }

    public prepareGroups(groups: SearchResultGroup[]): void
    {
        this.groups = [];
        this.expandedGroups = [null];

        groups.forEach(group =>
        {
            if (group.values.length > 0)
            {
                this.expandedGroups.push(group.key);

                this.groups.push({
                    id: group.key,
                    parentId: null,
                    hasChildren: true,
                    resource: group
                });

                group.values.forEach(value =>
                {
                    this.groups.push({
                        id: value.key,
                        parentId: group.key,
                        hasChildren: false,
                        resource: value
                    });
                });
            }
        });
    }

    @Watch('selectedTypes')
    public onTypesChange(): void
    {
        this.selectedValues = [];
        this.performSearch();
    }

    @Watch('onlyMine')
    @Watch('allVersions')
    @Watch('selectedValues')
    public onValuesChange(): void
    {
        this.performSearch();
    }
}
</script>

<style lang="scss">
#omnibox {
    height: var(--ideo-header-height);

    .wrap {
        width: 100%;
        margin: auto 8px;

        .navbar-toggler {
            display: none;
        }

        .input {
            position: relative;

            input {
                padding-left: 36px;
                padding: 8px 8px 8px 36px;
            }
            .icon {
                position: absolute;
                top: 12px;
                left: 12px;
                color: var(--bs-body-color);
            }
            .clear {
                position: absolute;
                top: 12px;
                right: 12px;
            }
        }

        .popup {
            position: relative;

            .results {
                position: absolute;
                top: 4px;
                left: 0;
                right: -100%;
                max-height: 90vh;
                min-width: 824px;
                max-width: 1024px;
                border: 1px solid var(--bs-border-color);
                z-index: var(--ideo-dropdown-zindex);
            }
        }
    }
}

.desktop {
    #omnibox {
        .wrap {
            width: 50%;
        }
    }
}

.mobile {
    #omnibox {
        .wrap {
            .navbar-toggler {
                display: block;
            }
            .input {
                display: none;
            }

            &.focused {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                margin: 0;
                z-index: var(--ideo-dropdown-zindex);
                height: var(--ideo-header-height);
                background-color: var(--bs-body-bg);

                .navbar-toggler {
                    display: none;
                }
                .input {
                    display: block;
                    margin: 12px 10px 0 10px;
                }
                .popup {
                    .results {
                        position: fixed;
                        top: var(--ideo-header-height);
                        left: 0;
                        right: 0;
                        bottom: 0;
                        min-width: auto;
                        max-width: auto;
                        max-height: none;
                    }
                }
            }
        }
    }
}
</style>
