import { Plugin, getCurrentInstance } from 'vue';
import VueLogger, { Log } from 'vuejs3-logger';

export const useLogging = (): {$log: Log} =>
{
    const app = getCurrentInstance();

    return {
        $log: app.appContext.config.globalProperties.$log
    };
};

const LoggerPlugin: Plugin =
{
    install(app)
    {
        const options: any = {
            // required ['debug', 'info', 'warn', 'error', 'fatal']
            logLevel: import.meta.env.PROD ? 'fatal' : 'debug',
            stringifyArguments: false,
            showLogLevel: true,
            showMethodName: false,
            separator: '|',
            showConsoleColors: true
        };

        app.use(VueLogger, options);

        app.config.errorHandler = (err, instance, info) =>
        {
            app.config.globalProperties.$log.error(info, err);
        };

        app.config.warnHandler = (msg, instance, trace) =>
        {
            app.config.globalProperties.$log.warn(msg, trace);
        };
    }
};

export default LoggerPlugin;
