import { Plugin } from 'vue';
import { Vue3Mq } from 'vue3-mq';

let instance: any = null;

interface Media
{
    mq(): string,
    phone(): boolean,
    tablet(): boolean,
    mobile(): boolean,
    laptop(): boolean,
    desktop(): boolean
}

export const useMedia = (): Media =>
{
    return {
        mq: () => instance.current,
        phone: () => ['xs', 'sm'].includes(instance.current),
        tablet: () =>  ['md', 'lg'].includes(instance.current),
        mobile: () =>  ['xs', 'sm', 'md', 'lg'].includes(instance.current),
        laptop: () =>  ['xl'].includes(instance.current),
        desktop: () =>  ['xl', 'xxl'].includes(instance.current)
    };
};

const MediaPlugin: Plugin =
{
    install(app, options)
    {
        app.use(Vue3Mq, {
            preset: 'bootstrap5'
        });

        instance = app._context.provides.mq;

        app.mixin({
            computed: {
                mq: () => useMedia().mq(),
                phone: () => useMedia().phone(),
                tablet: () => useMedia().tablet(),
                mobile: () => useMedia().mobile(),
                laptop: () => useMedia().laptop(),
                desktop: () => useMedia().desktop()
            }
        });
    }
};

export default MediaPlugin;
