// Base
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { AggregateBlueprint, instanceOfAggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { ValidatableBlueprint, validateBlueprints } from '@/components/builder/base/blueprints/ValidatableBlueprint';

// Blueprints
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { PageBlueprint } from '@/components/builder/form/blueprints/PageBlueprint';

// Traits
import { HasTitle } from '@/components/builder/form/traits/HasTitle';
import { HasDescription } from '@/components/builder/form/traits/HasDescription';

// Enums
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';

// --------------------------------------------------

export const Definition: BlueprintDefinition = {
    type: 'page',
    name: '[[[Strona]]]',
    group: 'none'
};

export interface PageContract extends PageBlueprint, AggregateBlueprint, VisibleBlueprint, HasTitle, HasDescription
{
    prevButtonText: Record<string, string>;
    nextButtonText: Record<string, string>;
}

export class PageType implements PageContract, ValidatableBlueprint
{
    public kind: 'page' = 'page' as const;
    public id: string;
    public type: string;
    public name: string;
    public components: Blueprint[];
    public title: Record<string, string>;
    public showTitle: boolean;
    public description: Record<string, string>;
    public prevButtonText: Record<string, string>;
    public nextButtonText: Record<string, string>;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public errors: ValidationErrors;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.components = [];
        this.title = {};
        this.showTitle = false;
        this.description = {};
        this.prevButtonText = {};
        this.nextButtonText = {};
        this.components = [];
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return validateBlueprints(this.components);
    }
}

export const instanceOfPageType = (object: any): object is PageType =>
{
    return instanceOfAggregateBlueprint(object) && 'type' in object && object.type === Definition.type;
};
