<template>
    <div v-if="!$auth.ready()">
        <loader></loader>
    </div>
    <router-view v-else />
    <vue-progress-bar></vue-progress-bar>

    <ideo-modal ref="errorsModalRef" :title="$t('[[[Wystąpiły błędy podczas zapisywania formularza]]]')" hide-footer static>
        <div v-for="(error, index) in exceptionErrors" :key="index" class="text-danger" :class="{'mb-2': index < exceptionErrors.length - 1}">
            <i class="fa-solid fa-fw fa-triangle-exclamation"></i>
            {{ getErrorMessage(error) }}
        </div>

        <div class="text-end">
            <action-button
                :text="$t('[[[Zamknij]]]')"
                variant="outline-dark"
                icon="far fa-xmark"
                @click="errorsModalRef().hide()"
            />
        </div>
    </ideo-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Watch, Ref } from '@/helpers/Decorators';
import Loader from '@/components/common/Loader.vue';
import CustomToast from './CustomToast.vue';
import axios from 'axios';
import { useThemeStore } from '@/store/theme';

@Options({
    components: {
        'loader': Loader
    }
})
export default class App extends Vue
{
    public appHash: string = '';
    public frequency: number = 2 * 60 * 1000;
    public themeStyles: string[] = ['light', 'dark'];
    public exceptionErrors: string[] = [];

    @Ref('errorsModalRef')
    public errorsModalRef!: () => any;

    public mounted()
    {
        this.$events.$on('errors-modal', this.toggleErrorsModal);
    }

    public beforeUnmount(): void
    {
        this.$events.$off('errors-modal', this.toggleErrorsModal);
    }

    public toggleErrorsModal(ex: any): void
    {
        const errors = Object.values(ex ?? {}).flat();

        this.exceptionErrors = [... new Set(errors)] as string[];

        if (errors.length > 0)
            this.errorsModalRef().show();
    }

    public getErrorMessage(error: string)
    {
        if (error.startsWith('[[['))
            return this.$t(error);
        else
            return error;
    }

    public async created(): Promise<void>
    {
        if (import.meta.env.PROD)
        {
            await this.checkVersion(`${location.origin}/manifest.webmanifest`);
        }

        await useThemeStore().initColors();
    }

    public async checkVersion(url: string): Promise<void>
    {
        this.appHash = await this.loadManifest(url);

        setInterval(async () =>
        {
            const result = await this.loadManifest(url);

            if (result)
                this.appHash = result;
        },
        this.frequency);
    }

    public async loadManifest(url: string): Promise<string>
    {
        try
        {
            const http = axios.create();
            const time = new Date();

            time.setSeconds(0);
            time.setMilliseconds(0);

            return (await http.get(`${url}?t=${time.getTime()}`)).data.hash;
        }
        catch (ex)
        {
            this.handleException(ex, {
                0: (ex: any) => this.$alert.danger(ex.message)
            });

            return null;
        }
    }

    @Watch('appHash')
    public onHashChanged(value: string, old: string): void
    {
        if (value != old && old)
        {
            const content = {
                component: CustomToast,
                props: {
                    message: this.$t('[[[Dostępna jest nowa aktualizacja. Proszę zapisać postęp pracy i klinąć przycisk "Aktualizuj". Możesz również zaakceptować aktualizację poprzez odświeżenie okna przeglądarki. Brak aktualizacji może spowodować błędy.]]]'),
                    update: this.$t('[[[Aktualizuj]]]')
                },
                listeners: {
                    reload: () =>
                    {
                        location.reload();
                    }
                }
            };

            this.$alert.show(content, {
                type: "error",
                timeout: false,
                closeButton: false,
                closeOnClick: false,
                draggable: false
            });
        }
    }
}
</script>

<style lang="scss">
    // Import Main styles for this application
    @import "@/assets/scss/style";
</style>
