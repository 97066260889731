<script lang="ts" setup>
import { ref, onMounted, onUnmounted, computed, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useSitemap, SitemapNode } from '@/plugins/sitemap';
import { useMedia } from '@/plugins/media';
import { useAuthStore } from '@/store/auth';
import logo from '@/assets/logo/motivationdirect-logo-white.png';
import { useEvents } from '@/plugins/events';
import MenuList from '@/views/partials/navigation/MenuList.vue';

const media = useMedia();
const { $events } = useEvents();

defineProps({
  "collapsed": { type: Boolean, default: false },
  "hover": { type: Boolean, default: false }
});

const emit = defineEmits(["toggleMenu"]);

const ICONS = Object
    .entries(import.meta.glob('@/assets/icons/*.svg', { as: 'raw', eager: true }))
    .map(([path, value]) => ({ name: path.split('/').last(), value: value }))
    .record(p => p.name, p => p.value);

const { $sitemap } = useSitemap();
const router = useRouter();
const route = useRoute();
const auth = useAuthStore();
const loaded = ref(false);
const sitemap = ref<SitemapNode[]>([]);
const toggled = ref<SitemapNode[]>([]);
const current = ref<SitemapNode>(null);
const selected = ref<SitemapNode>(null);
const version = computed(() =>
{
    return auth.identity?.systemVersion;
});

onMounted(async () =>
{
    $sitemap.onReload(async () =>
    {
        loaded.value = false;
        sitemap.value = await $sitemap.all();
        router.push({...route, force: true});
        loaded.value = true;
    });
    await $sitemap.reload();
});

onUnmounted(() =>
{
    $sitemap.purge();
});

watch(router.currentRoute, async (value, old) =>
{
    if (value !== old)
    {
        toggled.value = await $sitemap.crumbs(value);
        current.value = toggled.value.first();
    }
},
{immediate: true});


const submenu = (): any => children(current.value);

const active = (node: SitemapNode): boolean =>
{
    return $sitemap.active(node, route, true);
};

const url = (node: SitemapNode): any =>
{
    return $sitemap.url(node, route);
};

const root = (): SitemapNode[] =>
{
    return sitemap.value.filter(p => p.visible);
};

const children = (node: SitemapNode): SitemapNode[] =>
{
    return node?.children.filter(p => p.visible) || [];
};

const changeRoot = (node: SitemapNode, recurency?: boolean): void =>
{
    if (!recurency)
        selected.value = node;

    if (node.route || node.url)
    {
        router.push(url(node));
    }
    else
    {
        const items = children(node);

        if (items.length > 0)
        {
            changeRoot(items.first(), true);
        }
    }

    if (!node.preventCloseMenu && !recurency && children(node).length === 0)
    {
        if (media.mobile())
            emit('toggleMenu', false);
    }
};

const hasAdditional = computed(() => selected.value === current.value);

const showOther = (node: SitemapNode): void =>
{
    current.value = node;
};


const toggle = async (node: any): Promise<void> =>
{
    if (children(node).length > 0)
    {
        if (expanded(node))
        {
            toggled.value = toggled.value.filter(p => p !== node);
        }
        else
        {
            toggled.value = [];
            $sitemap.path(node).forEach(p =>
                toggled.value.push(p)
            );
        }
    }
    else
    {
        await router.push(url(node));

        if (media.mobile())
            emit('toggleMenu', false);
    }

    if (node.event)
    {
        $events.$emit(node.event);
    }
};

const expanded = (node: any): boolean =>
{
    return toggled.value.includes(node);
};
</script>

<template>
    <div id="menu" :class="{'collapsed': collapsed, 'hover': hover}">
        <div id="appsmenu">
            <ideo-tooltip
                :tooltip="media.mobile() ? null : $t('[[[Strona główna]]]')"
                position="right"
            >
                <header class="logo d-flex justify-content-center align-items-center">
                    <router-link :to="{name: 'dashboard'}">
                        <img :src="logo" />
                    </router-link>
                </header>
            </ideo-tooltip>
            <ul>
                <ideo-tooltip
                    v-for="(node, i) in root()"
                    :key="i"
                    :tooltip="media.mobile() ? null : $t(node.name)"
                    position="right"
                >
                    <li
                        class="d-flex flex-column justify-content-center align-items-center" :class="{'active': active(node)}"
                        @click="changeRoot(node)"
                        @mouseenter="showOther(node)"
                    >
                        <i class="fa-2x" :class="node.icon"></i>
                        <small>{{ $t(node.short) }}</small>
                    </li>
                </ideo-tooltip>
            </ul>
        </div>
        <div id="sitemenu" v-if="loaded">
            <header class="d-flex align-items-center justify-content-between text-body-emphasis">
                {{ $t(current?.name) }}

                <i v-if="media.mobile()" class="far fa-xmark" @click="emit('toggleMenu', false)"></i>
            </header>
            <section class="scroll">
                <menu-list
                    :nodes="submenu()"
                    :active="active"
                    :toggle="toggle"
                    :url="url"
                    :children="children"
                    :expanded="expanded"
                />
                <div class="px-1 mt-4" v-if="!hasAdditional">
                    <div class="alert alert-info text-center">{{ $t('[[[Pola poniżej o ile istnieją pokażą się po wejściu w daną zakładkę]]]') }}</div>
                </div>
                <template v-if="selected == current">
                    <portal-target name="filter-section"></portal-target>
                    <portal-target name="menu-section"></portal-target>
                </template>
            </section>
            <footer class="d-flex justify-content-center align-items-center">
                Edito {{ version }}
            </footer>
        </div>
    </div>
</template>

<style lang="scss" scoped>
#menu {
    --ideo-menu-item-space: 4px;
    --ideo-menu-icon-space: 20px;

    display: flex;
    width: var(--ideo-nav-width-default);

    header {
        height: var(--ideo-header-height);
    }
    section {
        height: calc(100dvh - var(--ideo-header-height) - var(--ideo-footer-height));
    }
    footer {
        height: var(--ideo-footer-height);
    }

    section.scroll{
        position: relative;
        z-index: 12000;
    }

    #appsmenu {
        ul {
            width: var(--ideo-nav-width-collapsed);
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                height: var(--ideo-nav-width-collapsed);
                padding-right: 2px;
                border-left: 4px solid transparent;
                color: var(--ideo-nav-color);
                transition: background-color 0.25s cubic-bezier(0, 1, 0.5, 1);
                cursor: pointer;

                &:hover {
                    border-left-color: var(--bs-border-color);
                    background-color: var(--ideo-menu-bg);
                    color: var(--ideo-nav-color-active);
                }
                &.active {
                    border-left-color: var(--bs-primary);
                    background-color: var(--ideo-menu-bg);
                    color: var(--ideo-nav-color-active);
                }

                small {
                    display: block;
                    width: calc(var(--ideo-nav-width-collapsed) - var(--ideo-menu-item-space));
                    margin: 2px auto 0 auto;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                &:has(small) i {
                    margin-top: 4px;
                }
            }
            li.logo {
                height: var(--ideo-header-height);
                cursor: inherit;
            }
        }
    }

    &.collapsed {
        &:not(.hover) #appsmenu li.active {
            background-color: var(--bs-tertiary-bg);
        }
    }

    #sitemenu {
        width: calc(var(--ideo-nav-width-default) - var(--ideo-nav-width-collapsed));
        max-width: calc(100vw - var(--ideo-nav-width-collapsed));
        background-color: var(--ideo-menu-bg);
        border-right: 1px solid var(--bs-border-color);
        color: var(--bs-body-color);

        > header {
            padding: 0 1rem;
            font-size: 20px;
            font-weight: 600;
        }

        section {
            padding-top: 0.5rem;
        }
    }
}
</style>
