<template>
    <component :is="'tr'" :class="getClass()" v-bind="bindData">
        <vnodes :vnodes="cells()" />
    </component>
</template>

<script lang="ts" setup>
import { computed, inject, provide, useSlots, useAttrs } from 'vue';
import { Header, travel } from '../helpers';

export interface Props
{
    index: number;
    item: any;
    headers: Header[];
}

defineOptions({
    name: 'list-view-utils-row',
    components: {
        'vnodes': (props: Record<string, any>, context: any) => props.vnodes
    },
    inheritAttrs: false
});

const $slots = useSlots();
const $attrs = useAttrs();
const props = defineProps({
  "index": null,
  "item": null,
  "headers": null
});
const item = computed(() => props.item);
const rowClass = inject<(item: any, i: number) => Record<string, boolean> | string[] | string>('row-class');
const draggable = inject<boolean>('draggable');

const bindData = computed(() => ({
    'data-draggable': $attrs['data-draggable'],
    draggable: $attrs.draggable,
    style: $attrs.style
}));

provide('item', item);

const getClass = (): any =>
{
    const classes = {'draggable': draggable};

    if (rowClass)
    {
        return Object.assign(classes, rowClass(props.item, props.index));
    }

    return classes;
};

const cells = (): any[] =>
{
    const allowed = [
        'list-view-header',
        'list-view-item',
        'list-view-label',
        'list-view-buttons',
        'list-view-image',
        'list-view-checkbox'
    ];
    const prefix = (new Date()).getTime();

    return travel($slots.default(), allowed)
        .map((p, i) =>
        {
            p.key = `${prefix}-${i}`;
            p.props = p.props || {};
            p.props.index = i;
            p.props.header = props.headers[i];

            return p;
        })
        .filter(p => !p.props.header.disabled)
        .orderBy(p => p.props.header.position)
        .toArray();
};
</script>
