import { computed, nextTick, WatchOptions } from 'vue';
import { Vue, createDecorator, PropOptions, VueDecorator } from 'vue-class-component';
export { Options as Component, Vue } from 'vue-class-component';

export function Prop(options: PropOptions = {}): VueDecorator
{
    return createDecorator((componentOptions, propName) =>
    {
        componentOptions.props = componentOptions.props || {};
        componentOptions.props[propName] = options;
    });
}

export function Ref(refKey?: string): VueDecorator
{
    return createDecorator((options, propName) =>
    {
        options.methods = options.methods || {};
        options.methods[propName] = function()
        {
            const property = refKey || propName;

            if (!(property in this.$refs))
                throw new Error(`Property ${property} not present in $refs object. References are are not available before mounted event.`);

            return this.$refs[property];
        };
    });
}

export function Watch(path: string, options: WatchOptions = {}): VueDecorator
{
    return createDecorator((componentOptions, handler) =>
    {
        if (typeof componentOptions.watch !== 'object')
        {
            componentOptions.watch = Object.create(null);
        }

        const watch: any = componentOptions.watch;

        if (typeof watch[path] === 'object' && !Array.isArray(watch[path]))
        {
            watch[path] = [watch[path]];
        }
        else if (typeof watch[path] === 'undefined')
        {
            watch[path] = [];
        }

        watch[path].push({ handler, ...options });
    });
}

const PROVIDE_KEY = '__provide_inject__';

export function Provide(key?: string): VueDecorator
{
    return createDecorator((componentOptions, propName) =>
    {
        const provider = componentOptions.provide || {};

        componentOptions.provide = function()
        {
            const data = typeof provider == 'function' ? provider.bind(this).call() : provider;

            data[PROVIDE_KEY] = { ...(data[PROVIDE_KEY] || {}), ...(this[PROVIDE_KEY] || {}) };
            data[PROVIDE_KEY][key || propName] = computed(() => this[propName]);

            return data;
        };
    });
}

export function Inject(key?: string): VueDecorator
{
    return createDecorator((componentOptions, propName) =>
    {
        componentOptions.inject = componentOptions.inject || {};
        componentOptions.inject[PROVIDE_KEY] = PROVIDE_KEY;

        componentOptions.computed = componentOptions.computed || {};
        componentOptions.computed[propName] = {
            cache: false,
            get()
            {
                return this[PROVIDE_KEY][key || propName].value;
            }
        };
    });
}

interface EmitOptions
{
    multipleEventArgs?: boolean;
    noEmitUndefined?: boolean;
    delay?: boolean;
}

export function Emit(event?: string, options: EmitOptions = {}): (target: Vue, propertyKey: string, descriptor: PropertyDescriptor) => void
{
    const hyphenate = (str: string): string => str.replace(/\B([A-Z])/g, '-$1').toLowerCase();
    const isPromise = (obj: any): obj is Promise<any> =>
    {
        return (obj instanceof Promise || (obj && typeof obj.then === 'function'));
    };

    return (target: Vue, propertyKey: string, descriptor: PropertyDescriptor): void =>
    {
        const key = hyphenate(propertyKey);
        const eventName = event || key;
        const original = descriptor.value;
        const decorator = createDecorator((componentOptions, propName) =>
        {
            componentOptions.emits = componentOptions.emits || {};
            componentOptions.emits[eventName] = (eventValue: any) => true;
        });

        decorator(target, propertyKey);

        descriptor.value = function(...args: any[])
        {
            const vm = this as Vue;
            const emit = (returnValue: any): void =>
            {
                if (returnValue !== undefined)
                {
                    if (options.multipleEventArgs == true && Array.isArray(returnValue))
                        vm.$emit(eventName, ...returnValue);
                    else
                        vm.$emit(eventName, returnValue);
                }
                else if (options.noEmitUndefined !== true)
                {
                    vm.$emit(eventName);
                }
            };
            const delayEmit = (returnValue: any): void =>
            {
                nextTick(() => emit(returnValue));
            };
            const returnValue = original.apply(vm, args);
            const callback = options.delay == true ? delayEmit : emit;

            if (isPromise(returnValue))
            {
                returnValue.then(callback);
            }
            else
            {
                callback(returnValue);
            }

            return returnValue;
        };
    };
}

export function Debounce(milliseconds: number = 0, options = {}): (target: Vue, propertyKey: string, descriptor: PropertyDescriptor) => void
{
    return (target: Vue, propertyKey: string, descriptor: PropertyDescriptor): void =>
    {
        const map = new WeakMap();
        const originalMethod = descriptor.value;

        descriptor.value = function(...args: any)
        {
            const vm = this;
            let debounced = map.get(this);

            if (debounced)
            {
                clearTimeout(debounced);
                map.delete(this);
            }

            debounced = setTimeout(function()
            {
                originalMethod.call(vm, ...args);
            },
            milliseconds);

            map.set(this, debounced);
        };
    };
}

export interface UseLoadingOptions {
    timeout?: number;
    field?: string;
}

/**
 * @description Function wrapper to use loading state on asynchronous methods
 *
 * @param { string } field Loading state field in component
 * @param { number } timeout Loading state set to false timeout
 * @returns { void }
 */
export function UseLoading(options?: UseLoadingOptions): (target: Vue, propertyKey: string, descriptor: PropertyDescriptor) => void
{
    return (target: Vue, propertyKey: string, descriptor: PropertyDescriptor): void =>
    {
        const originalMethod: (...args: unknown[]) => unknown = descriptor.value;
        const loadingState: string = options?.field || 'loading';

        descriptor.value = async function(...args: unknown[]): Promise<unknown>
        {
            this[loadingState] = true;

            const result = await originalMethod.apply(this, args);

            if (options?.timeout)
            {
                setTimeout(() => { this[loadingState] = false; }, options.timeout);
            }
            else
            {
                this[loadingState] = false;
            }

            return result;
        };
    };
}
