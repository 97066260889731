export interface Entry
{
    type: string;
}

export const instanceOfEntry = (object: any): object is Entry =>
{
    return object && typeof object === 'object' && 'type' in object;
};

export const entry = (data: any): Entry =>
{
    return data;
};
